import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AdminPanelSettingsOutlined } from "@mui/icons-material";
import ForumIcon from "@mui/icons-material/Forum";
import FeedbackIcon from "@mui/icons-material/Feedback";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { MenuItem } from "@mui/material";
import { StyledDiv, TypographyText } from "components/UserMenu/UserMenuStyles";
import theme from "styles/theme";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";

const icons = {
	user: PersonIcon,
	description: HelpIcon,
	usageGuide: InfoIcon,
	signOut: ExitToAppIcon,
	feedbackPage: FeedbackIcon,
	viewFeedbackPage: ForumIcon,
	adminPage: AdminPanelSettingsOutlined,
	timelinePage: KeyboardReturnIcon,
	showTimelineFromToday: QueryBuilderOutlinedIcon
};

const Icon = ({ type }) => {
	const SpecificIcon = icons[type];
	return <SpecificIcon />;
};

/**
 * Represents a general UserMenu item
 */
const UserMenuItem = ({ user, type, handleClick }) => {
	const { t } = useTranslation();
	const itemTextUser = user.name === undefined ? t("user") : user.name;
	const itemText = t(type);

	return (
		<MenuItem
			onClick={handleClick}
			disabled={type === "user" ? true : false}
			sx={{ "&:hover": { backgroundColor: theme.palette.secondary.main } }}>
			<StyledDiv>
				<Icon type={type} />
				<TypographyText data-testid={"usermenu-" + type} variant="body1">
					{type === "user" ? itemTextUser : itemText}
				</TypographyText>
			</StyledDiv>
		</MenuItem>
	);
};

UserMenuItem.propTypes = {
	/** The type of UserMenuItem */
	type: PropTypes.string.isRequired,
	/** Function for handling click on UserMenuItem */
	handleClick: PropTypes.func,
	/** The user object from state */
	user: PropTypes.object
};
Icon.propTypes = {
	/** The type of UserMenuItem */
	type: PropTypes.string.isRequired
};

export const mapStateToProps = state => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps)(UserMenuItem);
