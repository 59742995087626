import { ADD_USERS_PHOTOS } from "../constants";
import initialState from "./initialState";

/** Reducer for the usersPhotos object in state */
export default function (state = initialState.usersPhotos, action) {
	switch (action.type) {
		case ADD_USERS_PHOTOS:
			return {
				...state,
				photos: action.payload
			};
		default:
			return state;
	}
}
