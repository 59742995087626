import React, { useState, useEffect } from "react";
import { ListItemAvatar, ListItem, ListItemText, Button, Box } from "@mui/material";
import PropTypes from "prop-types";
import { modalsSetCustomGroupSelectedUsers } from "actions/modalActions";
import { connect } from "react-redux";
import UserPhoto from "components/UserPhoto/UserPhoto";
import { useTranslation } from "react-i18next";
import theme from "styles/theme";

/**
 * A user displayed in the SearchResultList component
 */
function UserResult({ userId, name, email, selectedUsers, modalsSetCustomGroupSelectedUsers }) {
	const [isSelected, setIsSelected] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const userIsSelected = selectedUsers.some(userObject => userId === userObject.id);
		setIsSelected(userIsSelected);
	}, [selectedUsers, userId]);

	const toggleUserIsSelected = () => {
		const updatedSelectedUsers = isSelected
			? selectedUsers.filter(userObject => userId !== userObject.id) // Remove if already selected
			: [...selectedUsers, { id: userId, name: name }]; // Add if not already selected

		modalsSetCustomGroupSelectedUsers(updatedSelectedUsers);
		setIsSelected(!isSelected);
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				paddingRight: "10px",
				width: "100%"
			}}>
			<ListItem key={name}>
				<ListItemAvatar>
					<UserPhoto userId={userId} size="30px" />
				</ListItemAvatar>
				<ListItemText sx={{ wordBreak: "break-word" }} primary={name} secondary={email} />
			</ListItem>
			<Button
				data-testid="user-result-button"
				onClick={toggleUserIsSelected}
				sx={{
					...theme.button
				}}>
				{isSelected ? t("remove") : t("add")}
			</Button>
		</Box>
	);
}

UserResult.propTypes = {
	/** The userId of a user in the search result */
	userId: PropTypes.string,
	/** The name of a user in the search result */
	name: PropTypes.string,
	/** The email of a user in the search result */
	email: PropTypes.string,
	/** An array of userIds representing users selected for a new custom group */
	selectedUsers: PropTypes.array,
	/** Action for removing a selected user */
	modalsSetCustomGroupSelectedUsers: PropTypes.func
};

const mapStateToProps = state => {
	return {
		selectedUsers: state.modals.customGroupModal.selectedUsers
	};
};

export default connect(mapStateToProps, { modalsSetCustomGroupSelectedUsers })(UserResult);
