import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Toolbar as ToolBar } from "@mui/material";
import BouvetAwayLogo from "../../assets/images/BouvetAwayLogo.png";
import UserMenuIcon from "../UserMenuIcon/UserMenuIcon";
import { AppBarRoot, ImgAwaylogo, DivDivider } from "./MenuAppBarStyles";
import { viewSetTodayCurrent, viewSetScope } from "../../actions/viewActions";
import { Link } from "react-router-dom";
import { LoaderSpinnerSmall } from "components/Loading/LoaderSpinners";
import LoadingEventsText from "components/Loading/LoadingEventsText";
import { userSetSelectedGroupId } from "actions/userActions";
import { SELECTED_GROUP_ALL_OPTION, MONTH } from "constants";
import TimelineSettingsMenuIcon from "components/TimelineSettingsMenuIcon/TimelineSettingsMenuIcon";

/**
 * Represents the main app bar
 */
const MenuAppBar = ({ isLoading, viewSetTodayCurrent, viewSetScope, userSetSelectedGroupId }) => {
	const handleClick = () => {
		viewSetScope(MONTH);
		viewSetTodayCurrent();
		userSetSelectedGroupId(SELECTED_GROUP_ALL_OPTION.id);
	};

	return (
		<AppBarRoot position="static">
			<ToolBar>
				<Link onClick={handleClick} to="/">
					<ImgAwaylogo src={BouvetAwayLogo} alt="Bouvet Away Logo" height="100%" />
				</Link>
				{isLoading && <LoaderSpinnerSmall />}
				{isLoading && <LoadingEventsText />}
				<DivDivider />
				<TimelineSettingsMenuIcon />
				<UserMenuIcon />
			</ToolBar>
		</AppBarRoot>
	);
};

MenuAppBar.propTypes = {
	/** Indicates if there are still events loading */
	isLoading: PropTypes.bool,
	/** Action for setting the view to today in current scope */
	viewSetTodayCurrent: PropTypes.func,
	/** Action for setting the view scope */
	viewSetScope: PropTypes.func,
	/** Action for setting the selected group id */
	userSetSelectedGroupId: PropTypes.func
};

const mapStateToProps = state => {
	return {
		isLoading: state.loading.isLoading
	};
};

export default connect(mapStateToProps, {
	viewSetTodayCurrent,
	viewSetScope,
	userSetSelectedGroupId
})(MenuAppBar);
