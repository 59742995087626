import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { MenuItemDiv, TypographyText } from "../UserMenu/UserMenuStyles";
import { MenuItem } from "@mui/material";
import { userSetFilterTimelineNoWorkingHours } from "../../actions/userActions";

/**
 * Represents an item in the UserMenu for filtering user
 * with no events in the timeline.
 */
const FilterUserNoWorkingHoursMenuItem = ({ user, userSetFilterTimelineNoWorkingHours }) => {
	const { t } = useTranslation();
	const [filtered, setFiltered] = useState(user.filteredOutsideWorkingHours);

	const toggleFilter = () => {
		userSetFilterTimelineNoWorkingHours(!filtered);
		setFiltered(!filtered);
	};

	return (
		<MenuItem onClick={toggleFilter}>
			<MenuItemDiv>
				{filtered ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
				<TypographyText data-testid="usermenu-workingHoursfilter" variant="body1">
					{t("showEventsWorkingHours")}
				</TypographyText>
			</MenuItemDiv>
		</MenuItem>
	);
};

FilterUserNoWorkingHoursMenuItem.propTypes = {
	/** The user object from state */
	user: PropTypes.object,
	/** Action for setting filter user property */
	userSetFilterTimelineNoWorkingHours: PropTypes.func
};

export const mapStateToProps = state => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps, { userSetFilterTimelineNoWorkingHours })(
	FilterUserNoWorkingHoursMenuItem
);
