import {
	MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP,
	MODALS_SET_CUSTOM_GROUP_MODAL_GROUP,
	MODALS_SET_CUSTOM_GROUP_SELECTED_USERS,
	MODALS_SET_MODAL_TYPE
} from "../constants";

export const modalsSetCustomGroupModalGroup = group => {
	return {
		type: MODALS_SET_CUSTOM_GROUP_MODAL_GROUP,
		payload: group
	};
};

export const modalsSetCustomGroupSelectedUsers = userIdList => {
	return {
		type: MODALS_SET_CUSTOM_GROUP_SELECTED_USERS,
		payload: userIdList
	};
};

export const modalsSetModalType = modalType => {
	return {
		type: MODALS_SET_MODAL_TYPE,
		payload: modalType
	};
};

export const modalsResetCustomGroupModal = () => {
	return {
		type: MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP
	};
};
