import { styled } from "@mui/material/styles";

export const TimeLineGridItem = styled("div")(({ theme, dayType }) => ({
	width: "100%",
	borderRight: theme.timeline.gridBorders.border,
	borderBottom: theme.timeline.gridBorders.border,
	borderLeft: theme.timeline.gridBorders.border,
	backgroundColor:
		dayType === "holiday"
			? theme.palette.specialDays.holiday
			: dayType === "saturday"
				? theme.palette.specialDays.saturday
				: theme.palette.background.default
}));

export const TimeLineGridContainerStyled = styled("div")(({ useCompactView }) => {
	const minHeight = useCompactView ? "24px" : "70px";
	return {
		width: "100%",
		display: "flex",
		position: "absolute",
		minHeight: minHeight
	};
});
