import styled from "@emotion/styled";
import { FormControl, TextField } from "@mui/material";
import { colors } from "styles/theme";

export const FilterFormControl = styled(FormControl)(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-evenly",
	flexWrap: "wrap",
	alignItems: "center",
	padding: "10px",
	paddingTop: "15px",
	backgroundColor: colors.darkgrey,
	borderTop: "2px solid gray",
	borderBottomLeftRadius: "5px",
	borderBottomRightRadius: "5px"
}));

export const FilterTextField = styled(TextField)(() => ({
	maxWidth: "300px",
	"& label.Mui-focused": {
		color: colors.orange
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: colors.lightgrey
		},
		"&.Mui-focused fieldset": {
			borderColor: colors.orange
		}
	}
}));
