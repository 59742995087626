import React, { useState } from "react";
import { Table, TableRow, TableCell, TableBody, Typography, TableHead } from "@mui/material";
import PropTypes from "prop-types";
import { LoaderSpinner } from "components/Loading/LoaderSpinners";
import {
	TableBox,
	TableCellNoGroups,
	TableAndFilterContainer,
	StyledTableContainer,
	HeaderTableCell,
	RemoveButton
} from "./AdminMenuGroupsTableStyles";
import { removeADGroups } from "services/apiAdminService";
import { useTranslation } from "react-i18next";
import TableFilters from "./TableFilters/TableFilters";
import { removeGroupFromState } from "utils/groupHelper";
import { connect, useDispatch, useStore } from "react-redux";
import { userSetSelectedGroupId } from "actions/userActions";
import { SELECTED_GROUP_ALL_OPTION } from "constants";

/** Table used to display all active groups in the admin menu */
function AdminMenuGroupsTable({
	tableGroups,
	setTableGroups,
	showSpinner,
	userSetSelectedGroupId
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = useStore();
	const [filterTextFieldInput, setFilterTextFieldInput] = useState("");
	const [filterCheckboxValue, setFilterCheckboxValue] = useState(false);

	const isGroupFiltered = group => {
		const groupNameContainsFilterText = group.name
			.toUpperCase()
			.includes(filterTextFieldInput.toUpperCase());
		const filterByNotBeingMember = filterCheckboxValue && !group.isMember;
		return !groupNameContainsFilterText || filterByNotBeingMember;
	};

	// Creates the rows to be displayed in the table
	const rows = tableGroups?.reduce((accumulator, group) => {
		if (isGroupFiltered(group) === false) {
			accumulator.push(
				<TableRow key={group.id} data-testid={`$row-${group.id}`}>
					<TableCell>{group.name}</TableCell>
					<TableCell>{group.id}</TableCell>
					<TableCell>{group.isMember ? t("yesText") : t("noText")}</TableCell>
					<TableCell>
						<RemoveButton
							data-testid={`$remove-btn-${group.id}`}
							onClick={() => handleRemoveGroup(group)}>
							{t("remove")}
						</RemoveButton>
					</TableCell>
				</TableRow>
			);
		}

		return accumulator;
	}, []);

	const handleRemoveGroup = async group => {
		const result = await removeADGroups([group]);
		if (result) {
			userSetSelectedGroupId(SELECTED_GROUP_ALL_OPTION.id);
			removeGroupFromState(group.id, store, dispatch);
			setTableGroups(prevTableGroups => {
				const newTableGroups = prevTableGroups.filter(
					tableGroup => tableGroup.id !== group.id
				);
				return newTableGroups;
			});
		}
	};

	return (
		<TableAndFilterContainer>
			<TableBox>
				<StyledTableContainer>
					<Table stickyHeader size="small">
						<TableHead>
							<TableRow>
								<HeaderTableCell sx={{ borderTopLeftRadius: 5 }}>
									{t("groupName")}
								</HeaderTableCell>
								<HeaderTableCell>ID</HeaderTableCell>
								<HeaderTableCell>{t("youAreMember")}</HeaderTableCell>
								<HeaderTableCell sx={{ borderTopRightRadius: 5 }} />
							</TableRow>
						</TableHead>
						<TableBody>
							{
								/* Show spinner when groups are loading */
								showSpinner ? (
									<TableRow data-testid="spinner">
										<TableCell colSpan={4}>
											<LoaderSpinner />
										</TableCell>
									</TableRow>
								) : /* Show 'no groups' message when tableGroups is empty or all tableGroups elements are filtered */
								rows && rows.length === 0 ? (
									<TableRow>
										<TableCellNoGroups colSpan={4}>
											<Typography variant="h4">
												{t("noGroupsFound")}
											</Typography>
										</TableCellNoGroups>
									</TableRow>
								) : (
									/* Displays the groups */
									rows
								)
							}
						</TableBody>
					</Table>
				</StyledTableContainer>

				<TableFilters
					setFilterTextFieldInput={setFilterTextFieldInput}
					filterCheckboxValue={filterCheckboxValue}
					setFilterCheckboxValue={setFilterCheckboxValue}
				/>
			</TableBox>
		</TableAndFilterContainer>
	);
}

AdminMenuGroupsTable.propTypes = {
	/** Array containing AD groups and a boolean indicating whether the user is a member of the group */
	tableGroups: PropTypes.array,
	/** Setter function for tableGroups */
	setTableGroups: PropTypes.func,
	/** Boolean used to show a loading spinner */
	showSpinner: PropTypes.bool,
	/** Action used to set the selected group id in state */
	userSetSelectedGroupId: PropTypes.func
};

export default connect(null, { userSetSelectedGroupId })(AdminMenuGroupsTable);
