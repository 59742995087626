import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect, useDispatch, useStore } from "react-redux";
import { userSetSelectedGroupId } from "actions/userActions";
import { StyledFormControl } from "./GroupSelectStyles";
import GroupSelectOption from "./GroupSelectOption/GroupSelectOption";
import {
	GROUP_TYPE_AD_GROUP,
	GROUP_TYPE_CUSTOM_GROUP,
	SELECTED_GROUP_ALL_OPTION
} from "../../../constants/index";
import { getSortedGroups, removeGroupFromState } from "utils/groupHelper";
import { deleteCustomGroup } from "services/apiCustomGroupService";
import AcceptDialog from "../../AcceptDialog";

/**
 * Represents the dropdown menu used to select a group
 */
const GroupSelect = ({ user, userSetSelectedGroupId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = useStore();
	const options = user.groups
		? [SELECTED_GROUP_ALL_OPTION, ...getSortedGroups(user.groups, user)]
		: [SELECTED_GROUP_ALL_OPTION];
	const [deleteGroup, setDeleteGroup] = useState(undefined);

	const handleClickDelete = async () => {
		const groupToBeDeleted = { ...deleteGroup };
		setDeleteGroup(undefined);

		// Set selected group to "all" if the group to be deleted is the currently selected group
		user.selectedGroupId === groupToBeDeleted.id &&
			userSetSelectedGroupId(SELECTED_GROUP_ALL_OPTION.id);
		removeGroupFromState(groupToBeDeleted.id, store, dispatch);
		await deleteCustomGroup(groupToBeDeleted.id);
	};

	// Groups the elements in the Autocomplete component based on group type and owner
	const getGrouping = group => {
		const userOwnsGroup = group.groupOwnerId === user.id;
		const isCustomGroup = group.groupType === GROUP_TYPE_CUSTOM_GROUP;
		const isADGroup = group.groupType === GROUP_TYPE_AD_GROUP;

		return isCustomGroup
			? userOwnsGroup
				? t("yourCustomGroups")
				: t("sharedGroups")
			: isADGroup
				? t("bouvetGroups")
				: null;
	};

	return (
		<>
			<AcceptDialog
				showDialog={deleteGroup !== undefined}
				dialogTitle={t("groupDeleteConfirm")}
				handleClickYes={() => handleClickDelete()}
				handleClickNo={() => setDeleteGroup(undefined)}
			/>
			<StyledFormControl size="small">
				<Autocomplete
					size="small"
					disableClearable={true}
					options={options}
					groupBy={option => getGrouping(option)}
					getOptionLabel={option => t(option.name)}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={options.find(option => option.id === user.selectedGroupId)}
					renderInput={params => (
						<TextField {...params} label={t("Group")} variant="outlined" />
					)}
					renderOption={(props, option) => (
						<GroupSelectOption
							key={option.id}
							props={props}
							group={option}
							setDeleteGroup={setDeleteGroup}
						/>
					)}
					onChange={(_, group) => userSetSelectedGroupId(group.id)}
				/>
			</StyledFormControl>
		</>
	);
};

GroupSelect.propTypes = {
	/** The user object from state */
	user: PropTypes.object,
	/** Action for setting selected group */
	userSetSelectedGroupId: PropTypes.func
};

const mapStateToProps = state => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps, { userSetSelectedGroupId })(GroupSelect);
