import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	DivDate,
	DivNav,
	DivDesktop,
	DivMobile,
	DivGroupSelectMobile,
	DivGroupSelectDesktop
} from "./NavAppBarStyles";
import NavTodayButton from "../MenuAppBar/Buttons/NavTodayButton/NavTodayButton";
import NavTextDisplay from "../NavTextDisplay/NavTextDisplay";
import NavDirectionButton from "../MenuAppBar/Buttons/NavDirectionButton/NavDirectionButton";
import NavViewScopeButton from "../NavViewScopeButton/NavViewScopeButton";
import { viewMoveCurrent, viewSetShowFromToday } from "../../actions/viewActions";
import { INCREMENT, DECREMENT, HIDE_MODALS } from "../../constants";
import { Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import GroupSelect from "./GroupSelect/GroupSelect";
import NewCustomGroupButton from "./NewCustomGroupButton/NewCustomGroupButton";
import NewCustomGroupModal from "components/CustomGroupModal/NewCustomGroupModal/NewCustomGroupModal";
import EditCustomGroupModal from "components/CustomGroupModal/EditCustomGroupModal/EditCustomGroupModal";
import { useGlobalKeyPress } from "../../utils/useGlobalKeyPress";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { colors } from "styles/theme";
import { useTranslation } from "react-i18next";

/**
 * Represents the navigation bar
 */
const NavAppBar = ({ view, viewMoveCurrent, modalType, viewSetShowFromToday, showFromToday }) => {
	const { t } = useTranslation();
	useGlobalKeyPress(["ArrowLeft", "ArrowRight"], e => {
		if (modalType !== HIDE_MODALS) return;
		switch (e.key) {
			case "ArrowLeft":
				viewMoveCurrent(DECREMENT);
				break;
			case "ArrowRight":
				viewMoveCurrent(INCREMENT);
				break;
			default:
				break;
		}
	});
	const isDesktop = useMediaQuery("(min-width: 960px)");
	const Div = isDesktop ? DivDesktop : DivMobile;
	const DivGroupSelect = isDesktop ? DivGroupSelectDesktop : DivGroupSelectMobile;

	const getGroupSelector = () => {
		return (
			<DivGroupSelect>
				<GroupSelect />
				<NewCustomGroupButton />
				<NewCustomGroupModal />
				<EditCustomGroupModal />
			</DivGroupSelect>
		);
	};

	const toggleViewFromToday = () => {
		viewSetShowFromToday(!showFromToday);
	};

	return view.start === undefined ? null : (
		<Div>
			<DivDate>
				<NavTodayButton />
				<NavTextDisplay />
			</DivDate>
			{isDesktop && getGroupSelector()}
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Tooltip title={showFromToday ? t("undo") : t("setTodayAsFirst")}>
					<IconButton
						onClick={toggleViewFromToday}
						sx={{ color: showFromToday ? colors.orange : "inherit" }}>
						<TodayOutlinedIcon />
					</IconButton>
				</Tooltip>
				<DivNav>
					<NavDirectionButton direction={DECREMENT} />
					<NavViewScopeButton />
					<NavDirectionButton direction={INCREMENT} />
				</DivNav>
			</Box>
			{!isDesktop && getGroupSelector()}
		</Div>
	);
};

NavAppBar.propTypes = {
	/** The view object from state */
	view: PropTypes.object,
	/** A function to move the current view */
	viewMoveCurrent: PropTypes.func,
	/** A boolean representing whether to show the modal or not */
	modalType: PropTypes.string,
	/** Function for setting the showFromToday property in the view state */
	viewSetShowFromToday: PropTypes.func,
	/** A boolean representing whether the view from today option is enabled */
	showFromToday: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		view: state.view,
		modalType: state.modals.modalType,
		showFromToday: state.view.showFromToday
	};
};

export default connect(mapStateToProps, { viewMoveCurrent, viewSetShowFromToday })(NavAppBar);
