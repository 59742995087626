import React, { useEffect, useState } from "react";
import DivRoot from "components/AppStyles";
import { isUserAdmin } from "services/apiAdminService";
import { LoaderSpinner } from "components/Loading/LoaderSpinners";
import MenuAppBar from "components/MenuAppBar/MenuAppBar";
import AdminMenu from "components/AdminMenu/AdminMenu";
import { useTranslation } from "react-i18next";

/** Page component used to administrate groups */
function AdminPage() {
	const { t } = useTranslation();
	const [isAdmin, setIsAdmin] = useState(undefined);

	// Verify that the user is an admin before rendering the admin page
	useEffect(() => {
		const checkIfUserIsAdmin = async () => {
			const response = await isUserAdmin();
			if (response === undefined) setIsAdmin(false);
			setIsAdmin(response);
		};

		checkIfUserIsAdmin();
	}, []);

	// Show a loading spinner while waiting for the admin status to be verified
	if (isAdmin === undefined) {
		return <LoaderSpinner />;
	} else if (isAdmin === false) {
		return <h1>{t("notAdmin")}</h1>;
	}

	return (
		<DivRoot>
			<MenuAppBar />
			<AdminMenu />
		</DivRoot>
	);
}

export default AdminPage;
