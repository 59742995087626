import styled from "@emotion/styled";
import { Box, FormControl, Typography } from "@mui/material";
import { colors } from "styles/theme";

export const AdminMenuFormControl = styled(FormControl)(() => ({
	padding: "2%",
	paddingTop: "1%",
	width: "100%"
}));

export const AdminMenuTitle = styled(Typography)(() => ({
	marginBottom: "10px",
	color: colors.orange
}));

export const AdminMenuDescription = styled(Typography)(() => ({
	marginBottom: "20px"
}));

export const AdminMenuBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	gap: "2%",
	flexWrap: "wrap",
	maxWidth: "1500px"
}));
