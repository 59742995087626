import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { StyledNewCustomGroupButton } from "./NewCustomGroupButtonStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SHOW_NEW_CUSTOM_GROUP_MODAL, GROUP_TYPE_CUSTOM_GROUP } from "constants";
import { setSnackbar } from "actions/snackbarActions";
import { modalsSetModalType } from "actions/modalActions";

/**
 * Represents the button for creating a new custom group
 */
const NewCustomGroupButton = ({ groups, user, modalsSetModalType, setSnackbar }) => {
	const { t } = useTranslation();

	const handleClick = () => {
		const maxGroups = process.env.REACT_APP_MAXIMUM_CUSTOM_GROUPS;
		const numberOfGroups =
			groups !== undefined
				? Object.values(groups).filter(
						group =>
							group.groupType === GROUP_TYPE_CUSTOM_GROUP &&
							group.groupOwnerId === user.id
					).length
				: 0;

		if (numberOfGroups >= maxGroups) {
			setSnackbar(t("groupLimitReached"), "warning");
		} else {
			modalsSetModalType(SHOW_NEW_CUSTOM_GROUP_MODAL);
		}
	};

	return (
		<StyledNewCustomGroupButton variant="contained" onClick={handleClick}>
			<Typography>{t("newGroup")}</Typography>
		</StyledNewCustomGroupButton>
	);
};

NewCustomGroupButton.propTypes = {
	/** The groups object from state */
	groups: PropTypes.object,
	/** The user object from state */
	user: PropTypes.object,
	/** Action used to set the modal type */
	modalsSetModalType: PropTypes.func,
	/** Action used to display a snackbar message */
	setSnackbar: PropTypes.func
};

const mapStateToProps = state => {
	return {
		groups: state.user.groups,
		user: state.user
	};
};

export default connect(mapStateToProps, { modalsSetModalType, setSnackbar })(NewCustomGroupButton);
