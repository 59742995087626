import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { format } from "date-fns";
import TimelineDataItem from "../TimelineDataItem/TimelineDataItem";
import { Tooltip } from "@mui/material";
import {
	PARENTAL_LEAVE_KEYWORDS,
	TRAINING_KEYWORDS,
	VACATION_KEYWORDS,
	UNDISCLOSED_KEYWORDS,
	HOME_OFFICE_KEYWORDS,
	MOVING_KEYWORDS,
	WEDDING_KEYWORDS
} from "../../../constants";
import CalendarItemInfo from "components/CalendarItemInfo/CalendarItemInfo";
import { checkWords, deepCheckWordsVacation } from "../../../utils/checkWordsHelper";

const filterItemText = (itemText, t) => {
	if (
		!itemText ||
		itemText === undefined ||
		itemText.length === 0 ||
		checkWords(UNDISCLOSED_KEYWORDS, itemText)
	)
		return t("categoryPrivate");

	if (checkWords(PARENTAL_LEAVE_KEYWORDS, itemText)) return t("categoryParentalLeave");
	if (checkWords(TRAINING_KEYWORDS, itemText)) return t("categoryTraining");
	if (checkWords(HOME_OFFICE_KEYWORDS, itemText)) return t("categoryHomeOffice");
	if (checkWords(MOVING_KEYWORDS, itemText)) return t("categoryMoving");
	if (checkWords(WEDDING_KEYWORDS, itemText)) return t("categoryWedding");

	if (
		checkWords(VACATION_KEYWORDS, itemText) ||
		deepCheckWordsVacation(VACATION_KEYWORDS, itemText)
	)
		return t("categoryVacation");

	return t("categoryUncategorized");
};

/**
 * Represents a container for all timeline data items (events)
 */
const TimelineDataItemContainer = ({
	start,
	end,
	text,
	startDate,
	endDate,
	dateType,
	reRender,
	resize,
	isWinter,
	useCompactView
}) => {
	const { t } = useTranslation();
	let displayText = filterItemText(text, t);
	let newText = text;
	if (displayText === t("categoryPrivate")) {
		newText = null;
	}
	let event = {
		title: displayText,
		description: newText,
		start: format(startDate, "dd-MM-yyyy HH:mm"),
		end: format(endDate, "dd-MM-yyyy HH:mm")
	};

	// eslint-disable-next-line no-empty-function
	useEffect(() => {}, [reRender]);

	return (
		<Tooltip
			followCursor={true}
			title={<CalendarItemInfo event={event} />}
			componentsProps={{ tooltip: { sx: { background: "transparent" } } }}
			placement="bottom">
			<div style={{ pointerEvents: "auto" }}>
				<TimelineDataItem
					event={event}
					start={start}
					end={end}
					displayText={displayText}
					text={text}
					dateType={dateType}
					reRender={reRender}
					resize={resize}
					isWinter={isWinter}
					useCompactView={useCompactView}
				/>
			</div>
		</Tooltip>
	);
};

TimelineDataItemContainer.propTypes = {
	/** Item number start */
	start: PropTypes.number,
	/** Item number end */
	end: PropTypes.number,
	/** Item text */
	text: PropTypes.string,
	/** Start date from current view */
	startDate: PropTypes.object,
	/** End date from current view */
	endDate: PropTypes.object,
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Indicates if the component should reRender */
	reRender: PropTypes.string,
	/** Indicates if the windowsize has changed */
	resize: PropTypes.bool,
	/** Indicates if the event is in winter */
	isWinter: PropTypes.bool,
	/** Indicates if a compact view should be shown */
	useCompactView: PropTypes.bool
};

export default TimelineDataItemContainer;
