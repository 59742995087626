import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";

export const AppBarRoot = styled(AppBar)(({ theme }) => ({
	flexGrow: 1,
	backgroundColor: theme.palette.primary.dark
}));

export const DivDivider = styled("div")(() => ({
	flexGrow: 1
}));

export const ImgAwaylogo = styled("img")(() => ({
	height: "50px",
	marginTop: "12px",
	paddingRight: "0px"
}));
