import styled from "@emotion/styled";

export const TimeLineUserText = styled("div")(({ theme, useCompactView }) => ({
	...theme.timeline.timelineUserText,
	color: "#e7e7e7",
	fontSize: useCompactView ? "12px" : "16px",
	whiteSpace: useCompactView ? "nowrap" : "normal",
	marginRight: useCompactView ? "5px" : "0px",
	flexBasis: "70%"
}));

export const TimeLineUserItemContainer = styled("div")(() => ({
	width: "270px",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center"
}));

export const TimeLineUserItemContainerMobile = styled("div")(() => ({
	width: "65px",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center"
}));
