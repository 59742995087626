import { combineReducers } from "redux";
import user from "./userReducer";
import view from "./viewReducer";
import loading from "./loadingReducer";
import event from "./eventReducer";
import usersPhotos from "./userPhotoReducer";
import modals from "./modalReducer";
import snackbar from "./snackbarReducer";

export default combineReducers({
	user,
	view,
	loading,
	event,
	usersPhotos,
	modals,
	snackbar
});
