import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { toCamelCase, toUpperCamelCase } from "../../utils/helper-funcs";
import { ReactComponent as PrivateIcon } from "../Icons/privateIcon.svg";
import { ReactComponent as ParentalLeaveIcon } from "../Icons/parentalLeaveIcon.svg";
import { ReactComponent as TrainingIcon } from "../Icons/trainingIcon.svg";
import { ReactComponent as VacationIcon } from "../Icons/vacationIcon.svg";
import { ReactComponent as WinterVacationIcon } from "../Icons/winterVacationIcon.svg";
import { ReactComponent as HomeOfficeIcon } from "../Icons/homeOfficeIcon.svg";
import { ReactComponent as MovingIcon } from "../Icons/movingIcon.svg";
import { ReactComponent as WeddingIcon } from "../Icons/weddingIcon.svg";
import { ReactComponent as UncategorizedIcon } from "../Icons/uncategorizedIcon.svg";
import {
	UNDISCLOSED_KEYWORDS,
	PARENTAL_LEAVE_KEYWORDS,
	TRAINING_KEYWORDS,
	VACATION_KEYWORDS,
	HOME_OFFICE_KEYWORDS,
	MOVING_KEYWORDS,
	WEDDING_KEYWORDS
} from "../../constants";

const icons = {
	vacation: VacationIcon,
	parentalLeave: ParentalLeaveIcon,
	training: TrainingIcon,
	winterVacationIcon: WinterVacationIcon,
	homeOffice: HomeOfficeIcon,
	moving: MovingIcon,
	wedding: WeddingIcon,
	uncategorized: UncategorizedIcon,
	private: PrivateIcon
};
const keywords = {
	vacation: VACATION_KEYWORDS,
	parentalLeave: PARENTAL_LEAVE_KEYWORDS,
	training: TRAINING_KEYWORDS,
	homeOffice: HOME_OFFICE_KEYWORDS,
	moving: MOVING_KEYWORDS,
	wedding: WEDDING_KEYWORDS,
	private: UNDISCLOSED_KEYWORDS
};

const Icon = ({ type }) => {
	const SpecificIcon = icons[toCamelCase(type)];
	return <SpecificIcon />;
};

/**
 * Represents a ListItemText with category name and corresponding keywords
 */
const TextElement = ({ type }) => {
	const words = keywords[toCamelCase(type)];
	const { t } = useTranslation();
	const title = "category" + toUpperCamelCase(type);

	let keywordsStr = t("keywords") + ": ";

	for (const index in words) {
		let keyword = words[index];
		if (index === "0") continue;

		if (index === "1") {
			// First word
			keyword = keyword.toLowerCase();
			keyword = keyword.charAt(0).toUpperCase() + keyword.slice(1); // First char uppercase
			keyword += ", ";
		} else if (index === (words.length - 1).toString()) {
			// Last word
			keyword = keyword.toLowerCase();
		} else {
			keyword = keyword.toLowerCase() + ", ";
		}
		keywordsStr += keyword;
	}

	return <ListItemText primary={t(title)} secondary={keywordsStr} />;
};

/**
 * Represents the list view of categories and keywords
 */
const CategoryDescriptionItem = props => {
	const { absenceType } = props;
	const { t } = useTranslation();

	if (absenceType === "Uncategorized" || absenceType === "Private") {
		const title = "category" + toUpperCamelCase(absenceType);
		const description = absenceType.toLowerCase() + "Description";
		return (
			<ListItem key={absenceType}>
				<ListItemAvatar>
					<Avatar>
						<Icon type={absenceType} />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={t(title)} secondary={t(description)} />
			</ListItem>
		);
	}
	if (absenceType === "Vacation")
		return (
			<ListItem key={absenceType}>
				<ListItemAvatar
					style={{ display: "flex", flexDirection: "column", position: "relative" }}>
					<Avatar
						sx={{
							width: 35,
							height: 35,
							position: "absolute",
							bottom: "-7px",
							left: "0px"
						}}>
						<Icon type={absenceType} />
					</Avatar>
					<Avatar
						sx={{
							width: 35,
							height: 35,
							position: "absolute",
							top: "-5px",
							left: "15px"
						}}>
						<Icon type={"WinterVacationIcon"} />
					</Avatar>
				</ListItemAvatar>
				<TextElement type={absenceType} />
			</ListItem>
		);
	return (
		<ListItem key={absenceType}>
			<ListItemAvatar>
				<Avatar>
					<Icon type={absenceType} />
				</Avatar>
			</ListItemAvatar>
			<TextElement type={absenceType} />
		</ListItem>
	);
};

CategoryDescriptionItem.propTypes = {
	absenceType: PropTypes.string.isRequired
};
TextElement.propTypes = {
	type: PropTypes.string.isRequired
};
Icon.propTypes = {
	type: PropTypes.string.isRequired
};

export default CategoryDescriptionItem;
