import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AbsenceTypesIcon from "../AbsenceTypesIcon/AbsenceTypesIcon";
import {
	DivTimelineEventText,
	DivTimelineUserText,
	CreateTimeLineDataItem
} from "./TimelineDataItemStyles";

// Checks if both the height and width of a text div
// is too large for its parent bar div
const textDisabled = (textDiv, hiddenDiv, barDiv) => {
	let a = null;
	let b = null;
	if (hiddenDiv) {
		a = hiddenDiv.scrollWidth > barDiv.offsetWidth;
		b = hiddenDiv.scrollHeight > barDiv.offsetHeight;
	} else if (textDiv) {
		a = textDiv.scrollWidth > barDiv.offsetWidth;
		b = textDiv.scrollHeight > barDiv.offsetHeight;
	} else {
		a = true;
		b = true;
	}
	return a || b;
};

/**
 * Represents an item (event) in the timeline
 *
 * Note: There are two icons in this component (instead of just one).
 * This is to reliably measure the entire width of icon + text,
 * thus preventing jitter between showing "text + icon" or "icon".
 */
const TimelineDataItem = ({
	start,
	end,
	displayText,
	text,
	dateType,
	reRender,
	resize,
	isWinter,
	useCompactView
}) => {
	const ratio = (38.4 / window.innerWidth) * 100;
	const tooSmall = end - start < ratio;
	const barRef = useRef();
	const textRef = useRef();
	const hiddenRef = useRef();
	const [textIsTooBig, setTextIsTooBig] = useState(true);
	const StyledTimelineDataItem = CreateTimeLineDataItem({
		start,
		end,
		isWinter,
		text,
		dateType,
		useCompactView
	});

	useEffect(() => {
		// Rerender component
		const isDisabled = textDisabled(textRef.current, hiddenRef.current, barRef.current);
		setTextIsTooBig(isDisabled);
	}, [barRef, hiddenRef, textRef, reRender, resize]);

	return (
		<StyledTimelineDataItem ref={barRef}>
			<DivTimelineUserText ref={textRef} useCompactView={useCompactView}>
				{useCompactView === false && (
					<>
						{/* If the text and icon cannot fit inside of the entry bar then this icon is
						visible in the center. Unless the icon also is too small - then neither icon
						or text will be visible. */}
						<AbsenceTypesIcon
							title={text}
							isWinter={isWinter}
							centerIcon={true}
							hidden={!textIsTooBig}
							tooSmall={tooSmall}
						/>
						{/* If the text and icon can fit inside of the entry bar then this icon will
						be visible to the left of the text */}
						<AbsenceTypesIcon
							title={text}
							isWinter={isWinter}
							centerIcon={false}
							hidden={textIsTooBig}
							tooSmall={tooSmall}
						/>
					</>
				)}
				<DivTimelineEventText
					style={{ visibility: textIsTooBig ? "hidden" : "visible" }}
					useCompactView={useCompactView}>
					{displayText}
				</DivTimelineEventText>
			</DivTimelineUserText>
		</StyledTimelineDataItem>
	);
};

TimelineDataItem.propTypes = {
	/** Item number start */
	start: PropTypes.number,
	/** Item number end */
	end: PropTypes.number,
	/** The content text for the item */
	displayText: PropTypes.string,
	/** The title text for the item */
	text: PropTypes.string,
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Indicates if the component should reRender */
	reRender: PropTypes.string,
	/** Indicates if the windowsize has changed */
	resize: PropTypes.bool,
	/** Indicates if the event is in winter */
	isWinter: PropTypes.bool,
	/** Indicates if a compact view should be shown */
	useCompactView: PropTypes.bool
};

export default TimelineDataItem;
