import React from "react";
import MenuAppBar from "components/MenuAppBar/MenuAppBar";
import Timeline from "components/Timeline";
import DivRoot from "components/AppStyles";

function TimeLinePage() {
	return (
		<DivRoot>
			<MenuAppBar />
			<Timeline />
		</DivRoot>
	);
}

export default TimeLinePage;
