import React from "react";
import DivRoot from "components/AppStyles";
import MenuAppBar from "components/MenuAppBar/MenuAppBar";
import FeedbackMenu from "components/FeedbackMenu/FeedbackMenu";

// /** Page component used to administrate groups */
function FeedbackPage() {
	return (
		<DivRoot>
			<MenuAppBar />
			<FeedbackMenu />
		</DivRoot>
	);
}

export default FeedbackPage;
