import React, { useState, useEffect } from "react";
import { getADGroups } from "services/apiAdminService";
import AdminMenuGroupsTable from "./AdminMenuGroupsTable/AdminMenuGroupsTable";
import {
	AdminMenuBox,
	AdminMenuDescription,
	AdminMenuFormControl,
	AdminMenuTitle
} from "./AdminMenuStyles";
import AdminMenuGroupSearch from "./AdminMenuGroupSearch/AdminMenuGroupSearch";
import { useTranslation } from "react-i18next";
import ErrorSnackbar from "components/ErrorSnackbar/ErrorSnackbar";

/** Component used to enable and disable groups */
function AdminMenu() {
	const { t } = useTranslation();
	const [tableGroups, setTableGroups] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);

	// Load all groups from the backend on component mount
	useEffect(() => {
		loadTableGroups();
	}, []);

	// Loads all active groups from the backend and updates tableGroups state
	const loadTableGroups = async () => {
		setShowSpinner(true);
		const response = await getADGroups();
		if (response) {
			setTableGroups(response);
		}
		setShowSpinner(false);
		return response;
	};

	return (
		<AdminMenuFormControl>
			<AdminMenuTitle variant="h3">{t("welcomeAdmin")}</AdminMenuTitle>
			<AdminMenuDescription variant="h5">
				{t("adminDescription1")}
				<br />
				<br />* {t("adminDescription2")}
				<br />
				<br />* {t("adminDescription3")}
			</AdminMenuDescription>

			<AdminMenuBox>
				<AdminMenuGroupsTable
					tableGroups={tableGroups}
					setTableGroups={setTableGroups}
					showSpinner={showSpinner}
				/>

				<AdminMenuGroupSearch tableGroups={tableGroups} loadTableGroups={loadTableGroups} />
			</AdminMenuBox>
			<ErrorSnackbar />
		</AdminMenuFormControl>
	);
}

export default AdminMenu;
