import React, { useState } from "react";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import UserMenu from "../UserMenu/UserMenu";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import UserPhoto from "../UserPhoto/UserPhoto";

/**
 * Represents the icon for user, displaying UserMenu
 */
const UserMenuIcon = ({ user }) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip enterDelay={600} title={t("userSettings")} aria-label="user settings">
				<IconButton
					data-testid="menu-icon-btn"
					aria-label="account of current user"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenu}
					color="inherit"
					size="large">
					<UserPhoto userId={user.id} size="30px" />
				</IconButton>
			</Tooltip>
			<UserMenu user={user} anchorEl={anchorEl} open={open} handleClose={handleClose} />
		</div>
	);
};

UserMenuIcon.propTypes = {
	/** The user object from state */
	user: PropTypes.object
};

const mapStateToProps = state => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps)(UserMenuIcon);
