import { SimpleGroupDto } from "models/simpleGroupDto";

// eslint-disable-next-line no-undef
export const ENDPOINT = process.env.REACT_APP_ENDPOINT;

/** Group Type Constants */
export const GROUP_TYPE_CUSTOM_GROUP = "GROUP_TYPE_CUSTOM_GROUP";
export const GROUP_TYPE_AD_GROUP = "GROUP_TYPE_AD_GROUP";

/** User Constants */
export const USER_SET_USER_INFO = "USER_SET_USER_INFO";
export const USER_SET_FILTER_TIMELINE = "USER_SET_FILTER_TIMELINE";
export const USER_SET_FILTER_NO_WORKING_HOURS = "USER_SET_FILTER_NO_WORKING_HOURS";
export const USER_SET_FILTER_RECURRING_EVENTS = "USER_SET_FILTER_RECURRING_EVENTS";
export const USER_SET_SELECTED_GROUP = "USER_SET_SELECTED_GROUP";
export const SELECTED_GROUP_ALL_OPTION = new SimpleGroupDto("all", "all", false, "", "");

/** View Constants */
export const VIEW_SET_TODAY_CURRENT = "VIEW_SET_TODAY_CURRENT";
export const VIEW_SET_SCOPE = "VIEW_SET_SCOPE";
export const VIEW_MOVE_CURRENT = "VIEW_MOVE_CURRENT";
export const VIEW_SET_CURRENT = "VIEW_SET_CURRENT";
export const VIEW_SET_SHOW_FROM_TODAY = "VIEW_SET_SHOW_FROM_TODAY";
export const VIEW_SET_COMPACT_VIEW = "VIEW_SET_COMPACT_VIEW";
export const DAY = "day";
export const WEEK = "week";
export const MONTH = "month";
export const YEAR = "year";
export const TIMELINE_OPTIONS = [DAY, WEEK, MONTH, YEAR];
export const TIMELINE_PREFERENCE = "timeLineViewPreference";
export const TIMELINE_START = "timeLineViewStart";
export const TIMELINE_END = "timeLineViewEnd";
export const TIMELINE_CURRENT = "timeLineViewCurrent";
export const INCREMENT = 1;
export const DECREMENT = -1;

/** Loading Constants */
export const LOADING_SET_IS_LOADING = "LOADING_SET_IS_LOADING";
export const LOADING_ADD_TO_LOADING_QUEUE = "LOADING_ADD_TO_LOADING_QUEUE";
export const LOADING_REMOVE_FROM_LOADING_QUEUE = "LOADING_REMOVE_FROM_LOADING_QUEUE";

/** Event Constants */
export const EVENT_SET_EVENTS = "EVENT_SET_EVENTS";
export const EVENT_ADD_EVENTS = "EVENT_ADD_EVENTS";

/** User Photo Constants */
export const ADD_USERS_PHOTOS = "ADD_USERS_PHOTOS";

/** Feedback constants */
export const FEEDBACK_INTERVAL = parseInt(process.env.REACT_APP_FEEDBACK_INTERVAL);

/** Modal Constants */
export const MODALS_SET_CUSTOM_GROUP_MODAL_GROUP = "MODAL_SET_CUSTOM_GROUP_MODAL_GROUP";
export const MODALS_SET_CUSTOM_GROUP_SELECTED_USERS = "MODALS_SET_CUSTOM_GROUP_SELECTED_USERS";
export const MODALS_SET_MODAL_TYPE = "MODALS_SET_MODAL_TYPE";
export const MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP = "MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP";

/** Snackbar Constants */
export const SET_SNACKBAR = "SET_SNACKBAR";
export const SNACKBAR_TIMEOUT = 6000;

/** Custom group modal constants */
export const HIDE_MODALS = "HIDE_MODALS";
export const SHOW_NEW_CUSTOM_GROUP_MODAL = "SHOW_NEW_CUSTOM_GROUP_MODAL";
export const SHOW_EDIT_CUSTOM_GROUP_MODAL = "SHOW_EDIT_CUSTOM_GROUP_MODAL";
export const MAX_GROUP_NAME_LENGTH = 50;
export const MIN_GROUP_NAME_LENGTH = 1;

/** Timeline Constants */
export const ABSENCE_TYPE_PARENTALLEAVE = ">PARENTALLEAVE";
export const ABSENCE_TYPE_TRAINING = ">TRAINING";
export const ABSENCE_TYPE_VACATION = ">VACATION";
export const ABSENCE_TYPE_HOMEOFFICE = ">HOMEOFFICE";
export const ABSENCE_TYPE_MOVING = ">MOVING";
export const ABSENCE_TYPE_WEDDING = ">WEDDING";
export const ABSENCE_TYPE_ILLNESS = ">ILLNESS";
export const ABSENCE_TYPE_DOCTOR = ">DOCTOR";

/** Keywords for out-of-office reasons */
export const PARENTAL_LEAVE_KEYWORDS = [
	ABSENCE_TYPE_PARENTALLEAVE,
	"PERM",
	"PERMISJON",
	"FØDSELSPERMISJON",
	"FØDSELS PERMISJON",
	"FORELDREPERMISJON",
	"FORELDRE PERMISJON",
	"MAMMAPERM",
	"MAMMA PERM",
	"MAMMAPERMISJON",
	"MAMMA PERMISJON",
	"MORSPERM",
	"MORSPERMISJON",
	"MORS PERMISJON",
	"PAPPAPERM",
	"PAPPA PERM",
	"PAPPAPERMISJON",
	"PAPPA PERMISJON",
	"FARSPERM",
	"FARSPERMISJON",
	"FARS PERMISJON",
	"SVANGERSKAPSPERMISJON",
	"SVANGERSKAPS PERMISJON",
	"PARENTAL",
	"PARENTAL LEAVE",
	"MATERNITY",
	"MATERNITY LEAVE",
	"PATERNITYLEAVE",
	"PATERNITY LEAVE"
];
export const TRAINING_KEYWORDS = [
	ABSENCE_TYPE_TRAINING,
	"SEMINAR",
	"KURS",
	"OPPLÆRING",
	"TRAINING",
	"SKOLE",
	"CLASS",
	"COURSE",
	"SCHOOL",
	"KONFERANSE",
	"CONFERENCE",
	"CONVENTION"
];
export const VACATION_KEYWORDS = [
	ABSENCE_TYPE_VACATION,
	"ARBEIDSFRI",
	"DRØMMEFERIE",
	"FERIE",
	"FRI",
	"FRI DAG",
	"HØSTFERIE",
	"HØST FERIE",
	"JULEFERIE",
	"JULE FERIE",
	"PÅSKEFERIE",
	"PÅSKE FERIE",
	"PINSE",
	"PINSEFERIE",
	"PINSE FERIE",
	"SKI",
	"SKIFERIE",
	"SKI FERIE",
	"SOMMER",
	"SOMMERFERIE",
	"SOMMER FERIE",
	"VINTER",
	"VINTERFERIE",
	"VINTER FERIE",
	"CHRISTMAS",
	"CHRISTMAS HOLIDAY",
	"CHRISTMASHOLIDAY",
	"CHRISTMASHOLIDAYS",
	"CHRISTMAS HOLIDAY",
	"CHRISTMAS HOLIDAYS",
	"EASTER HOLIDAY",
	"EASTERHOLIDAY",
	"FREE",
	"HOLIDAY",
	"HOLIDAYS",
	"SUMMER",
	"SUMMERHOLIDAY",
	"SUMMER HOLIDAY",
	"VACATION",
	"WINTER",
	"WINTERHOLIDAY",
	"WINTER HOLIDAY",
	"XMAS",
	"XMASHOLIDAY",
	"XMASHOLIDAYS",
	"XMAS HOLIDAY",
	"XMAS HOLIDAYS"
];
export const HOME_OFFICE_KEYWORDS = [ABSENCE_TYPE_HOMEOFFICE, "HJEMMEKONTOR", "HOMEOFFICE"];
export const MOVING_KEYWORDS = [
	ABSENCE_TYPE_MOVING,
	"FLYTTING",
	"MOVING",
	"FLYTTEDAG",
	"MOVINGDAY",
	"FLYTTE",
	"MOVE"
];
export const WEDDING_KEYWORDS = [
	ABSENCE_TYPE_WEDDING,
	"BRYLLUP",
	"WEDDING",
	"GIFTING",
	"GIFT",
	"GIFTET",
	"GIFTE",
	"WED"
];

// The following is categorized as private
export const UNDISCLOSED_KEYWORDS = [
	"UNDISCLOSED",
	ABSENCE_TYPE_DOCTOR,
	"DOKTOR",
	"LEGE",
	"TANNLEGE",
	"DOCTOR",
	"DOC",
	"DENTIST",
	"FYSIO",
	"FYSIOTERAPAUT",
	"KIROPRAKTOR",
	"AKUPUNKTØR",
	ABSENCE_TYPE_ILLNESS,
	"SYK",
	"SYKEMELDING",
	"INFLUENSA",
	"FEBER",
	"SICK",
	"SICKNESS",
	"ILLNESS",
	"FLU",
	"SYKEDAG",
	"SYKEDAGER",
	"SYKEDAGENE"
];

export const FEEDBACK_TYPES = [
	"feedbackCategoryNoCategory",
	"feedbackRequestEntraIdAccess",
	"feedbackCategoryBug",
	"feedbackCategoryUsability",
	"feedbackCategoryFrontend",
	"feedbackCategoryBackend",
	"feedbackCategoryFeature",
	"feedbackCategoryOther"
];

// i18next
export const englishCode = "en";
export const norwegianCode = "nb";

// date-fns
export const dateFnsOptions = {
	weekStartsOn: 1, // 1 = monday
	firstWeekContainsDate: 4 // 4 = thursday
};
