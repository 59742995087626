import { userSetUserInfo } from "actions/userActions";
import { addYears } from "date-fns";
import { getSchedulesIncrementally } from "config/initUserConfig";
import { eventSetEvents } from "actions/eventActions";
import { GROUP_TYPE_CUSTOM_GROUP } from "../constants";

const getSchedulesIncrementallySingleGroup = group => {
	const startDate = new Date();
	getSchedulesIncrementally(
		{ [group.id]: group },
		addYears(startDate, -1),
		addYears(startDate, 1),
		200
	);
};

// Helper function for adding a new group to state
export const addGroupToState = (newGroup, store, dispatch) => {
	const state = store.getState();
	const groups = { ...state.user.groups };
	groups[newGroup.id] = newGroup;
	dispatch(userSetUserInfo({ ...state.user, groups: groups }));
	getSchedulesIncrementallySingleGroup(newGroup); // Fetch calendar data for the new group
};

// Helper function for editing a group in state
export const modifyGroupInState = (modifiedGroup, store, dispatch) => {
	const state = store.getState();
	const groups = { ...state.user.groups };
	groups[modifiedGroup.id] = modifiedGroup;
	dispatch(userSetUserInfo({ ...state.user, groups: groups }));
	getSchedulesIncrementallySingleGroup(modifiedGroup); // Fetch calendar data for the edited group
};

// Helper function for removing a group from state
export const removeGroupFromState = (groupId, store, dispatch) => {
	const state = store.getState();
	const groups = { ...state.user.groups };
	const groupWithIdExists = Object.values(groups).some(g => g.id === groupId);

	if (groupWithIdExists) {
		delete groups[groupId]; // Remove group from user in state
		dispatch(userSetUserInfo({ ...state.user, groups: groups }));
		removeEntriesFromGroupInState(groupId, store, dispatch); // Remove all entries part of the deleted group from state
	}
};

// Helper function for removing all entries part of a group from state
export const removeEntriesFromGroupInState = (groupId, store, dispatch) => {
	const state = store.getState();
	const groupEntriesMap = state.event.groupEntries;
	if (groupEntriesMap && groupEntriesMap.has(groupId)) {
		const updatedGroupEntriesMap = new Map(groupEntriesMap);
		updatedGroupEntriesMap.delete(groupId);
		dispatch(eventSetEvents(updatedGroupEntriesMap));
	}
};

// Helper function for getting the name of the owner of a group
export const getGroupOwnerName = (group, groupEntries) => {
	if (groupEntries && groupEntries.has(group.id)) {
		const groupOwnerUser = groupEntries
			.get(group.id)
			.find(entry => entry.user.id === group.groupOwnerId);
		if (groupOwnerUser) {
			return groupOwnerUser.user.name;
		}
	}
	return undefined;
};

// Helper function for getting the groups as a sorted list
export const getSortedGroups = (groups, user) => {
	const customGroup = GROUP_TYPE_CUSTOM_GROUP;

	const customGroupsShared = [];
	const yourCustomGroups = [];
	const bouvetGroups = [];

	Object.values(groups).forEach(group => {
		if (group.groupType === customGroup) {
			if (group.groupOwnerId === user.id) {
				yourCustomGroups.push(group);
			} else {
				customGroupsShared.push(group);
			}
		} else {
			bouvetGroups.push(group);
		}
	});

	return [
		...yourCustomGroups.sort((a, b) => a.name.localeCompare(b.name)),
		...customGroupsShared.sort((a, b) => a.name.localeCompare(b.name)),
		...bouvetGroups.sort((a, b) => a.name.localeCompare(b.name))
	];
};
