import styled from "@emotion/styled";

export const TimeLineUserRowStyled = styled("div")(({ useCompactView }) => {
	const minHeight = useCompactView ? "24px" : "70px";
	return {
		height: minHeight,
		flexGrow: "1",
		display: "flex"
	};
});

export const TimeLineUserItemStyled = styled("div")(({ theme, useCompactView }) => {
	const minHeight = useCompactView ? "24px" : "70px";
	return {
		...theme.timeline.timelineUserItem,
		height: minHeight,
		flexGrow: "1"
	};
});
