import styled from "@emotion/styled";
import { List, ListItem, CircularProgress } from "@mui/material";
import { colors } from "styles/theme";

export const StyledList = styled(List)(() => ({
	width: "100%",
	maxWidth: 360,
	maxHeight: "30vh",
	backgroundColor: colors.gridColor,
	overflow: "auto",
	justifyContent: "center",

	"::-webkit-scrollbar": {
		width: "8px"
	},
	"::-webkit-scrollbar-track": {
		backgroundColor: colors.darkgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb": {
		backgroundColor: colors.lightgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#a9a9a9"
	}
}));

export const StyledListItem = styled(ListItem)(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center"
}));

export const StyledCircularProgress = styled(CircularProgress)({
	color: "#E05902"
});
