import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
// import { deleteFeedbackById } from "services/apiFeedbackService";
import PropTypes from "prop-types";
import { colors } from "styles/theme";

function AcceptDialog({ dialogTitle, showDialog, handleClickYes, handleClickNo }) {
	const { t } = useTranslation();

	return (
		<>
			<Dialog
				open={showDialog}
				sx={{
					minWidth: "200px",
					margin: "auto"
				}}>
				<DialogTitle id="delete-dialog-title">{dialogTitle}</DialogTitle>
				<DialogActions>
					<Button
						variant="contained"
						onClick={handleClickNo}
						sx={{
							width: "10%",
							bgcolor: colors.orange,
							"&:hover": {
								bgcolor: colors.grey
							}
						}}>
						{t("noText")}
					</Button>
					<Button
						variant="contained"
						onClick={handleClickYes}
						autoFocus
						sx={{
							width: "10%",
							bgcolor: colors.orange,
							"&:hover": {
								bgcolor: colors.grey
							}
						}}>
						{t("yesText")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

// Props validation
AcceptDialog.propTypes = {
	/** The title displayed in the dialog */
	dialogTitle: PropTypes.string,
	/** Boolean representing whether to show the dialog */
	showDialog: PropTypes.bool,
	/** Function called at click no */
	handleClickNo: PropTypes.func,
	/** Function called at click yes */
	handleClickYes: PropTypes.func
};

export default AcceptDialog;
