import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import theme from "styles/theme";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import "../i18n";
import App from "components/App";
import { store } from "../reducers/store";
import { Provider } from "react-redux";

const AuthApp = () => {
	return (
		<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Provider store={store}>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</Provider>
				</ThemeProvider>
			</StyledEngineProvider>
		</MsalAuthenticationTemplate>
	);
};

export default AuthApp;
