import styled from "@emotion/styled";
import { Chip, Box, Typography } from "@mui/material";
import { colors } from "styles/theme";

export const StyledSelectedUsersContainer = styled(Box)(() => ({
	borderRadius: "3px",
	position: "relative",
	paddingLeft: " 2px",
	paddingTop: "2px",
	marginBottom: "15px"
}));

export const StyledContainerTitle = styled(Typography)(() => ({
	position: "absolute",
	top: -10,
	left: 10,
	padding: "0 5px",
	backgroundColor: colors.gridColor
}));

export const StyledContainerText = styled(Typography)(() => ({
	textAlign: "center",
	flexGrow: 1,
	paddingBottom: "5px"
}));

export const StyledChipContainer = styled("div")(() => ({
	display: "flex",
	flexWrap: "wrap",
	gap: "5px",
	padding: "5px",
	paddingTop: "10px",
	maxHeight: "130px",
	overflow: "auto",

	"::-webkit-scrollbar": {
		width: "8px"
	},
	"::-webkit-scrollbar-track": {
		backgroundColor: colors.darkgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb": {
		backgroundColor: colors.lightgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#a9a9a9"
	}
}));

export const StyledChip = styled(Chip)(() => ({
	fontSize: 11
}));
