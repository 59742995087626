import { SET_SNACKBAR } from "../constants";
import initialState from "./initialState";

/** Reducer for the snackbar object in state */
export default function (state = initialState.snackbar, action) {
	switch (action.type) {
		case SET_SNACKBAR: {
			return action.payload;
		}
		default:
			return state;
	}
}
