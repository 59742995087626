import { ADD_USERS_PHOTOS } from "../constants";
import { getUserProfilePhoto } from "services/apiUserService";

export const addUsersPhotos = photos => {
	return {
		type: ADD_USERS_PHOTOS,
		payload: photos
	};
};

export const getUsersPhotos = async (signedInUserId, userWithEventsList) => {
	const refresh = profile_pictures_need_refresh(
		// eslint-disable-next-line no-undef
		process.env.REACT_APP_CACHE_PHOTO_RELOAD_INTERVAL_DAYS
	);

	let usersPhotos = new Map();
	if (refresh === false) {
		// Get users' profile pictures from cache.
		usersPhotos = getCachedUsersPhotos(userWithEventsList);
	}

	// Get users' profile pictures from API. These users' profile pictures are not found in cache.
	let usersIEventsNoCachedPhoto = Array.from(Object.values(userWithEventsList)).filter(
		userWithEvents => usersPhotos.has(userWithEvents.user.id) === false
	);
	const promises = Array.from(Object.values(usersIEventsNoCachedPhoto), userEventsNoCachePhoto =>
		getUserProfilePhoto(userEventsNoCachePhoto.user.id)
	);

	// Fetch profile pictures from API, in parallel!
	const results = await Promise.allSettled(promises);

	// Filter out rejected requests
	const successfulPromises = results.filter(p => p.status === "fulfilled");

	for (const promise of successfulPromises) {
		if (promise.value !== null) {
			let photo = {
				userId: `${promise.value.userId}`,
				photoContentType: promise.value.photoContentType,
				encodedRawContent: promise.value.photoRawContent
			};
			// Store user's photo got from API in Web local storage.
			localStorage.setItem(photo.userId, JSON.stringify(photo));
			// Add user photo got from API to Map
			usersPhotos.set(promise.value.userId, photo);
		}
	}

	// If signed in user is not in list (no events), get profile picture separately
	if (usersPhotos.has(signedInUserId) === false) {
		let signedInUserPhoto = await getUserProfilePhoto(signedInUserId);
		if (signedInUserPhoto !== null) {
			let signedInPhoto = {
				userId: signedInUserId,
				photoContentType: signedInUserPhoto.photoContentType,
				encodedRawContent: signedInUserPhoto.photoRawContent
			};
			usersPhotos.set(signedInUserId, signedInPhoto);
		}
	}
	return usersPhotos;
};

function getCachedUsersPhotos(userWithEventsList) {
	const allPhotosFromCache = Array.from(Object.values(userWithEventsList), userWithEvents =>
		localStorage.getItem(userWithEvents.user.id)
	).filter(resultFromCache => resultFromCache !== null);

	// Put cached photos in Map to use in view.
	let usersPhotos = allPhotosFromCache.reduce((usersPhotosMap, photoFromCache) => {
		let aPhoto = JSON.parse(photoFromCache);
		usersPhotosMap.set(aPhoto.userId, aPhoto);
		return usersPhotosMap;
	}, new Map());
	return usersPhotos;
}

function profile_pictures_need_refresh(refresh_interval) {
	let date_got_photo_from_api = new Date(
		localStorage.getItem("DateDownloadedUsersProfilePicturesFromApi")
	);
	let today = new Date();
	let interval_in_days = Math.floor(
		(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
			Date.UTC(
				date_got_photo_from_api.getFullYear(),
				date_got_photo_from_api.getMonth(),
				date_got_photo_from_api.getDate()
			)) /
			(1000 * 60 * 60 * 24)
	);

	if (refresh_interval <= interval_in_days) {
		// Save in cache the new date.
		localStorage.setItem("DateDownloadedUsersProfilePicturesFromApi", today);
		// Profile pictures need refresh.
		return true;
	}

	// No need to refresh profile pictures.
	return false;
}
