import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import { IconButtonCloseButton } from "./CategoryDescriptionStyles";
import CloseIcon from "@mui/icons-material/Close";
import CategoryDescriptionItem from "../CategoryDescriptionItem/CategoryDescriptionItem";
import { DialogContent, DialogContentText } from "@mui/material";

const absenceTypes = [
	"Vacation",
	"Moving",
	"Wedding",
	"Parental leave",
	"Training",
	"Home office",
	"Uncategorized",
	"Private"
];

/**
 * Represents the category description dialog explaining all available categories and keywords.
 */
const CategoryDescription = props => {
	const { onClose, open } = props;
	const { t } = useTranslation();
	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="category-description-title" open={open}>
			<DialogTitle id="category-description-title">
				{t("description")}
				<IconButtonCloseButton aria-label="close" onClick={handleClose} size="large">
					<CloseIcon />
				</IconButtonCloseButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="category-description-text">
					{t("categoryDescription")}
				</DialogContentText>
				<List>
					{absenceTypes.map(absenceType => (
						<CategoryDescriptionItem absenceType={absenceType} key={absenceType} />
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};

CategoryDescription.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired
};

export default CategoryDescription;
