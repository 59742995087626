import {
	LOADING_SET_IS_LOADING,
	LOADING_ADD_TO_LOADING_QUEUE,
	LOADING_REMOVE_FROM_LOADING_QUEUE
} from "../constants";

export const loadingSetIsLoading = isLoading => {
	return {
		type: LOADING_SET_IS_LOADING,
		isLoading: isLoading
	};
};

export const loadingAddToLoadingQueue = id => {
	return {
		type: LOADING_ADD_TO_LOADING_QUEUE,
		id: id
	};
};

export const loadingRemoveFromLoadingQueue = id => {
	return {
		type: LOADING_REMOVE_FROM_LOADING_QUEUE,
		id: id
	};
};
