import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "reducers";
import initialState from "./initialState";

// eslint-disable-next-line no-undef
const currentEnv = process.env.NODE_ENV;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWare =
	currentEnv === "development"
		? composeEnhancers(applyMiddleware(thunk))
		: compose(applyMiddleware(thunk));

export const store = createStore(reducers, initialState, middleWare);
