import { ListItemIcon, ListItemText, CircularProgress, Typography } from "@mui/material";
import React, { useState, useMemo, useCallback } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import { addADGroups, searchADGroups } from "services/apiAdminService";
import PropTypes from "prop-types";
import {
	GroupSearchButton,
	GroupSearchContainer,
	GroupSearchField,
	GroupSearchFieldContainer,
	GroupSearchTitle,
	NoResultContainer,
	SearchResultsList,
	StyledListItem
} from "./AdminMenuGroupSearchSyles";
import { useTranslation } from "react-i18next";
import { addGroupToState } from "utils/groupHelper";
import { useDispatch, useStore } from "react-redux";
import { SimpleGroupDto } from "models/simpleGroupDto";
import { GROUP_TYPE_AD_GROUP } from "constants";

/* Component used to search for groups, display searched groups, and add new groups */
function AdminMenuGroupSearch({ tableGroups, loadTableGroups }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = useStore();
	const [searchText, setSearchText] = useState("");
	const [showSpinner, setShowSpinner] = useState(false);
	const [groupSearchResults, setGroupSearchResults] = useState([]);
	const [userHasSearched, setUserHasSearched] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleAddGroup = useCallback(
		async group => {
			const result = await addADGroups([group]);
			if (result) {
				const newGroups = await loadTableGroups();
				if (newGroups) {
					const currentUserIsMember = newGroups.some(
						newGroup => newGroup.id === group.id && newGroup.isMember
					);
					if (currentUserIsMember) {
						addGroupToState(
							new SimpleGroupDto(
								group.name,
								group.id,
								false,
								null,
								GROUP_TYPE_AD_GROUP
							),
							store,
							dispatch
						);
					}
				}
			}
		},
		[loadTableGroups, store, dispatch]
	);

	const handleGroupSearch = async () => {
		if (!searchText || searchText.trim() === "") {
			setErrorMessage(t("noSearchInputError"));
			return;
		}
		setErrorMessage("");

		// Hide previous search results before showing the spinner
		setUserHasSearched(true);

		setShowSpinner(true);
		const response = await searchADGroups(searchText);
		setShowSpinner(false);
		if (response) {
			setGroupSearchResults(response);
		}
	};

	let listElements = useMemo(() => {
		return groupSearchResults.map(group => {
			const isGroupInTable = tableGroups.some(tableGroup => tableGroup.id === group.id);
			return (
				<StyledListItem key={group.id}>
					<ListItemIcon>
						<GroupsIcon />
					</ListItemIcon>
					<ListItemText primary={group.name} />
					<GroupSearchButton
						variant="contained"
						onClick={() => handleAddGroup(group)}
						disabled={isGroupInTable}>
						{isGroupInTable ? t("added") : t("add")}
					</GroupSearchButton>
				</StyledListItem>
			);
		});
	}, [groupSearchResults, tableGroups, handleAddGroup, t]);

	return (
		<GroupSearchContainer>
			<GroupSearchTitle variant="h4">{t("addGroups")}</GroupSearchTitle>

			<GroupSearchFieldContainer>
				<GroupSearchField
					error={!!errorMessage}
					helperText={errorMessage}
					variant="outlined"
					placeholder={t("groupName")}
					label={t("searchGroups")}
					size="small"
					value={searchText}
					onChange={e => setSearchText(e.target.value)}
					onKeyDown={e => {
						if (e.key === "Enter") {
							handleGroupSearch();
						}
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
				<GroupSearchButton variant="contained" onClick={handleGroupSearch} size="small">
					{t("search")}
				</GroupSearchButton>
			</GroupSearchFieldContainer>

			{/* List the search results. Show a spinner while the search request is pending */}
			{showSpinner ? (
				<NoResultContainer>
					<CircularProgress color="secondary" />
				</NoResultContainer>
			) : (
				userHasSearched &&
				groupSearchResults.length === 0 && (
					<NoResultContainer>
						<Typography>{t("noGroupsFound")}</Typography>
					</NoResultContainer>
				)
			)}
			{!showSpinner && <SearchResultsList>{listElements}</SearchResultsList>}
		</GroupSearchContainer>
	);
}

AdminMenuGroupSearch.propTypes = {
	/** Array containing AD groups and a boolean indicating whether the user is a member of the group */
	tableGroups: PropTypes.array,
	/** Function used to load all groups from the backend */
	loadTableGroups: PropTypes.func
};

export default AdminMenuGroupSearch;
