import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledDiv } from "../UserMenu/UserMenuStyles";

export default function LoadingText() {
	const { t } = useTranslation();
	const [dots, setDots] = useState("");
	useEffect(() => {
		const interval = setInterval(() => {
			setDots(prev => (prev.length >= 3 ? "" : prev + "."));
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	return <>{<StyledDiv>{t("loadingText") + dots}</StyledDiv>}</>;
}
