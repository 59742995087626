import styled from "@emotion/styled";

export const TimeLineDateRowDiv = styled("div")(({ theme }) => ({
	...theme.timeline.box,
	display: "flex",
	justifyContent: "flex-start",
	flexGrow: "1"
}));

export const SpacerMobile = styled("div")(() => ({
	width: "65px",
	verticalAlign: "top"
}));

export const SpacerDesktop = styled("div")(() => ({
	width: "270px",
	verticalAlign: "top"
}));
