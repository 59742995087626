import React from "react";
import PropTypes from "prop-types";
import TimelineUserItem from "../TimelineUserItem/TimelineUserItem";
import TimelineUserItemMobile from "../TimelineUserItem/TimelineUserItemMobile";
import TimelineGridContainer from "../TimelineGridContainer/TimelineGridContainer";
import TimelineDataContainer from "../TimelineDataContainer/TimelineDataContainer";
import { useMediaQuery } from "@mui/material";
import { TimeLineUserRowStyled, TimeLineUserItemStyled } from "./TimelineUserRowStyles";

/**
 * Represent an row item in the timeline,
 * i.e. a user item and all corresponding events for this view
 */
const TimelineUserRow = ({
	name,
	data,
	dateType,
	startDate,
	endDate,
	ticks,
	dates,
	resize,
	outsideWorkingHoursFiltered,
	filteredRecurringEvents,
	filteredUsersWithNoEvents,
	useCompactView
}) => {
	const isDesktop = useMediaQuery("(min-width: 960px)");

	const container = TimelineDataContainer({
		data: data.events,
		startDate: startDate,
		endDate: endDate,
		dates: dates,
		resize: resize,
		dateType: dateType,
		outsideWorkingHoursFiltered: outsideWorkingHoursFiltered,
		filteredRecurringEvents: filteredRecurringEvents,
		filteredUsersWithNoEvents: filteredUsersWithNoEvents
	});

	if (container === null && filteredUsersWithNoEvents) {
		return null;
	}

	if (!isDesktop) {
		return (
			<div style={{ display: "flex", flexGrow: "1" }}>
				<TimelineUserItemMobile userWithEvents={data} />
				<TimeLineUserRowStyled>
					<TimeLineUserItemStyled>
						<TimelineGridContainer ticks={ticks} dates={dates} dateType={dateType} />
						<TimelineDataContainer
							data={data.events}
							startDate={startDate}
							endDate={endDate}
							resize={resize}
							dateType={dateType}
							outsideWorkingHoursFiltered={outsideWorkingHoursFiltered}
							filteredRecurringEvents={filteredRecurringEvents}
						/>
					</TimeLineUserItemStyled>
				</TimeLineUserRowStyled>
			</div>
		);
	}

	return (
		<div style={{ display: "flex", flexGrow: "1" }}>
			<TimelineUserItem
				name={name}
				start={startDate}
				end={endDate}
				userWithEvents={data}
				useCompactView={useCompactView}
			/>
			<TimeLineUserRowStyled useCompactView={useCompactView}>
				<TimeLineUserItemStyled useCompactView={useCompactView}>
					<TimelineGridContainer
						ticks={ticks}
						dates={dates}
						dateType={dateType}
						useCompactView={useCompactView}
					/>
					<TimelineDataContainer
						data={data.events}
						startDate={startDate}
						endDate={endDate}
						resize={resize}
						dateType={dateType}
						outsideWorkingHoursFiltered={outsideWorkingHoursFiltered}
						filteredRecurringEvents={filteredRecurringEvents}
						useCompactView={useCompactView}
					/>
				</TimeLineUserItemStyled>
			</TimeLineUserRowStyled>
		</div>
	);
};

TimelineUserRow.propTypes = {
	/** User name*/
	name: PropTypes.string,
	/** List of entries in the user's calendar for this view */
	data: PropTypes.object,
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Start date from current view */
	startDate: PropTypes.object,
	/** End date from current view */
	endDate: PropTypes.object,
	/** List of dates in the current view */
	dates: PropTypes.array,
	/** Number of sections the row is divided into */
	ticks: PropTypes.number,
	/** Indicates if the windowsize has changed */
	resize: PropTypes.bool,
	/** Indicates if the events should be filtered on working hours */
	outsideWorkingHoursFiltered: PropTypes.bool,
	/** Indicates if recurring events should be filtered */
	filteredRecurringEvents: PropTypes.bool,
	/** Indicates if users with no events should be filtered */
	filteredUsersWithNoEvents: PropTypes.bool,
	/** Indicates if the compact view should be shown */
	useCompactView: PropTypes.bool
};

export default TimelineUserRow;
