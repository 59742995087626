import React, { useState } from "react";
import { StyledMenu } from "./UserMenuStyles";
import authServiceInstance from "../../services/authService";
import PropTypes from "prop-types";
import CategoryDescription from "../CategoryDescription/CategoryDescription";
import UsageGuide from "../UsageGuide/UsageGuide";
import UserMenuItem from "../UserMenuItem/UserMenuItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TranslationUserMenuItem from "components/UserMenuItem/TranslationUserMenuItem";

/**
 * Represents the user menu displayed when clicking the user icon
 */
const UserMenu = ({ open, anchorEl, handleClose, currentUserIsAdmin }) => {
	const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
	const [openGuideDialog, setOpenGuideDialog] = useState(false);

	const logout = () => {
		authServiceInstance.msalClient.logout();
	};

	const handleDescriptionDialogOpen = () => {
		setOpenDescriptionDialog(true);
		handleClose();
	};
	const handleDescriptionDialogClose = () => {
		setOpenDescriptionDialog(false);
	};
	const handleGuideDialogOpen = () => {
		setOpenGuideDialog(true);
		handleClose();
	};
	const handleGuideDialogClose = () => {
		setOpenGuideDialog(false);
	};

	const currentPageIsTimeline =
		window.location.href.includes("admin") === false &&
		window.location.href.includes("feedback") === false;

	return (
		<div>
			<StyledMenu
				data-testid="user-menu"
				id="menu-appbar"
				anchorEl={anchorEl}
				open={open}
				keepMounted
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}>
				<div>
					{/* Need to be wrapped in a div to prevent React ref error! */}
					<UserMenuItem type={"user"} />
					<TranslationUserMenuItem handleClose={handleClose} />
					{currentPageIsTimeline && (
						<>
							<UserMenuItem
								type={"description"}
								handleClick={handleDescriptionDialogOpen}
							/>
							<UserMenuItem type={"usageGuide"} handleClick={handleGuideDialogOpen} />
							<Link to="/feedback" style={{ textDecoration: "none" }}>
								<UserMenuItem type={"feedbackPage"} />
							</Link>
						</>
					)}

					{currentUserIsAdmin && currentPageIsTimeline && (
						<Link to="/viewfeedback" style={{ textDecoration: "none" }}>
							<UserMenuItem type={"viewFeedbackPage"} />
						</Link>
					)}
					{currentUserIsAdmin && currentPageIsTimeline && (
						<Link to="/admin" style={{ textDecoration: "none" }}>
							<UserMenuItem type={"adminPage"} />
						</Link>
					)}
					{currentPageIsTimeline === false && (
						<Link to="/" style={{ textDecoration: "none" }}>
							<UserMenuItem type={"timelinePage"} />
						</Link>
					)}

					<UserMenuItem type={"signOut"} handleClick={logout} />
				</div>
			</StyledMenu>
			<CategoryDescription
				open={openDescriptionDialog}
				onClose={handleDescriptionDialogClose}
			/>
			<UsageGuide open={openGuideDialog} onClose={handleGuideDialogClose} />
		</div>
	);
};

UserMenu.propTypes = {
	/** Anchor element for positioning the menu */
	anchorEl: PropTypes.object,
	/** Function for handling close event on menu */
	handleClose: PropTypes.func,
	/** Boolean setting the open prop on the menu */
	open: PropTypes.bool,
	/** Boolean indicating if the current user is an admin */
	currentUserIsAdmin: PropTypes.bool
};
export const mapStateToProps = state => {
	return {
		currentUserIsAdmin: state.user.isAdmin
	};
};

export default connect(mapStateToProps)(UserMenu);
