import { useEffect } from "react";

export const useGlobalKeyPress = (keys, callback) => {
	useEffect(() => {
		const handleGlobalKeyPress = e => {
			const { key } = e;
			if (keys.includes(key)) {
				callback(e);
			}
		};

		document.addEventListener("keydown", handleGlobalKeyPress, true);

		return () => {
			document.removeEventListener("keydown", handleGlobalKeyPress, true);
		};
	}, [keys, callback]);
};
