import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { englishCode, norwegianCode } from "../../constants/index";
import TranslateIcon from "@mui/icons-material/TranslateOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { MenuItem } from "@mui/material";
import {
	DivTest,
	StyledDiv,
	TypographyText,
	TypographyNestedText
} from "../UserMenu/UserMenuStyles";

/**
 * Represents the translation item for changing language in UserMenu
 */
const TranslationUserMenuItem = ({ handleClose }) => {
	const { t, i18n } = useTranslation();
	const [openLangMenu, setOpenLangMenu] = useState(false);
	const lang = i18n.language === norwegianCode ? t("norwegianChange") : t("englishChange");

	const toggleLangMenu = () => {
		setOpenLangMenu(!openLangMenu);
	};

	const changeLanguage = languageCode => {
		i18n.changeLanguage(languageCode);

		// Save language preference to localStorage
		localStorage.setItem("i18nextLng", languageCode);

		handleClose(); // Close menu
		setOpenLangMenu(false); // Close language menu
	};

	return (
		<>
			<MenuItem onClick={toggleLangMenu}>
				<DivTest>
					<StyledDiv>
						<TranslateIcon />
						<TypographyText data-testid="usermenu-language" variant="body1">
							{lang}
						</TypographyText>
					</StyledDiv>
					{openLangMenu ? <ExpandLess /> : <ExpandMore />}
				</DivTest>
			</MenuItem>
			<Collapse in={openLangMenu} timeout="auto">
				<MenuItem onClick={() => changeLanguage(englishCode)}>
					<StyledDiv>
						<TypographyNestedText data-testid="usermenu-en-language" variant="body1">
							{t("english")}
						</TypographyNestedText>
					</StyledDiv>
				</MenuItem>
				<MenuItem onClick={() => changeLanguage(norwegianCode)}>
					<StyledDiv>
						<TypographyNestedText data-testid="usermenu-no-language" variant="body1">
							{t("norwegian")}
						</TypographyNestedText>
					</StyledDiv>
				</MenuItem>
			</Collapse>
		</>
	);
};

TranslationUserMenuItem.propTypes = {
	/** Function for handling close event on menu */
	handleClose: PropTypes.func
};

export default TranslationUserMenuItem;
