import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledSearchContainer, UsernameTextField } from "./UserSearchStyles";
import { getUsersBySearch } from "services/apiUserService";
import SearchResultList from "./SearchResultList/SearchResultList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUsersPhotos, addUsersPhotos } from "actions/userPhotoActions";
import { shapeUserToUserWithEvents } from "utils/helper-funcs";

/**
 * A component used to select users and display users
 */
function UserSearch({ usersPhotos, userId, addUsersPhotos, initialSearchInput = "" }) {
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState("");
	const [searchInput, setSearchInput] = useState(initialSearchInput);
	const [searchResult, setSearchResult] = useState([]);
	const [showLoading, setShowLoading] = useState(false);

	const handleSearch = async () => {
		// Ensure input is valid
		if (searchInput.length === 0) {
			setErrorMessage(t("noSearchInputError"));
			return;
		}

		setShowLoading(true);
		const listOfUsers = await getUsersBySearch(searchInput);
		setShowLoading(false);

		if (listOfUsers) {
			// Display search result in the SearchResultList component
			setSearchResult(listOfUsers);

			// Wait for usersPhotos.photos to be defined by getUsersPhotos call in initUserConfig.js
			while (!usersPhotos || !usersPhotos.photos) {
				await new Promise(resolve => setTimeout(resolve, 1000));
			}

			// Fetch user photos for the users in the search result
			const usersWithEvents = shapeUserToUserWithEvents(listOfUsers);
			const fetchedPhotos = await getUsersPhotos(userId, usersWithEvents);

			// Combine the fetched photos with the photos already in the store into a new map
			const combinedPhotos = new Map([...usersPhotos.photos, ...fetchedPhotos]);
			addUsersPhotos(combinedPhotos);
		}
	};

	const handlePressEnter = e => {
		if (e.keyCode === 13) {
			handleSearch();
		}
	};

	return (
		<>
			<StyledSearchContainer>
				<UsernameTextField
					error={!!errorMessage}
					helperText={errorMessage}
					data-testid="user-search-input"
					size="small"
					variant="outlined"
					label={t("entername")}
					onChange={e => setSearchInput(e.target.value)}
					onKeyDown={handlePressEnter}
					value={searchInput}
				/>
				<StyledButton onClick={handleSearch} data-testid="search-btn">
					{t("search")}
				</StyledButton>
			</StyledSearchContainer>
			<SearchResultList searchResult={searchResult} showLoading={showLoading} />
		</>
	);
}

UserSearch.propTypes = {
	/** Object from state containing the user photos */
	usersPhotos: PropTypes.object,
	/** The Id of the currenly logged in user */
	userId: PropTypes.string,
	/** Action for adding user photos */
	addUsersPhotos: PropTypes.func,
	/** The initial search input */
	initialSearchInput: PropTypes.string
};

const mapStateToProps = state => {
	return {
		usersPhotos: state.usersPhotos,
		userId: state.user.id
	};
};

export default connect(mapStateToProps, { addUsersPhotos })(UserSearch);
