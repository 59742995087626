import React from "react";
import { StyledCircularProgress, StyledList, StyledListItem } from "./SearchResultListStyles";
import PropTypes from "prop-types";
import UserResult from "./UserResult/UserResult";

/**
 * A container used to display users after a user search
 */
function SearchResultList({ searchResult, showLoading }) {
	const getSelectedUserListItems = () =>
		searchResult?.map(userSearchResult => (
			<UserResult
				key={userSearchResult.id}
				userId={userSearchResult.id}
				name={userSearchResult.name}
				email={userSearchResult.email}
			/>
		));

	return (
		<StyledList>
			{showLoading ? (
				// Display loading spinner
				<StyledListItem>
					<StyledCircularProgress data-testid="search-result-list-spinner" />
				</StyledListItem>
			) : (
				getSelectedUserListItems()
			)}
		</StyledList>
	);
}

SearchResultList.propTypes = {
	/** An array containing names and Ids representing the search result */
	searchResult: PropTypes.array,
	/** A boolean representing if the loading spinner should be displayed */
	showLoading: PropTypes.bool
};

export default SearchResultList;
