import React, { useState } from "react";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import TimelineSettingsMenu from "components/TimelineSettingsMenu/TimelineSettingsMenu";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

/**
 * Represents the icon for the timeline settings menu
 */
const TimelineSettingsMenuIcon = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const currentPageIsTimeline =
		window.location.href.includes("admin") === false &&
		window.location.href.includes("feedback") === false;

	if (!currentPageIsTimeline) {
		return null;
	}

	return (
		<div>
			<Tooltip enterDelay={600} title={t("timelineSettings")} aria-label="timeline settings">
				<IconButton
					data-testid="timelineSettings-icon-btn"
					aria-label="settings button for timeline"
					aria-controls="timeline-settings-menu-appbar"
					aria-haspopup="true"
					onClick={handleMenu}
					color="inherit"
					size="large">
					<TuneOutlinedIcon />
				</IconButton>
			</Tooltip>
			<TimelineSettingsMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
		</div>
	);
};

export default TimelineSettingsMenuIcon;
