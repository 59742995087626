import Holidays from "date-holidays";

export const getHolidays = dates => {
	const hd = new Holidays("NO");

	const holidays = new Map(
		hd
			.getHolidays(dates[0].getFullYear())
			.concat(hd.getHolidays(dates[dates.length - 1].getFullYear()))
			.map(holiday => [holiday.start.toISOString().split("T")[0], true])
	);
	// Creating the holiday mapping in this way also treats some important days that are not holidays as holidays.
	// This is removed in the following code. The dates in the quotes are the day before the event being deleted due to how the dates work.

	// Sami national day
	holidays.delete(dates[0].getFullYear() + "-02-05");
	// Valentine's day
	holidays.delete(dates[0].getFullYear() + "-02-13");
	// The King's birthday
	holidays.delete(dates[0].getFullYear() + "-02-20");
	// Mother's day
	holidays.delete(dates[0].getFullYear() + "-03-07");
	// Liberation day
	holidays.delete(dates[0].getFullYear() + "-05-07");
	// Norwegian Swedish union
	holidays.delete(dates[0].getFullYear() + "-06-06");
	// Summer solstice
	holidays.delete(dates[0].getFullYear() + "-06-22");
	// Don't know
	holidays.delete(dates[0].getFullYear() + "-07-19");
	holidays.delete(dates[0].getFullYear() + "-07-28");
	holidays.delete(dates[0].getFullYear() + "-07-03");
	holidays.delete(dates[0].getFullYear() + "-08-18");

	return holidays;
};

export const isHolidayOrSunday = (date, holidaysMap) =>
	holidaysMap.has(date.toISOString().split("T")[0]) || date.getDay() === 0;

export const isSaturday = date => date.getDay() === 6;
