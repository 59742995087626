import React from "react";
import PropTypes from "prop-types";
import { TimeLineGridContainerStyled, TimeLineGridItem } from "./TimelineGridContainerStyles";
import getWeek from "date-fns/getWeek";
import ClickableGridItemsContainer from "../ClickableGridItemsContainer/ClickableGridItemsContainer";
import { dateFnsOptions } from "constants";
import { getHolidays, isSaturday, isHolidayOrSunday } from "utils/holidayHelper";

/**
 * Represents a container for the timeline grid
 */
const TimelineGridContainer = ({ ticks, dates, dateType, useCompactView }) => {
	const holidaysMap = getHolidays(dates);

	const getDayType = date => {
		if (isHolidayOrSunday(date, holidaysMap)) {
			return "holiday";
		} else if (isSaturday(date)) {
			return "saturday";
		}
		return "weekday";
	};

	const startDates = new Map();
	const gridItemMap = new Map();

	for (let i = 0; i < ticks; i++) {
		const tickDate = dateType === "day" ? dates[0] : dates[i];
		const dayType = dateType === "year" ? "weekday" : getDayType(tickDate);

		// Store each week (shown in the month view) in the same array by using the same key
		let mapKey = undefined;
		if (dateType === "month") {
			mapKey = getWeek(dates[i], dateFnsOptions);
		} else {
			mapKey = i;
		}

		if (!gridItemMap.has(mapKey)) {
			gridItemMap.set(mapKey, []);
			startDates.set(mapKey, tickDate);
		}

		gridItemMap.get(mapKey).push(<TimeLineGridItem key={i} dayType={dayType} />);
	}

	const daysTotal = Array.from(gridItemMap.values()).flat().length;

	const gridItemLists = Array.from(gridItemMap, ([mapKey, gridItemList]) => (
		<ClickableGridItemsContainer
			key={mapKey}
			dateType={dateType}
			gridItems={gridItemList}
			startDateOfFirstGridItem={startDates.get(mapKey)}
			width={`${(gridItemList.length / daysTotal) * 100}%`}
		/>
	));

	return (
		<TimeLineGridContainerStyled id={"minid"} data-cy={"minid"} useCompactView={useCompactView}>
			{gridItemLists}
		</TimeLineGridContainerStyled>
	);
};

TimelineGridContainer.displayName = "TimelineGridContainer";

TimelineGridContainer.propTypes = {
	/** Represents the number of ticks that will be displayed */
	ticks: PropTypes.number,
	/** Represents an array of dates that will be used instead of ticks */
	dates: PropTypes.array,
	/** Represents the type of date that is being displayed */
	dateType: PropTypes.string,
	/** Indicates if a compact view should be shown */
	useCompactView: PropTypes.bool
};

export default React.memo(TimelineGridContainer);
