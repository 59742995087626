import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getListOfFeedbacks, deleteFeedback } from "../../services/apiFeedbackService";
import {
	ViewFeedbackWhiteText,
	ViewFeedbackGreyText,
	ViewFeedbackOrangeText
} from "./ViewFeedbackMenuStyles.js";
import FeedbackImage from "./FeedbackImage.js";
import AcceptDialog from "../AcceptDialog.js";
import { Box, Grid, Button } from "@mui/material";
import { LoaderSpinner } from "../Loading/LoaderSpinners.js";
import { colors } from "styles/theme";

/** Component used to view collected feedback from users */
function ViewFeedbackMenu() {
	const { t } = useTranslation();
	const [feedbacks, setFeedback] = useState([]);
	const [hasLoadedFeedbacks, setHasLoadedFeedbacks] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [feedbackId, setFeedbackId] = useState(undefined);

	useEffect(() => {
		getListOfFeedbacks()
			.then(response => {
				setFeedback(sortFeedbacks(response));

				setHasLoadedFeedbacks(true);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	const groupedFeedbacks = feedbacks.reduce((acc, feedback) => {
		if (!acc[feedback.category]) {
			acc[feedback.category] = [];
		}
		acc[feedback.category].push(feedback);

		return acc;
	}, {});

	const removeFeedback = feedbackId => {
		deleteFeedback(feedbackId)
			.then(response => {
				console.log(response);
			})
			.catch(error => {
				console.error(error);
			});
		const updatedFeedbacks = feedbacks.filter(feedback => feedback.feedbackId !== feedbackId);
		setFeedback(updatedFeedbacks);
	};

	// Function to sort the feedbacks by timestamp
	const sortFeedbacks = feedbacks => {
		return feedbacks.sort((a, b) => {
			return new Date(b.timestamp) - new Date(a.timestamp);
		});
	};

	const handleImageClick = feedbackId => {
		console.log(feedbackId);
	};

	return (
		<>
			<AcceptDialog
				showDialog={showDialog}
				dialogTitle={t("feedbackDeleteConfirm")}
				handleClickYes={() => {
					removeFeedback(feedbackId);
					setFeedbackId(undefined);
					setShowDialog(false);
				}}
				handleClickNo={() => {
					setFeedbackId(undefined);
					setShowDialog(false);
				}}
			/>
			<Box sx={{ flexGrow: 1, p: 2 }}>
				{hasLoadedFeedbacks ? (
					feedbacks.length !== 0 ? (
						<Grid container spacing={2}>
							{Object.keys(groupedFeedbacks).map(category => (
								<Grid item xs={"auto"} md={"auto"} key={category}>
									<Box
										sx={{
											bgcolor: colors.gridColor,
											p: 2,
											maxWidth: 500
										}}>
										<ViewFeedbackOrangeText
											variant="h3"
											fontWeight="bold"
											color
											marginTop="2%"
											sx={{ paddingLeft: 1 }}>
											{t(category)}
										</ViewFeedbackOrangeText>
										{groupedFeedbacks[category].map(feedback => (
											<Box
												key={feedback.feedbackId}
												sx={{
													border: "1px solid black",
													borderRadius: "5px",
													padding: "10px",
													margin: "10px"
												}}>
												<ViewFeedbackWhiteText
													variant="h3"
													marginTop="2%"
													sx={{
														lineHeight: "1",
														marginBottom: "10px"
													}}>
													{feedback.title}
												</ViewFeedbackWhiteText>
												<ViewFeedbackWhiteText
													sx={{
														whiteSpace: "pre-line",
														marginBottom: "10px"
													}}>
													{feedback.content}
												</ViewFeedbackWhiteText>
												<ViewFeedbackGreyText
													sx={{
														whiteSpace: "pre-line",
														marginBottom: "10px"
													}}>
													{t("feedbackSubmittedTime")}
													{new Date(feedback.timestamp).toLocaleString()}
												</ViewFeedbackGreyText>
												{feedback.hasImage && (
													<FeedbackImage
														feedbackId={feedback.feedbackId}
														onClick={() =>
															handleImageClick(feedback.feedbackId)
														}
														sx={{
															width: "100%",
															height: "auto",
															margin: "10px"
														}}
													/>
												)}
												<Button
													variant="contained"
													onClick={() => {
														setFeedbackId(feedback.feedbackId);
														setShowDialog(true);
													}}
													sx={{
														width: "100%",
														bgcolor: colors.orange,
														"&:hover": {
															bgcolor: colors.grey
														}
													}}>
													{t("feedbackDelete")}
												</Button>
											</Box>
										))}
									</Box>
								</Grid>
							))}
						</Grid>
					) : (
						<ViewFeedbackWhiteText>{t("feedbackNoFeedback")}</ViewFeedbackWhiteText>
					)
				) : (
					<LoaderSpinner />
				)}
			</Box>
		</>
	);
}

export default ViewFeedbackMenu;
