import styled from "@emotion/styled";
import { Box, Button, List, ListItem, TextField, Typography } from "@mui/material";
import theme, { colors } from "styles/theme";

export const GroupSearchContainer = styled(Box)(() => ({
	width: "400px",
	borderRadius: "5px",
	backgroundColor: colors.gridColor,
	padding: "20px",
	paddingRight: "10px",
	overflow: "hidden",
	position: "relative"
}));

export const GroupSearchTitle = styled(Typography)(() => ({
	color: colors.orange,
	marginBottom: "25px"
}));

export const GroupSearchFieldContainer = styled(Box)(() => ({
	display: "flex",
	gap: "15px"
}));

export const GroupSearchField = styled(TextField)(() => ({
	flexGrow: 1,
	"& label.Mui-focused": {
		color: colors.orange
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: colors.lightgrey
		},
		"&.Mui-focused fieldset": {
			borderColor: colors.orange
		}
	}
}));

export const GroupSearchButton = styled(Button)(() => ({
	...theme.button,
	maxHeight: "40px",
	width: "80px",
	marginRight: "10px"
}));

export const SearchResultsList = styled(List)(() => ({
	position: "absolute",
	top: "110px",
	left: "20px",
	right: "10px",
	bottom: "20px",
	overflowY: "auto",
	overflowX: "hidden",
	"::-webkit-scrollbar": {
		width: "8px"
	},
	"::-webkit-scrollbar-track": {
		backgroundColor: colors.darkgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb": {
		backgroundColor: colors.lightgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#a9a9a9"
	}
}));

export const NoResultContainer = styled(Box)(() => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	paddingRight: "10px",
	marginTop: "50%"
}));

export const StyledListItem = styled(ListItem)(() => ({
	padding: 0,
	marginBottom: "5px"
}));
