import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as NorwegianFlagIcon } from "../Icons/SpecialDays/norwegianFlagIcon.svg";
import { ReactComponent as SantaIcon } from "../Icons/SpecialDays/santaIcon.svg";
import { ReactComponent as FireworkIcon } from "../Icons/SpecialDays/fireworkIcon.svg";
import { ReactComponent as EasterChickenIcon } from "../Icons/SpecialDays/easterChickenIcon.svg";
import { ReactComponent as NorwegianLaborDayIcon } from "../Icons/SpecialDays/norwegianLaborDayIcon.svg";
import { ReactComponent as AscensionDayIcon } from "../Icons/SpecialDays/ascensionDayIcon.svg";
import { ReactComponent as PentecostIcon } from "../Icons/SpecialDays/pentecostIcon.svg";

// Special icons for special days
const NewYear = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<FireworkIcon />
		</div>
	);
};

const EasterChicken = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<EasterChickenIcon />
		</div>
	);
};

const AscensionDay = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<AscensionDayIcon />
		</div>
	);
};

const Pentecost = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<PentecostIcon />
		</div>
	);
};

const NorwegianLaborDay = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<NorwegianLaborDayIcon />
		</div>
	);
};

const NorwayFlag = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<NorwegianFlagIcon />
		</div>
	);
};

const Christmas = () => {
	return (
		<div className="navtexticon" style={{ paddingLeft: "10px" }}>
			<SantaIcon />
		</div>
	);
};

// Functions to check if date is a special day
const IsNewYear = date => {
	return date.getMonth() === 0 && date.getDate() === 1;
};

const CalculateEasterSunday = year => {
	const a = year % 19;
	const b = Math.floor(year / 100);
	const c = year % 100;
	const d = Math.floor(b / 4);
	const e = b % 4;
	const f = Math.floor((b + 8) / 25);
	const g = Math.floor((b - f + 1) / 3);
	const h = (19 * a + b - d - g + 15) % 30;
	const i = Math.floor(c / 4);
	const k = c % 4;
	const l = (32 + 2 * e + 2 * i - h - k) % 7;
	const m = Math.floor((a + 11 * h + 22 * l) / 451);
	const n = Math.floor((h + l - 7 * m + 114) / 31);
	const p = (h + l - 7 * m + 114) % 31;
	const month = n - 1;
	const day = p + 1;

	return new Date(year, month, day);
};

const IsEaster = date => {
	const EasterSunday = CalculateEasterSunday(date.getFullYear());

	// Maundy Thursday
	const maundyThursday = new Date(
		EasterSunday.getFullYear(),
		EasterSunday.getMonth(),
		EasterSunday.getDate() - 3
	);

	// Easter Monday
	const easterMonday = new Date(
		EasterSunday.getFullYear(),
		EasterSunday.getMonth(),
		EasterSunday.getDate() + 1
	);

	return date >= maundyThursday && date <= easterMonday;
};

const IsAscensionDay = date => {
	const year = date.getFullYear();
	const EasterSunday = CalculateEasterSunday(year);

	// Ascension Day
	const ascensionDay = new Date(
		EasterSunday.getFullYear(),
		EasterSunday.getMonth(),
		EasterSunday.getDate() + 39
	);

	return date.getMonth() === ascensionDay.getMonth() && date.getDate() === ascensionDay.getDate();
};

const IsPentecost = date => {
	const year = date.getFullYear();
	const EasterSunday = CalculateEasterSunday(year);

	// Pentecost
	const pentecost = new Date(
		EasterSunday.getFullYear(),
		EasterSunday.getMonth(),
		EasterSunday.getDate() + 49
	);

	const pentecostMonday = new Date(
		EasterSunday.getFullYear(),
		EasterSunday.getMonth(),
		EasterSunday.getDate() + 50
	);

	// Returning true if date is either Pentecost or Pentecost Monday
	return (
		(date.getMonth() === pentecost.getMonth() && date.getDate() === pentecost.getDate()) ||
		(date.getMonth() === pentecostMonday.getMonth() &&
			date.getDate() === pentecostMonday.getDate())
	);
};

const IsNorwegianLaborDay = date => {
	return date.getMonth() === 4 && date.getDate() === 1;
};

const IsMay17th = date => {
	return date.getMonth() === 4 && date.getDate() === 17;
};

const IsChristmas = date => {
	return date.getMonth() === 11 && date.getDate() >= 24 && date.getDate() <= 26;
};

const GetHoliday = date => {
	if (IsNewYear(date)) {
		return <NewYear />;
	}
	if (IsEaster(date)) {
		return <EasterChicken />;
	}
	if (IsAscensionDay(date)) {
		return <AscensionDay />;
	}
	if (IsPentecost(date)) {
		return <Pentecost />;
	}
	if (IsNorwegianLaborDay(date)) {
		return <NorwegianLaborDay />;
	}
	if (IsMay17th(date)) {
		return <NorwayFlag />;
	}
	if (IsChristmas(date)) {
		return <Christmas />;
	}
	return "";
};

const HolidayIcons = ({ date }) => {
	if (date === undefined) {
		return "";
	}
	return GetHoliday(date);
};

HolidayIcons.propTypes = {
	/** The date of the view */
	date: PropTypes.instanceOf(Date)
};

export default HolidayIcons;
