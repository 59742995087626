import {
	PARENTAL_LEAVE_KEYWORDS,
	TRAINING_KEYWORDS,
	VACATION_KEYWORDS,
	UNDISCLOSED_KEYWORDS,
	HOME_OFFICE_KEYWORDS,
	MOVING_KEYWORDS,
	WEDDING_KEYWORDS
} from "../../../constants";
import { colors } from "styles/theme";
import styled from "@emotion/styled";
import { checkWords, deepCheckWordsVacation } from "../../../utils/checkWordsHelper";

const getAbsenceTypeColor = (title, isWinter, absenceTypeColor) => {
	if (
		!title ||
		title === undefined ||
		title.length === 0 ||
		checkWords(UNDISCLOSED_KEYWORDS, title)
	)
		return absenceTypeColor.private;

	if (checkWords(PARENTAL_LEAVE_KEYWORDS, title)) return absenceTypeColor.parentalLeave;
	if (checkWords(TRAINING_KEYWORDS, title)) return absenceTypeColor.training;
	if (checkWords(HOME_OFFICE_KEYWORDS, title)) return absenceTypeColor.homeOffice;
	if (checkWords(MOVING_KEYWORDS, title)) return absenceTypeColor.moving;
	if (checkWords(WEDDING_KEYWORDS, title)) return absenceTypeColor.wedding;

	if (checkWords(VACATION_KEYWORDS, title || deepCheckWordsVacation(VACATION_KEYWORDS, title)))
		return isWinter ? absenceTypeColor.winterVacation : absenceTypeColor.vacation;

	return absenceTypeColor.uncategorized;
};

export const CreateTimeLineDataItem = ({
	start,
	end,
	isWinter,
	text,
	dateType,
	useCompactView
}) => {
	// Ratio is 38.4 divided by the width of the window times 100
	// This ratio is 2 at 1920 window width
	let ratio = (38.4 / window.innerWidth) * 100;
	let isTooSmall = end - start < ratio;
	// newStart counts 0 to 100 from left to right
	let newStart = isTooSmall ? 0 : start;
	// newEnd counts 0 to 100 from right to left
	let newEnd = isTooSmall ? 100 : 100 - end;
	let borderRadius = {};
	let notDisplaying = {};
	if (isTooSmall && start !== end && dateType !== "day") {
		if (100 - start < ratio) {
			newStart = 100 - ratio * 1.5;
			newEnd = ratio * 0.5;
		} else if (end < 2) {
			newEnd = 100 - ratio * 1.5;
			newStart = ratio * 0.5;
		} else {
			newEnd = 100 - ratio / 4 - start;
			newStart = start - ratio / 4;
		}
		borderRadius = {
			borderRadius: "50%",
			border: "1px solid black",
			width: "15px",
			height: "15px",
			marginTop: "0%",
			marginBottom: "0%",
			top: "50%",
			transform: "translateY(-50%)",
			"& div": {
				visibility: "hidden !important",
				"& svg": {
					width: "200px"
				}
			}
		};
	}
	if (newStart === 0 && newEnd === 100) {
		notDisplaying = {
			display: "none"
		};
	}

	const TimeLineDataItem = styled("div")(({ theme }) => ({
		...theme.timeline.timelineDataItem,
		marginTop: useCompactView ? "4px" : theme.timeline.timelineDataItem.marginTop,
		marginBottom: useCompactView ? "4px" : theme.timeline.timelineDataItem.marginBottom,
		backgroundColor: getAbsenceTypeColor(text, isWinter, theme.timeline.absenceTypeColor),
		pointerEvents: "all",
		left: `${newStart}%`,
		right: `${newEnd}%`,
		top: "0%",
		bottom: "0%",
		display: "flex",
		justifyContent: "center",
		border: `1px solid ${colors.gridColor}`,
		...borderRadius,
		...notDisplaying
	}));

	return TimeLineDataItem;
};

export const DivTimelineUserText = styled("div")(({ theme, useCompactView }) => ({
	...(useCompactView ? theme.timeline.compactTimelineUserText : theme.timeline.timelineUserText),
	display: "flex",
	flexWrap: "nowrap",
	alignItems: "center",
	color: "#000"
}));

export const DivTimelineEventText = styled("div")(({ useCompactView }) => ({
	marginRight: useCompactView ? "0px" : "15px"
}));
