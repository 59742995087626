import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactComponent as DizzyIcon } from "../../Icons/dizzyIcon.svg";

export const TypographyDefaultText = styled(Typography)(() => ({
	marginTop: "15px",
	marginLeft: "270px"
}));

export const StyledDizzyIcon = styled(DizzyIcon)(() => ({
	marginLeft: "5px"
}));

export const StyledTimelineContainer = styled("div")(() => ({
	margin: "10px",
	minWidth: "800px"
}));
