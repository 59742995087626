import { postApiData, getApiData, deleteApiData } from "./apiService";
import { ENDPOINT } from "constants";

export const submitFeedback = async feedbackForm => {
	let loc = ENDPOINT + "userFeedback";

	return await postApiData(loc, feedbackForm, true);
};

export const getListOfFeedbacks = async () => {
	let loc = ENDPOINT + "userFeedback";

	return await getApiData(loc);
};

export const getFeedbackImage = async feedbackId => {
	let loc = ENDPOINT + "userFeedbackImage/" + feedbackId;

	return await getApiData(loc);
};

export const deleteFeedback = async feedbackId => {
	let loc = ENDPOINT + "deleteFeedback";
	let data = feedbackId;

	return await deleteApiData(loc, data);
};
