import {
	USER_SET_USER_INFO,
	USER_SET_FILTER_TIMELINE,
	USER_SET_FILTER_NO_WORKING_HOURS,
	USER_SET_SELECTED_GROUP,
	USER_SET_FILTER_RECURRING_EVENTS
} from "../constants";
import initialState from "./initialState";

/** Reducer for the user object in state */
export default function (state = initialState.user, action) {
	switch (action.type) {
		case USER_SET_FILTER_TIMELINE:
			return { ...state, filtered: action.payload };
		case USER_SET_FILTER_NO_WORKING_HOURS:
			return { ...state, filteredOutsideWorkingHours: action.payload };
		case USER_SET_FILTER_RECURRING_EVENTS:
			return { ...state, filteredRecurringEvents: action.payload };
		case USER_SET_USER_INFO:
			return {
				...state,
				name: action.payload.name,
				id: action.payload.id,
				filtered: action.payload.filtered,
				filteredOutsideWorkingHours: action.payload.filteredOutsideWorkingHours,
				groups: action.payload.groups,
				filteredRecurringEvents: action.payload.filteredRecurringEvents,
				isAdmin: action.payload.isAdmin
			};
		case USER_SET_SELECTED_GROUP: {
			return { ...state, selectedGroupId: action.payload };
		}
		default:
			return state;
	}
}
