import React from "react";
import PropTypes from "prop-types";
import { ListItemText, ListItem, Tooltip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getUsersInCustomGroup } from "services/apiCustomGroupService";
import { connect } from "react-redux";
import { SHOW_EDIT_CUSTOM_GROUP_MODAL, GROUP_TYPE_CUSTOM_GROUP } from "../../../../constants";
import { getGroupOwnerName } from "utils/groupHelper";
import {
	StyledDeleteIcon,
	StyledDeleteIconButton,
	StyledEditIcon,
	StyledEditIconButton
} from "./GroupSelectOptionStyles";
import { userSetSelectedGroupId } from "actions/userActions";
import {
	modalsSetCustomGroupModalGroup,
	modalsSetCustomGroupSelectedUsers,
	modalsSetModalType
} from "actions/modalActions";

/**
 * Represents a group listed in the GroupSelect component
 */
const GroupSelectOption = ({
	props,
	setDeleteGroup,
	group,
	user,
	groupEntries,
	modalsSetCustomGroupModalGroup,
	modalsSetCustomGroupSelectedUsers,
	modalsSetModalType
}) => {
	const { t } = useTranslation();
	const userOwnsGroup = group.groupOwnerId === user.id;

	const handleClickEdit = async event => {
		event.stopPropagation();
		modalsSetCustomGroupModalGroup(group); // Sets title and selected users in the modal

		// Gets the users in the group and sets them as selected users in the modal
		let listOfUsers =
			groupEntries && groupEntries.has(group.id)
				? groupEntries.get(group.id).map(entry => ({
						id: entry.user.id,
						name: entry.user.name,
						email: entry.user.emailAddress
					}))
				: await getUsersInCustomGroup(group.id); // If the groupEntries object is not yet populated, get the users from the backend
		listOfUsers = listOfUsers.filter(u => u.id !== user.id);

		modalsSetCustomGroupSelectedUsers(listOfUsers);
		modalsSetModalType(SHOW_EDIT_CUSTOM_GROUP_MODAL);
	};

	// Gets the text to be displayed below the group name
	const getSecondaryText = () => {
		const userOwnsGroup = group.groupOwnerId === user.id;
		const isCustomGroup = group.groupType === GROUP_TYPE_CUSTOM_GROUP;
		return isCustomGroup && group.isSharedWithMembers
			? userOwnsGroup
				? t("youHaveShared")
				: t("sharedBy") + " " + getGroupOwnerName(group, groupEntries)
			: null;
	};

	return (
		<Box {...props}>
			<ListItem sx={{ padding: 0 }}>
				<ListItemText
					data-testid="group-select-option"
					primary={t(group.name)}
					secondary={getSecondaryText()}
				/>
			</ListItem>
			{userOwnsGroup && (
				<>
					<Tooltip title={t("edit")}>
						<StyledEditIconButton aria-label={t("edit")} onClick={handleClickEdit}>
							<StyledEditIcon data-testid="group-select-option-edit" />
						</StyledEditIconButton>
					</Tooltip>

					<Tooltip title={t("remove")}>
						<StyledDeleteIconButton
							onClick={() => {
								setDeleteGroup(group);
							}}>
							<StyledDeleteIcon data-testid="group-select-option-delete" />
						</StyledDeleteIconButton>
					</Tooltip>
				</>
			)}
		</Box>
	);
};

GroupSelectOption.propTypes = {
	/** Props object returned from the MUI Autocomplete component */
	props: PropTypes.object,
	setDeleteGroup: PropTypes.func,
	/** The group representing the option passed from the Autocomplete component */
	group: PropTypes.object,
	/** The user object from state */
	user: PropTypes.object,
	/** The groupEntries object from state */
	groupEntries: PropTypes.object,
	/** Action for setting the selected group in state */
	userSetSelectedGroupId: PropTypes.func,
	/** Action for setting the group shown in the modal */
	modalsSetCustomGroupModalGroup: PropTypes.func,
	/** Action for setting the selected users in the modal */
	modalsSetCustomGroupSelectedUsers: PropTypes.func,
	/** Action for setting the modal type */
	modalsSetModalType: PropTypes.func
};

const mapStateToProps = state => {
	return {
		user: state.user,
		groupEntries: state.event.groupEntries
	};
};

export default connect(mapStateToProps, {
	userSetSelectedGroupId,
	modalsSetCustomGroupModalGroup,
	modalsSetCustomGroupSelectedUsers,
	modalsSetModalType
})(GroupSelectOption);
