// Checking if a keyword is a word in a string. True if keyword is found
export function checkWords(keywords, text) {
	for (const keyword of keywords) {
		if (regexCheck(keyword, text)) return true;
	}
	return false;
}

// Matching with regular expression
function regexCheck(keyword, text) {
	return new RegExp(`\\b${keyword}\\b`, "i").test(text);
}

// The vacation keyword should be more "sensitive" than the other keywords
// This function checks if the keyword is a substring of the text
export function deepCheckWordsVacation(keyword, text) {
	return text.toUpperCase().includes(keyword);
}
