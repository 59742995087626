import React from "react";
import { styled } from "@mui/material/styles";
import { Oval } from "react-loader-spinner";

const Root = styled("div")(() => ({}));

export const LoaderSpinner = () => {
	return (
		<Root
			sx={{
				display: "flex",
				justifyContent: "Center",
				alignItems: "Center",
				height: "50vh"
			}}>
			<Oval color="#E05902" secondaryColor="#E05902" height={100} width={100} />
		</Root>
	);
};

export const LoaderSpinnerSmall = () => {
	return (
		<Root
			sx={{
				display: "flex",
				justifyContent: "Center",
				alignItems: "Center",
				paddingLeft: "10px",
				paddingRight: "5px"
			}}>
			<Oval
				color="#E05902"
				secondaryColor="#E05902"
				height="30px"
				width="30px"
				strokeWidth={4}
				strokeWidthSecondary={4}
			/>
		</Root>
	);
};
