import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { colors } from "styles/theme";

export const FeedbackOrangeText = styled(Typography)(() => ({
	color: colors.orange,
	width: "80%",
	fontSize: "1.2rem"
}));

export const FeedbackWhiteText = styled(Typography)(() => ({
	color: colors.white,
	width: "80%"
}));

export const FeedbackGreyText = styled(Typography)(() => ({
	color: colors.lightergrey,
	width: "80%"
}));

export const FeedbackRedText = styled(Typography)(() => ({
	color: colors.errorRed,
	width: "80%"
}));

export const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
	accept: "image/*"
});
