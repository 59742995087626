import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import TimelineDateRow from "../TimelineDateRow/TimelineDateRow";
import TimelineUserRow from "../TimelineUserRow/TimelineUserRow";
import {
	TypographyDefaultText,
	StyledDizzyIcon,
	StyledTimelineContainer
} from "./TimelineContainerStyles";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";

/**
 * Container component for timeline elements user row and date row
 */
const TimelineContainer = ({
	user,
	data,
	dateType,
	startDate,
	endDate,
	resize,
	// showFromToday,
	useCompactView
}) => {
	const { t } = useTranslation();
	let userRows = [];

	let userCount = Object.keys(data).length;

	// Sort list of people
	let dataSorted = useMemo(() => {
		return data.slice().sort((a, b) => a.user.name - b.user.name);
	}, [data]);

	// Creating array of the dates in the current view
	let dates = [];
	// let currentDate = showFromToday ? new Date() : new Date(startDate); // If the showFromToday option is enabled, set the currentDate to today

	let currentDate = new Date(startDate); // If the showFromToday option is enabled, set the currentDate to today
	while (currentDate <= endDate) {
		dates.push(new Date(currentDate));

		if (dateType === "year") {
			currentDate.setMonth(currentDate.getMonth() + 1);
		} else {
			currentDate.setDate(currentDate.getDate() + 1);
		}
	}

	let ticks;
	switch (dateType) {
		case "day":
			ticks = 24;
			break;
		default:
			ticks = dates.length;
	}

	// Filter out users with no events in the view period
	if (user) {
		dataSorted = dataSorted.map(userWithEvents => {
			const filteredEvents = userWithEvents.events.filter(e => {
				const startInView = e.start >= startDate && e.start <= endDate;
				const endInView = e.end >= startDate && e.end <= endDate;
				const startBeforeEndAfter = e.start < startDate && e.end > endDate;
				return startInView || endInView || startBeforeEndAfter;
			});
			return { user: userWithEvents.user, events: filteredEvents };
		});
	}

	// If there are no users with events in the view period, display a message
	const userWithEventsExists = dataSorted.some(
		userWithEvents => userWithEvents.events.length > 0
	);
	if (userWithEventsExists === false && user.filtered === true) {
		userRows.push(
			<TypographyDefaultText variant="h5" align="center" key={uuidv4()}>
				{t("noRelevantData")}
				<StyledDizzyIcon />
			</TypographyDefaultText>
		);
	}

	// If there are users with events in the view period, display them
	else {
		for (let item in dataSorted) {
			userRows.push(
				<TimelineUserRow
					key={item}
					name={dataSorted[item].user.name}
					data={dataSorted[item]}
					startDate={startDate}
					endDate={endDate}
					ticks={ticks}
					dates={dates}
					dateType={dateType}
					resize={resize}
					outsideWorkingHoursFiltered={user.filteredOutsideWorkingHours}
					filteredRecurringEvents={user.filteredRecurringEvents}
					filteredUsersWithNoEvents={user.filtered}
					useCompactView={useCompactView}
				/>
			);
		}
	}

	userCount = Object.keys(userRows).length;

	return (
		<StyledTimelineContainer>
			<TimelineDateRow dateType={dateType} ticks={ticks} startTime={startDate} />
			{userCount > 0 ? (
				userRows
			) : (
				<TypographyDefaultText variant="h5" align="center">
					{t("noRelevantData")}
					<StyledDizzyIcon />
				</TypographyDefaultText>
			)}
		</StyledTimelineContainer>
	);
};

TimelineContainer.propTypes = {
	/** The user object from state. Is required for the filter function to work */
	user: PropTypes.object.isRequired,
	/** The event data */
	data: PropTypes.array,
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Start date from current view */
	startDate: PropTypes.object,
	/** End date from current view */
	endDate: PropTypes.object,
	/** Indicates if the windowsize has changed */
	resize: PropTypes.bool,
	/** Indicates if the timeline should show from today */
	showFromToday: PropTypes.bool,
	/** Indicates if a compact view should be shown */
	useCompactView: PropTypes.bool
};

export const mapStateToProps = state => {
	return {
		showFromToday: state.view.showFromToday
	};
};

export default connect(mapStateToProps)(TimelineContainer);
