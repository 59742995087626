import {
	VIEW_SET_TODAY_CURRENT,
	VIEW_SET_SCOPE,
	VIEW_MOVE_CURRENT,
	VIEW_SET_CURRENT,
	VIEW_SET_COMPACT_VIEW,
	VIEW_SET_SHOW_FROM_TODAY
} from "../constants";

export const viewMoveCurrent = oper => {
	return {
		type: VIEW_MOVE_CURRENT,
		oper: oper
	};
};

export const viewSetTodayCurrent = () => {
	return {
		type: VIEW_SET_TODAY_CURRENT
	};
};

export const viewSetScope = scope => {
	return {
		type: VIEW_SET_SCOPE,
		scope: scope
	};
};

export const viewSetCurrent = date => {
	return {
		type: VIEW_SET_CURRENT,
		date: date
	};
};

export const viewSetCompactView = compactView => {
	return {
		type: VIEW_SET_COMPACT_VIEW,
		compactView: compactView
	};
};

export const viewSetShowFromToday = showFromToday => {
	return {
		type: VIEW_SET_SHOW_FROM_TODAY,
		showFromToday: showFromToday
	};
};
