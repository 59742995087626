import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { ReactComponent as PersonIcon } from "../Icons/personIcon.svg";

/**
 * Represents the user profile photo icon
 * Displays a `PersonIcon` when photo is not available
 */
const UserPhoto = ({ usersPhotos, userId, size }) => {
	if (
		usersPhotos === null ||
		usersPhotos === undefined ||
		usersPhotos.photos === undefined ||
		usersPhotos.photos.has(userId) === false
	) {
		return <PersonIcon width={size} height={size} />;
	}

	const photo = usersPhotos.photos.get(userId);
	return (
		<Avatar
			style={{ width: size, height: size }}
			src={`data:${photo.photoContentType};base64,${photo.encodedRawContent}`}
			sx={{ rounded: "true" }}
		/>
	);
};

UserPhoto.propTypes = {
	/** The usersPhoto object from state */
	usersPhotos: PropTypes.object,
	/** The id of the logged in user */
	userId: PropTypes.string,
	/** Property scaling the size of the icon */
	size: PropTypes.string
};

const mapStateToProps = state => {
	return {
		usersPhotos: state.usersPhotos
	};
};

export default connect(mapStateToProps)(UserPhoto);
