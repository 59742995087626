import React from "react";
import PropTypes from "prop-types";
import UserPhoto from "../../UserPhoto/UserPhoto";
import { TimeLineUserItemContainerMobile } from "./TimelineUserItemStyles";

/**
 * Represent an user item in the timeline for mobile view, i.e. a user photo
 */
const TimelineUserItemMobile = ({ userWithEvents }) => {
	return (
		<TimeLineUserItemContainerMobile>
			<div style={{ flexBasis: "10%" }} />
			<UserPhoto userId={userWithEvents.user.id} size="40px" />
			<div style={{ flexBasis: "7%" }} />
		</TimeLineUserItemContainerMobile>
	);
};

TimelineUserItemMobile.propTypes = {
	/** User name */
	name: PropTypes.string,
	/** Start date from current view */
	start: PropTypes.object,
	/** End date from current view */
	end: PropTypes.object,
	/** User with attached events */
	userWithEvents: PropTypes.object
};

export default TimelineUserItemMobile;
