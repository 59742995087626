import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import { SNACKBAR_TIMEOUT } from "constants";
import { connect } from "react-redux";

/**
 * Snackbar for showing errors
 */
const ErrorSnackbar = ({ snackbar }) => {
	const [open, setOpen] = useState(false);
	const handleClose = reason => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// Show error snackbar if error message or severity is set
	useEffect(() => {
		if (!snackbar.message || !snackbar.severity) return;
		setOpen(true);

		setTimeout(() => {
			setOpen(false);
		}, SNACKBAR_TIMEOUT);
	}, [snackbar]);

	return (
		<Snackbar
			open={open}
			autoHideDuration={SNACKBAR_TIMEOUT}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left"
			}}>
			<MuiAlert elevation={6} variant="filled" severity={snackbar.severity}>
				{snackbar.message}
			</MuiAlert>
		</Snackbar>
	);
};

ErrorSnackbar.propTypes = {
	/** Snackbar object from state */
	snackbar: PropTypes.object
};

const mapStateToProps = state => {
	return {
		snackbar: state.snackbar
	};
};

export default connect(mapStateToProps)(ErrorSnackbar);
