import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const DivRoot = styled("div")(() => ({
	display: "flex",
	justifyContent: "space-evenly",
	marginLeft: "25px",
	alignItems: "center"
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontWeight: "100",
	whiteSpace: "nowrap"
}));
