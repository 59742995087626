import React from "react";
import "./i18n";
import AuthApp from "components/AuthApp";
import { store } from "reducers/store";
import authServiceInstance from "services/authService";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<MsalProvider instance={authServiceInstance.msalClient} reduxStore={store}>
		<AuthApp />
	</MsalProvider>
);
