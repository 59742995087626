import ForwardIcon from "@mui/icons-material/ChevronRight";
import BackIcon from "@mui/icons-material/ChevronLeft";
import { DialogContentText } from "@mui/material";
import styled from "@emotion/styled";

export const DivSpacer = styled("div")(() => ({
	flex: "1 0 0"
}));

export const BackIconBack = styled(BackIcon)(() => ({
	marginLeft: "-6px"
}));

export const ForwardIconNext = styled(ForwardIcon)(() => ({
	marginRight: "-6px"
}));

export const DialogContentTextText = styled(DialogContentText)(() => ({
	whiteSpace: "pre-line"
}));
