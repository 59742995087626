import { getApiData, postApiData, deleteApiData, putApiData } from "./apiService";
import { ENDPOINT } from "constants";
import { format } from "date-fns";

/** API call used to get all users part of a custom group */
export const getUsersInCustomGroup = async customGroupId => {
	let loc = ENDPOINT + "customGroup/" + customGroupId;

	return await getApiData(loc);
};

/** API call used to create a new customGroup */
export const postCustomGroup = async newCustomGroup => {
	let loc = ENDPOINT + "customGroup";

	return await postApiData(loc, newCustomGroup);
};

/** API call used to delete a customGroup */
export const deleteCustomGroup = async customGroupId => {
	let loc = ENDPOINT + "customGroup";

	return await deleteApiData(loc, customGroupId);
};

/** API call used to update a customGroup */
export const putCustomGroup = async updatedCustomGroup => {
	let loc = ENDPOINT + "customGroup";

	return await putApiData(loc, updatedCustomGroup);
};

/** API call for getting all calendar entries for a custom group */
export const getCustomGroupSchedulesByStartTimeAndEndTime = async (
	customGroupId,
	startDate,
	endDate
) => {
	const loc =
		ENDPOINT +
		"getCustomGroupSchedules/" +
		customGroupId +
		"?startDate=" +
		format(startDate, "dd.MM.yyyy") +
		"&endDate=" +
		format(endDate, "dd.MM.yyyy");
	return await getApiData(loc);
};

/** API call for getting all calendar entries for a shared custom group */
export const getSharedCustomGroupSchedulesByStartTimeAndEndTime = async (
	customGroupId,
	groupOwnerId,
	startDate,
	endDate
) => {
	const loc =
		ENDPOINT +
		"GetSharedCustomGroupSchedules/" +
		customGroupId +
		"/" +
		groupOwnerId +
		"?startDate=" +
		format(startDate, "dd.MM.yyyy") +
		"&endDate=" +
		format(endDate, "dd.MM.yyyy");
	return await getApiData(loc);
};
