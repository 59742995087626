import React from "react";
import PropTypes from "prop-types";
import {
	StyledChip,
	StyledChipContainer,
	StyledSelectedUsersContainer,
	StyledContainerTitle,
	StyledContainerText
} from "./SelectedUsersContainerStyles";
import { connect } from "react-redux";
import UserPhoto from "components/UserPhoto/UserPhoto";
import { useTranslation } from "react-i18next";
import { colors } from "styles/theme";
import { modalsSetCustomGroupSelectedUsers } from "actions/modalActions";

/**
 * A container for displaying selected user Chip components
 */
function SelectedUsersContainer({
	selectedUsers,
	errorMessage,
	modalsSetCustomGroupSelectedUsers
}) {
	const { t } = useTranslation();
	const maxSelectedUsers = process.env.REACT_APP_MAXIMUM_GROUP_MEMBERS ?? 25;
	const numberOfSelectedUsers = selectedUsers ? selectedUsers.length : 0;

	// Creates a Chip component for each selected user
	const getSelectedUserChips = () =>
		selectedUsers.map(userObject => (
			<StyledChip
				data-testid={"selected-user-chip-" + userObject.id}
				avatar={<UserPhoto userId={userObject.id} size="23px" />}
				key={userObject.id}
				size="small"
				label={userObject.name}
				onDelete={() =>
					modalsSetCustomGroupSelectedUsers(
						selectedUsers.filter(user => userObject.id !== user.id)
					)
				}
			/>
		));

	// Sets the border color of the container to red if the user has selected more than maximum allowed users
	const borderColor = numberOfSelectedUsers > maxSelectedUsers ? "red" : colors.lightgrey;

	return (
		<StyledSelectedUsersContainer border={1} borderColor={borderColor}>
			<StyledContainerTitle gutterBottom variant="body2">
				{selectedUsers && selectedUsers.length <= maxSelectedUsers
					? t("groupMembers")
					: t("maxGroupMembers")}
			</StyledContainerTitle>
			<StyledChipContainer>
				{numberOfSelectedUsers === 0 ? (
					<StyledContainerText
						variant="body2"
						color={errorMessage ? "error" : colors.white}>
						{errorMessage ? errorMessage : t("noSelectedUsers")}
					</StyledContainerText>
				) : (
					getSelectedUserChips()
				)}
			</StyledChipContainer>
		</StyledSelectedUsersContainer>
	);
}

SelectedUsersContainer.propTypes = {
	/** An array of userIds representing users selected for a new custom group */
	selectedUsers: PropTypes.array,
	/** Represents an error message shown when the user provides invalid input */
	errorMessage: PropTypes.string,
	/** Action for setting selected users in the modal */
	modalsSetCustomGroupSelectedUsers: PropTypes.func
};

export default connect(null, { modalsSetCustomGroupSelectedUsers })(SelectedUsersContainer);
