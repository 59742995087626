import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TimelineDataItemContainer from "../TimelineDataItemContainer/TimelineDataItemContainer";
import { StyledTimelineContainer } from "./TimelineDataContainerStyles";
import { addSeconds } from "date-fns";

/**
 * Normalizes dates - making it possible to find how close
 * a given date is to the start or end of a given timeframe.
 * 50 and above === closer to the end date
 * Below 50 == closer to the start date
 * */
const normalize = (start, end, selected) => {
	let counter = +selected - +start;
	let denominator = +end - +start;
	let normal = counter / denominator;

	normal = normal * 100;
	normal = normal < 0 ? 0 : normal > 100 ? 100 : normal;

	return normal;
};

const TimelineDataContainer = ({
	data,
	dateType,
	startDate,
	endDate,
	resize,
	outsideWorkingHoursFiltered,
	filteredRecurringEvents,
	useCompactView
}) => {
	useEffect(() => {
		// Rerender component
	}, [startDate, endDate]);
	let dataItemList = [];

	for (let item in data) {
		// Filter events that are not in working hours
		if (outsideWorkingHoursFiltered && eventOverlapsWithWorkingHours(data[item]) === false)
			continue;

		// Filter recurring events
		if (filteredRecurringEvents && data[item].isRecurring) continue;

		// Checking if the event is in winter by checking if it starts before the winter period
		let isWinter = false;
		const eventMonth = data[item].start.getMonth();
		const winterStart = parseInt(process.env.REACT_APP_WINTER_TIME_START);
		const winterEnd = parseInt(process.env.REACT_APP_WINTER_TIME_END);

		if (eventMonth >= winterStart || eventMonth <= winterEnd) {
			isWinter = true;
		}

		dataItemList.push(
			<TimelineDataItemContainer
				key={`${data[item].subject}-${data[item].start}-${data[item].end}`}
				start={normalize(startDate, endDate, data[item].start)}
				end={normalize(startDate, endDate, data[item].end)}
				text={data[item].subject}
				startDate={data[item].start}
				endDate={data[item].end}
				dateType={dateType}
				reRender={+startDate + "" + +endDate}
				resize={resize}
				isWinter={isWinter}
				useCompactView={useCompactView}
			/>
		);
	}

	if (dataItemList.length === 0) {
		return null;
	}

	return <StyledTimelineContainer>{dataItemList}</StyledTimelineContainer>;
};

const eventOverlapsWithWorkingHours = event => {
	const startWorkHours = process.env.REACT_APP_WORK_HOURS_START;
	const endWorkHours = process.env.REACT_APP_WORK_HOURS_END;
	const startTime = event.start;
	const endTime = event.end;

	const startInWorkHours =
		startTime.getHours() >= startWorkHours && startTime.getHours() < endWorkHours;
	const endInWorkHours =
		endTime.getHours() > startWorkHours && endTime.getHours() <= endWorkHours;
	const startBeforeEndAfter =
		startTime.getHours() < startWorkHours && addSeconds(endTime, -1).getHours() > endWorkHours;

	if (startInWorkHours || endInWorkHours || startBeforeEndAfter) {
		return true;
	}
	return false;
};

TimelineDataContainer.propTypes = {
	/** The event data */
	data: PropTypes.array,
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Start date from current view */
	startDate: PropTypes.object,
	/** End date from current view */
	endDate: PropTypes.object,
	/** Indicates if the windowsize has changed */
	resize: PropTypes.bool,
	/** Indicates if the events should be filtered on working hours */
	outsideWorkingHoursFiltered: PropTypes.bool,
	/** Indicates if recurring events should be filtered */
	filteredRecurringEvents: PropTypes.bool,
	/** Indicates if a compact view should be shown */
	useCompactView: PropTypes.bool
};

export default TimelineDataContainer;
