import {
	USER_SET_USER_INFO,
	USER_SET_FILTER_TIMELINE,
	USER_SET_FILTER_NO_WORKING_HOURS,
	USER_SET_SELECTED_GROUP,
	USER_SET_FILTER_RECURRING_EVENTS
} from "../constants";

export const userSetFilterTimeline = emptyFiltered => {
	return {
		type: USER_SET_FILTER_TIMELINE,
		payload: emptyFiltered
	};
};

export const userSetFilterTimelineNoWorkingHours = filteredOutsideWorkingHours => {
	return {
		type: USER_SET_FILTER_NO_WORKING_HOURS,
		payload: filteredOutsideWorkingHours
	};
};

export const userSetFilterRecurringEvents = filteredRecurringEvents => {
	return {
		type: USER_SET_FILTER_RECURRING_EVENTS,
		payload: filteredRecurringEvents
	};
};

export const userSetUserInfo = user => {
	return {
		type: USER_SET_USER_INFO,
		payload: user
	};
};

export const userSetSelectedGroupId = selectedGroupId => {
	return {
		type: USER_SET_SELECTED_GROUP,
		payload: selectedGroupId
	};
};
