import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "styles/theme";

export const ClickableBox = styled(Box)(({ width }) => ({
	width: width,
	display: "flex",
	"&:hover > *": {
		backgroundColor: colors.grey
	},
	"&:active > *": {
		backgroundColor: colors.orange,
		opacity: 0.5
	},
	cursor: "pointer"
}));
