import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
	StyledCloseModalButton,
	StyledFormControl,
	StyledTextField,
	CreateGroupButtonContainer,
	StyledButton,
	ModalTitle,
	ModalSecondaryText,
	StyledFormControlLabel
} from "./CustomGroupModalStyles";
import {
	modalsSetCustomGroupModalGroup,
	modalsSetModalType,
	modalsResetCustomGroupModal
} from "actions/modalActions";
import SelectedUsersContainer from "./ModalComponents/SelectedUsersContainer/SelectedUsersContainer";
import UserSearch from "./ModalComponents/UserSearch/UserSearch";
import { HIDE_MODALS, SHOW_EDIT_CUSTOM_GROUP_MODAL } from "constants";

/**
 * Represents the modal used in the editCustomGroupModal and newCustomGroupModal components
 */
function CustomGroupModal({
	displayModal,
	modalTitle,
	modalButtonText,
	modalButtonClickHandler,
	secondaryText,
	modals,
	groups,
	modalsSetCustomGroupModalGroup,
	modalsSetModalType,
	modalsResetCustomGroupModal
}) {
	const { t } = useTranslation();
	const [groupNameInputError, setGroupNameInputError] = useState("");
	const [groupMemberInputError, setGroupMemberInputError] = useState("");
	const modalGroup = modals.customGroupModal.modalGroup;
	const selectedUsers = modals.customGroupModal.selectedUsers;

	const closeAndResetModal = () => {
		modalsSetModalType(HIDE_MODALS);
		modalsResetCustomGroupModal();
		setGroupNameInputError("");
		setGroupMemberInputError("");
	};

	// Checks whether the group name is duplicate
	const isDuplicateGroupName = groupName => {
		const isEditing = modals.modalType === SHOW_EDIT_CUSTOM_GROUP_MODAL;
		const groupsArray = isEditing
			? Object.values(groups).filter(group => group.id !== modalGroup.id) // If editing, exclude the group being edited
			: Object.values(groups);
		return groupsArray.some(groups => groups.name === groupName);
	};

	// Checks the user input and sets error messages if the user has not provided valid input
	const isNewGroupValid = () => {
		const maxGroupNameLength = process.env.REACT_APP_MAX_GROUP_NAME_LENGTH;
		const { name } = modalGroup;
		let groupNameInputError = "";
		let groupMemberInputError = "";

		if (isDuplicateGroupName(name)) {
			groupNameInputError = t("groupNameAlreadyUsed");
		} else if (name.length > maxGroupNameLength) {
			groupNameInputError = t("longGroupNameError");
		} else if (name.length === 0) {
			groupNameInputError = t("shortGroupNameError");
		}

		if (selectedUsers.length === 0) {
			groupMemberInputError = t("noMembersError");
		}

		setGroupNameInputError(groupNameInputError);
		setGroupMemberInputError(groupMemberInputError);

		return !groupNameInputError && !groupMemberInputError;
	};
	const handleGroupNameInput = event => {
		const name = event.target.value;
		modalsSetCustomGroupModalGroup({ ...modalGroup, name: name });
		setGroupNameInputError(isDuplicateGroupName(name) ? t("groupNameAlreadyUsed") : "");
	};

	const handleToggleCheckbox = () =>
		modalsSetCustomGroupModalGroup({
			...modalGroup,
			isSharedWithMembers: !modalGroup.isSharedWithMembers
		});

	const handleCreateGroup = () => {
		if (isNewGroupValid() === false) return;
		modalButtonClickHandler();
		closeAndResetModal();
	};

	return (
		<Modal
			open={displayModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<StyledFormControl>
				<StyledCloseModalButton
					aria-label="close-modal"
					size="large"
					onClick={closeAndResetModal}>
					<CloseIcon />
				</StyledCloseModalButton>

				<ModalTitle variant="h6">{modalTitle}</ModalTitle>
				<ModalSecondaryText variant="h5">{secondaryText}</ModalSecondaryText>

				<StyledTextField
					data-testid="title-input"
					error={!!groupNameInputError}
					helperText={groupNameInputError}
					size="small"
					variant="outlined"
					label={t("groupName")}
					onInput={handleGroupNameInput}
					value={modalGroup.name}
				/>

				<SelectedUsersContainer
					selectedUsers={selectedUsers}
					errorMessage={groupMemberInputError}
				/>

				<Typography variant="h5">{t("addMembers")}</Typography>
				<UserSearch />

				<StyledFormControlLabel
					control={
						<Checkbox
							checked={modalGroup.isSharedWithMembers}
							color="secondary"
							onChange={handleToggleCheckbox}
						/>
					}
					label={t("shareWithMembers")}
				/>

				<CreateGroupButtonContainer>
					<StyledButton
						variant="Outlined"
						onClick={handleCreateGroup}
						data-testid="confirm-btn">
						{modalButtonText}
					</StyledButton>
				</CreateGroupButtonContainer>
			</StyledFormControl>
		</Modal>
	);
}

CustomGroupModal.propTypes = {
	/** A boolean representing whether to display the modal or not */
	displayModal: PropTypes.bool,
	/** A string representing the title shown in the modal */
	modalTitle: PropTypes.string,
	/** A string representing the text shown in the modal button */
	modalButtonText: PropTypes.string,
	/** A function representing the click handler for the modal button */
	modalButtonClickHandler: PropTypes.func,
	/** A boolean representing whether to display the secondary text or not */
	secondaryText: PropTypes.string,
	/** The modals object from state */
	modals: PropTypes.object,
	/** The groups object from state */
	groups: PropTypes.object,
	/** Action used to set the group shown in the modal */
	modalsSetCustomGroupModalGroup: PropTypes.func,
	/** Action used to show or hide the modals*/
	modalsSetModalType: PropTypes.func,
	/** Action used to reset the modal input fields */
	modalsResetCustomGroupModal: PropTypes.func
};

const mapStateToProps = state => {
	return {
		modals: state.modals,
		groups: state.user.groups
	};
};

export default connect(mapStateToProps, {
	modalsSetCustomGroupModalGroup,
	modalsSetModalType,
	modalsResetCustomGroupModal
})(CustomGroupModal);
