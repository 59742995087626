import React, { useEffect, useState } from "react";
import { getFeedbackImage } from "services/apiFeedbackService";
import PropTypes from "prop-types";
import { Box, Dialog } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

const FeedbackImage = ({ feedbackId }) => {
	const [imageData, setImageData] = useState("");
	const [open, setOpen] = useState(false);

	useEffect(() => {
		console.log("feedbackId: " + feedbackId);
		getFeedbackImage(feedbackId).then(response => {
			if (response.response !== "noImage") {
				setImageData("data:" + response.contentType + ";base64," + response.fileContents);
			}
		});
	}, [feedbackId]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			{imageData !== "" ? (
				<>
					<Box
						component={"img"}
						src={imageData}
						alt="Feedback image"
						onClick={handleOpen}
						sx={{
							width: "100%"
						}}
					/>
					<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
						<Box component={"img"} src={imageData} alt="Feedback image" />
					</Dialog>
				</>
			) : (
				<ImageIcon
					sx={{
						fontSize: "300px",
						width: "100%"
					}}
				/>
			)}
		</>
	);
};

FeedbackImage.propTypes = {
	/** The feedback id of the image */
	feedbackId: PropTypes.string
};

export default FeedbackImage;
