import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TimelineContainer from "./TimelineContainer/TimelineContainer";
import PropTypes from "prop-types";
import NavAppBar from "../NavAppBar/NavAppBar";
import { LoaderSpinner } from "components/Loading/LoaderSpinners";
import ErrorSnackbar from "components/ErrorSnackbar/ErrorSnackbar";
import { useTranslation } from "react-i18next";
import {
	GroupHeader,
	GroupHeaderMobile,
	GroupSecondaryText,
	GroupTitle,
	NoGroupsText
} from "./TimelineStyles";
import { useMediaQuery } from "@mui/material";
import { setSnackbar } from "actions/snackbarActions";
import {
	GROUP_TYPE_CUSTOM_GROUP,
	GROUP_TYPE_AD_GROUP,
	SELECTED_GROUP_ALL_OPTION
} from "../../constants";
import { getGroupOwnerName, getSortedGroups } from "utils/groupHelper";

/** The main *Timeline* component */
const Timeline = ({ event, view, user, setSnackbar }) => {
	const [isResized, setResized] = useState(false);
	const { t } = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 960px)");
	const GroupHeaderStyled = isDesktop ? GroupHeader : GroupHeaderMobile;

	useEffect(() => {
		const handleResize = () => {
			setResized(!isResized);
		};
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isResized]);

	const showSpinner =
		(view.start === undefined || event.groupEntries === undefined) && !event.error;
	const showError = (view.start === undefined || event.groupEntries === undefined) && event.error;
	if (showError) setSnackbar(t("errorMessage"), "error");

	// Gets the text to be displayed below the group name
	const getSecondaryText = group => {
		const userOwnsGroup = group.groupOwnerId === user.id;
		const isCustomGroup = group.groupType === GROUP_TYPE_CUSTOM_GROUP;
		const isADGroup = group.groupType === GROUP_TYPE_AD_GROUP;
		const groupOwnerName = getGroupOwnerName(group, event.groupEntries);

		return isCustomGroup
			? group.isSharedWithMembers
				? userOwnsGroup
					? `${t("customGroup")}`
					: `${t("customGroup")} ${t("sharedBy").toLowerCase()} ${groupOwnerName}`
				: `${t("customGroup")}`
			: isADGroup
				? `${t("bouvetGroup")}`
				: null;
	};

	// Creates a timeline for each group and custom group
	const getGroupTimelines = () => {
		let groupTimelines = [];
		for (const group of getSortedGroups(user.groups, user)) {
			// Only show selected group or all groups
			if (
				user.selectedGroupId !== SELECTED_GROUP_ALL_OPTION.id &&
				user.selectedGroupId !== group.id
			)
				continue;

			const groupHasEvents = event.groupEntries.has(group.id);
			groupTimelines.push(
				<div key={group.id}>
					{!groupHasEvents ? (
						<LoaderSpinner />
					) : (
						<>
							<GroupHeaderStyled data-testid={`group-header-${group.id}`}>
								<GroupTitle>{group.name}</GroupTitle>
								<GroupSecondaryText>{getSecondaryText(group)}</GroupSecondaryText>
							</GroupHeaderStyled>
							<TimelineContainer
								data={event.groupEntries.get(group.id)}
								dateType={view.scope}
								startDate={new Date(view.start)}
								endDate={new Date(view.end)}
								selectedDate={new Date(view.start)}
								resize={isResized}
								useCompactView={view.compactView}
								user={user}
							/>
						</>
					)}
				</div>
			);
		}
		return groupTimelines;
	};

	let timelines = [];
	if (!showSpinner) {
		timelines = getGroupTimelines();
	}

	return (
		<>
			<NavAppBar />
			{!showError && showSpinner && <LoaderSpinner />}
			{!showSpinner && timelines.length === 0 && (
				<NoGroupsText variant="h3">
					{t("noGroups1")}
					<br />
					<br />
					{t("noGroups2")}
					<br />
					<br />
					{t("noGroups3")}
				</NoGroupsText>
			)}
			{!showSpinner && timelines}
			<ErrorSnackbar />
		</>
	);
};

Timeline.propTypes = {
	/** The view object from state */
	view: PropTypes.object,
	/** The event object from state */
	event: PropTypes.object,
	/** The user object from state */
	user: PropTypes.object,
	/** The snackbar object from state */
	snackbar: PropTypes.object,
	/** Action for setting snackbar message and severity */
	setSnackbar: PropTypes.func
};

const mapStateToProps = state => {
	return {
		view: state.view,
		event: state.event,
		user: state.user
	};
};

export default connect(mapStateToProps, { setSnackbar })(Timeline);
