import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { postCustomGroup } from "services/apiCustomGroupService";
import { connect, useDispatch, useStore } from "react-redux";
import { userSetSelectedGroupId } from "actions/userActions";
import CustomGroupModal from "../CustomGroupModal";
import { SHOW_NEW_CUSTOM_GROUP_MODAL, GROUP_TYPE_CUSTOM_GROUP } from "../../../constants";
import { v4 as uuidv4 } from "uuid";
import { addGroupToState } from "utils/groupHelper";
import { CustomGroupDto } from "models/customGroupDto";
import { SimpleGroupDto } from "models/simpleGroupDto";

/**
 * Represents the modal used to add new custom groups
 */
function NewCustomGroupModal({ modals, user, userSetSelectedGroupId }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = useStore();
	const modalGroup = modals.customGroupModal.modalGroup;

	const handleCreateGroup = async () => {
		// Filter out the current user and get Ids of the selected users to be sent in put request
		const memberIdList = modals.customGroupModal.selectedUsers
			.filter(usernameAndId => usernameAndId.id !== user.id)
			.map(usernameAndId => usernameAndId.id);

		const newGroup = new CustomGroupDto(
			modalGroup.name,
			uuidv4(),
			memberIdList,
			modalGroup.isSharedWithMembers
		);
		const result = await postCustomGroup({ ...newGroup });

		if (result) {
			addGroupToState(
				new SimpleGroupDto(
					newGroup.name,
					newGroup.rowKey,
					newGroup.isSharedWithMembers,
					user.id,
					GROUP_TYPE_CUSTOM_GROUP
				),
				store,
				dispatch
			);
			userSetSelectedGroupId(newGroup.rowKey); // Select and display the new group
		}
	};

	return (
		<CustomGroupModal
			displayModal={modals.modalType === SHOW_NEW_CUSTOM_GROUP_MODAL}
			modalTitle={t("newGroup")}
			modalButtonText={t("createGroup")}
			modalButtonClickHandler={handleCreateGroup}
			secondaryText={
				t("groupLimit1") + process.env.REACT_APP_MAXIMUM_CUSTOM_GROUPS + t("groupLimit2")
			}
		/>
	);
}

NewCustomGroupModal.propTypes = {
	/** The modals object from state */
	modals: PropTypes.object,
	/** The user object from state */
	user: PropTypes.object,
	/** Action for setting selected group */
	userSetSelectedGroupId: PropTypes.func
};

const mapStateToProps = state => {
	return {
		modals: state.modals,
		user: state.user
	};
};

export default connect(mapStateToProps, {
	userSetSelectedGroupId
})(NewCustomGroupModal);
