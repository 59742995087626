import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const GroupHeader = styled(Box)(() => ({
	justifyContent: "center",
	marginLeft: "250px",
	marginTop: "45px"
}));

export const GroupHeaderMobile = styled(Box)(() => ({
	justifyContent: "center",
	marginLeft: "65px",
	marginTop: "20px"
}));

export const GroupTitle = styled(Typography)(() => ({
	display: "block",
	fontSize: "x-large",
	textAlign: "center"
}));

export const GroupSecondaryText = styled(Typography)(() => ({
	fontSize: "12px",
	textAlign: "center",
	display: "block"
}));

export const NoGroupsText = styled(Typography)(() => ({
	width: "60%",
	margin: "auto",
	textAlign: "center",
	fontSize: "15px",
	marginTop: "100px"
}));
