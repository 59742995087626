import { SimpleGroupDto } from "models/simpleGroupDto";
import {
	WEEK,
	TIMELINE_PREFERENCE,
	TIMELINE_START,
	TIMELINE_END,
	SELECTED_GROUP_ALL_OPTION,
	HIDE_MODALS,
	GROUP_TYPE_CUSTOM_GROUP
} from "../constants";

const today = new Date();

const initialState = {
	user: {
		name: undefined,
		id: undefined,
		filtered: false,
		filteredOutsideWorkingHours: false,
		groups: undefined,
		selectedGroupId: SELECTED_GROUP_ALL_OPTION.id,
		filteredRecurringEvents: false,
		isAdmin: false
	},
	view: {
		today: today.toString(),
		current: today.toString(),
		start: localStorage.getItem(TIMELINE_START) || undefined,
		end: localStorage.getItem(TIMELINE_END) || undefined,
		scope: localStorage.getItem(TIMELINE_PREFERENCE) || WEEK,
		showFromToday: false,
		compactView: false
	},
	loading: {
		isLoading: true,
		loadingQueue: []
	},
	event: {
		groupEntries: undefined
	},
	usersPhotos: {
		photos: undefined
	},
	modals: {
		modalType: HIDE_MODALS,
		customGroupModal: {
			modalGroup: new SimpleGroupDto("", "", false, "", GROUP_TYPE_CUSTOM_GROUP),
			selectedUsers: []
		}
	},
	snackbar: {
		message: undefined,
		severity: undefined
	}
};

export default initialState;
