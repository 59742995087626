import styled from "@emotion/styled";

export const DivTimelineDateText = styled("div")(({ theme }) => ({
	...theme.timeline.timelineUserText,
	color: "#8d8d8d",
	textAlign: "left",
	letterSpacing: "0.5px"
}));

export const DivTimelineDateTextWithWrap = styled("div")(({ theme }) => ({
	...theme.timeline.timelineUserText,
	width: "100%",
	color: "#8d8d8d",
	textAlign: "left",
	letterSpacing: "0.5px"
}));

export const DivTimelineDateItem = styled("div")(({ theme }) => ({
	position: "relative",
	width: "100%",
	borderBottom: theme.timeline.gridBorders.border,
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	paddingLeft: "5px"
}));

export const DivTimelineDateItemWithWrap = styled("div")(({ theme }) => ({
	position: "relative",
	width: "100%",
	borderBottom: theme.timeline.gridBorders.border,
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "flex-start",
	alignItems: "center",
	paddingLeft: "5px"
}));
