import { React } from "react";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";
import { viewMoveCurrent } from "../../../../actions/viewActions";
import { DECREMENT } from "../../../../constants";

const NavDirectionButton = ({ direction, viewMoveCurrent }) => {
	const handleClick = () => {
		viewMoveCurrent(direction);
	};

	return (
		<div>
			<IconButton
				data-testid="nav-direction-btn"
				aria-label="nav-direction-btn"
				onClick={handleClick}
				size="large">
				{direction === DECREMENT ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
			</IconButton>
		</div>
	);
};

NavDirectionButton.propTypes = {
	/** A number indicating which direction to navigate (-1: backward, 1: forward) */
	direction: PropTypes.number,
	/** Action for moving the view of current scope */
	viewMoveCurrent: PropTypes.func
};

export default connect(null, { viewMoveCurrent })(NavDirectionButton);
