import React from "react";
import PropTypes from "prop-types";
import {
	DivTimelineDateItem,
	DivTimelineDateItemWithWrap,
	DivTimelineDateText,
	DivTimelineDateTextWithWrap
} from "./TimelineDateItemStyles";
import HolidayIcons from "../../HolidayIcons/HolidayIcons";

/**
 * Represents a date representation in the timeline
 */
const TimelineDateItem = ({
	text = "",
	lineBreakAt = undefined,
	date = undefined,
	dateType = undefined
}) => {
	let textStart = "";
	let textEnd = "";

	const today = new Date();
	let isSameDate = (today, date) => {
		return (
			date &&
			today.getFullYear() === date.getFullYear() &&
			today.getMonth() === date.getMonth() &&
			today.getDate() === date.getDate()
		);
	};

	const fontSize = window.innerWidth > 1800 ? "16px" : "12px";
	// Using bold font if date is today
	const fontWeight = isSameDate(today, date) ? "bold" : "normal";
	// Using white color if date is today
	const color = isSameDate(today, date) ? "#dddddd" : "";

	// Make sure date is capitalized
	let capitalized = text !== "" ? text[0].toUpperCase() + text.slice(1) : "";

	// Add line break if specified
	if (lineBreakAt) {
		textStart = capitalized.slice(0, lineBreakAt) + " ";
		textEnd = capitalized.slice(lineBreakAt);

		return (
			<DivTimelineDateItemWithWrap>
				<DivTimelineDateTextWithWrap
					style={{ fontSize: fontSize, fontWeight: fontWeight, color: color }}>
					{textStart}
				</DivTimelineDateTextWithWrap>
				<DivTimelineDateTextWithWrap
					style={{ fontSize: fontSize, fontWeight: fontWeight, color: color }}>
					{textEnd}
				</DivTimelineDateTextWithWrap>
			</DivTimelineDateItemWithWrap>
		);
	}

	return (
		<DivTimelineDateItem>
			<DivTimelineDateText style={{ fontWeight: fontWeight, color: color }}>
				{capitalized}
			</DivTimelineDateText>
			{dateType === "week" && <HolidayIcons date={date} />}
		</DivTimelineDateItem>
	);
};

TimelineDateItem.propTypes = {
	/** Date item text  */
	text: PropTypes.string,
	/** Line break at index */
	lineBreakAt: PropTypes.number,
	/** Date of the date item */
	date: PropTypes.instanceOf(Date),
	/** Date type */
	dateType: PropTypes.string
};

export default TimelineDateItem;
