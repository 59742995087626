import React from "react";
import DivRoot from "components/AppStyles";
import MenuAppBar from "components/MenuAppBar/MenuAppBar";
import ViewFeedbackMenu from "components/ViewFeedbackMenu/ViewFeedbackMenu";

// /** Page component used to administrate groups */
function ViewFeedbackPage() {
	return (
		<DivRoot>
			<MenuAppBar />
			<ViewFeedbackMenu />
		</DivRoot>
	);
}

export default ViewFeedbackPage;
