import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const StyledEditIconButton = styled(IconButton)(() => ({
	width: "30px",
	height: "30px"
}));

export const StyledDeleteIconButton = styled(IconButton)(() => ({
	width: "30px",
	height: "30px",
	"&:hover": { color: "red" }
}));

export const StyledEditIcon = styled(EditIcon)(() => ({
	fontSize: "20px"
}));

export const StyledDeleteIcon = styled(DeleteIcon)(() => ({
	fontSize: "20px"
}));
