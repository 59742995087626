import { EVENT_SET_EVENTS, EVENT_ADD_EVENTS } from "../constants";
import initialState from "./initialState";

/** Reducer for the event object in state */
export default function (state = initialState.event, action) {
	switch (action.type) {
		case EVENT_SET_EVENTS: {
			return { ...state, groupEntries: action.payload };
		}
		case EVENT_ADD_EVENTS: {
			if (!state.groupEntries) {
				return { ...state, groupEntries: action.payload };
			}
			return addGroupEntriesToState(state, action.payload);
		}
		default:
			return state;
	}
}

function addGroupEntriesToState(state, payload) {
	let newState = { ...state };

	for (const [groupId, payloadGroupEntries] of payload) {
		// Check if group exists, add entire payload if not
		if (!newState.groupEntries.has(groupId)) {
			newState.groupEntries.set(groupId, payloadGroupEntries);
			continue;
		}
		// Combine entries stored in state with entries stored in payload
		newState.groupEntries.set(
			groupId,
			combineEventsInEntries(newState.groupEntries.get(groupId), payloadGroupEntries)
		);
	}

	return newState;
}

/** Combines the events of all users in two entries objects without including duplicate events */
function combineEventsInEntries(stateEntries, payloadEntries) {
	const stateEntriesMap = new Map(stateEntries.map(entry => [entry.user.id, entry]));

	for (let entryInPayload of payloadEntries) {
		const entryInState = stateEntriesMap.get(entryInPayload.user.id);

		if (entryInState) {
			entryInState.events.push(
				...combineEventArrays(entryInState.events, entryInPayload.events)
			);
		} else {
			stateEntriesMap.set(entryInPayload.user.id, entryInPayload);
		}
	}

	return Array.from(stateEntriesMap.values());
}

/** Combines two arrays of events without including duplicates  */
function combineEventArrays(userEventsInState, userEventsInPayload) {
	const stateEventIds = new Set(
		userEventsInState.map(event => `${event.subject}-${event.start}-${event.end}`)
	);
	return userEventsInPayload.filter(
		event => !stateEventIds.has(`${event.subject}-${event.start}-${event.end}`)
	);
}
