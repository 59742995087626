import {
	LOADING_SET_IS_LOADING,
	LOADING_ADD_TO_LOADING_QUEUE,
	LOADING_REMOVE_FROM_LOADING_QUEUE
} from "../constants";
import initialState from "./initialState";

export default function (loading = initialState.loading, action) {
	switch (action.type) {
		case LOADING_SET_IS_LOADING:
			return {
				...loading,
				isLoading: action.isLoading
			};
		case LOADING_ADD_TO_LOADING_QUEUE:
			return {
				...loading,
				loadingQueue: [...loading.loadingQueue, action.id]
			};
		case LOADING_REMOVE_FROM_LOADING_QUEUE:
			return {
				...loading,
				loadingQueue: loading.loadingQueue.filter(id => id !== action.id)
			};
		default:
			return loading;
	}
}
