import React, { useEffect } from "react";
import initUserConfig from "config/initUserConfig";
import PropTypes from "prop-types";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminPage from "pages/AdminPage/AdminPage";
import TimeLinePage from "pages/TimelinePage/TimeLinePage";
import FeedbackPage from "pages/FeedbackPage/FeedbackPage";
import ViewFeedbackPage from "pages/ViewFeedbackPage/ViewFeedbackPage";

/**
 * The main *App* component.
 * Mounts the menu and navigation bars and the timeline component
 */
const App = () => {
	useEffect(() => {
		initUserConfig();
	}, []);
	return (
		<Routes>
			<Route path="/" element={<TimeLinePage />} />
			<Route path="admin" element={<AdminPage />} />
			<Route path="feedback" element={<FeedbackPage />} />
			<Route path="viewfeedback" element={<ViewFeedbackPage />} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

App.propTypes = {
	/** Action for setting the view in current scope */
	store: PropTypes.object
};

export default App;
