import React from "react";
import { StyledMenu } from "../UserMenu/UserMenuStyles";
import PropTypes from "prop-types";
import FilterUserMenuItem from "../UserMenuItem/FilterUserMenuItem";
import FilterWorkingHourMenuItem from "components/UserMenuItem/FilterWorkingHourMenuItem";
import FilterUserRecurringEventsItem from "components/UserMenuItem/FilterRecurringMenuItem";
import { connect } from "react-redux";
import ToggleCompactViewMenuItem from "components/UserMenuItem/ToggleCompactViewMenuItem";

/**
 * Represents the settings menu displayed when clicking the timeline settings icon
 */
const TimelineSettingsMenu = ({ open, anchorEl, handleClose }) => {
	return (
		<StyledMenu
			data-testid="timeline-settings-menu"
			id="timeline-settings-menu-appbar"
			anchorEl={anchorEl}
			open={open}
			keepMounted
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}>
			<FilterUserMenuItem />
			<FilterWorkingHourMenuItem />
			<FilterUserRecurringEventsItem />
			<ToggleCompactViewMenuItem />
		</StyledMenu>
	);
};

TimelineSettingsMenu.propTypes = {
	/** Anchor element for positioning the menu */
	anchorEl: PropTypes.object,
	/** Function for handling close event on menu */
	handleClose: PropTypes.func,
	/** Boolean setting the open prop on the menu */
	open: PropTypes.bool
};

export default connect(null)(TimelineSettingsMenu);
