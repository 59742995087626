import authServiceInstance from "services/authService";
import { store } from "reducers/store";
import { format } from "date-fns";
import { setSnackbar } from "actions/snackbarActions";
import { ENDPOINT } from "constants";

/** Helper function for issuing API requests */
const apiRequest = async (loc, method = "GET", data = null, isForm) => {
	let token = await authServiceInstance.getToken();
	let fetchOptions = {
		method,
		headers: {
			authorization: `Bearer ${token.accessToken}`,
			...(isForm ? {} : { "Content-Type": "application/json" })
		},
		body: data && isForm ? data : data && isForm === false ? JSON.stringify(data) : null
	};
	try {
		const response = await fetch(loc, fetchOptions);
		// Bad request: user provided invalid data and backend returned an error message
		if (response.status === 400 && method === "POST") {
			const errorMessage = await response.text();
			store.dispatch(setSnackbar(errorMessage, "error"));
		} else if (response.status !== 200) {
			store.dispatch(setSnackbar("Failed to fetch data from API", "error"));
			throw response.statusText;
		} else {
			return method === "GET" ? await response.json() : await response.text();
		}
	} catch (error) {
		store.dispatch(setSnackbar("Failed to fetch data from API", "error"));
		console.log(error);
		throw error;
	}
};

/** Helper function for issuing GET requests */
export const getApiData = loc => apiRequest(loc, "GET", null, false);

/** Helper function for issuing POST requests with form body */
export const postApiData = (loc, data, isForm = false) => apiRequest(loc, "POST", data, isForm);

/** Helper function for issuing DELETE requests */
export const deleteApiData = (loc, data, isForm = false) => apiRequest(loc, "DELETE", data, isForm);

/** Helper function for issuing PUT requests */
export const putApiData = (loc, data, isForm = false) => apiRequest(loc, "PUT", data, isForm);

/** API call for getting all calendar entries */
export const getSchedulesByStartTimeAndEndTime = async (groupID, startDate, endDate) => {
	const loc =
		ENDPOINT +
		"getSchedules/" +
		groupID +
		"?startDate=" +
		format(startDate, "dd.MM.yyyy") +
		"&endDate=" +
		format(endDate, "dd.MM.yyyy");
	return await getApiData(loc);
};
