import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PrivateIcon } from "../../Icons/privateIcon.svg";
import { ReactComponent as ParentalLeaveIcon } from "../../Icons/parentalLeaveIcon.svg";
import { ReactComponent as TrainingIcon } from "../../Icons/trainingIcon.svg";
import { ReactComponent as VacationIcon } from "../../Icons/vacationIcon.svg";
import { ReactComponent as WinterVacationIcon } from "../../Icons/winterVacationIcon.svg";
import { ReactComponent as UncategorizedIcon } from "../../Icons/uncategorizedIcon.svg";
import { ReactComponent as HomeOfficeIcon } from "../../Icons/homeOfficeIcon.svg";
import { ReactComponent as MovingIcon } from "../../Icons/movingIcon.svg";
import { ReactComponent as WeddingIcon } from "../../Icons/weddingIcon.svg";
import {
	PARENTAL_LEAVE_KEYWORDS,
	TRAINING_KEYWORDS,
	VACATION_KEYWORDS,
	HOME_OFFICE_KEYWORDS,
	MOVING_KEYWORDS,
	WEDDING_KEYWORDS,
	UNDISCLOSED_KEYWORDS
} from "../../../constants";
import { checkWords, deepCheckWordsVacation } from "../../../utils/checkWordsHelper";

/** Function gets the correct Icon based on title */
const getAbsenceTypeIcon = (title, isWinter) => {
	if (
		!title ||
		title === undefined ||
		title.length === 0 ||
		checkWords(UNDISCLOSED_KEYWORDS, title)
	)
		return PrivateIcon;

	if (checkWords(PARENTAL_LEAVE_KEYWORDS, title)) return ParentalLeaveIcon;
	if (checkWords(TRAINING_KEYWORDS, title)) return TrainingIcon;
	if (checkWords(HOME_OFFICE_KEYWORDS, title)) return HomeOfficeIcon;
	if (checkWords(MOVING_KEYWORDS, title)) return MovingIcon;
	if (checkWords(WEDDING_KEYWORDS, title)) return WeddingIcon;

	if (checkWords(VACATION_KEYWORDS, title || deepCheckWordsVacation(VACATION_KEYWORDS, title))) {
		return isWinter ? WinterVacationIcon : VacationIcon;
	}

	return UncategorizedIcon;
};

/**
 * Represents the absence type icon in the event in the timeline
 */
const AbsenceTypesIcon = ({ title, isWinter, centerIcon, hidden, tooSmall }) => {
	let Icon = getAbsenceTypeIcon(title, isWinter);
	const style = centerIcon
		? {
				position: "absolute",
				left: "50%",
				transform: "translateX(-50%)",
				flexShrink: "0",
				visibility: tooSmall ? "hidden" : hidden ? "hidden" : "visible"
			}
		: {
				marginRight: "15px",
				marginLeft: "15px",
				flexShrink: "0",
				visibility: hidden ? "hidden" : "visible"
			};
	return <Icon data-testid="absence-icon" style={style} />;
};

AbsenceTypesIcon.propTypes = {
	/** The title of the event */
	title: PropTypes.string,
	/** Specifies if the event is in winter */
	isWinter: PropTypes.bool,
	/** Decides if icon is centered on the event */
	centerIcon: PropTypes.bool,
	/** Decides if icon is hidden on the event */
	hidden: PropTypes.bool,
	/** Decides if icon is too small on the event */
	tooSmall: PropTypes.bool
};

export default AbsenceTypesIcon;
