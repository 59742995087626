import { createTheme } from "@mui/material";

/** A custom theme for this app, created with colors from https://www.bouvet.no/styleguide */

const fontFamily = [
	"Source Sans Pro",
	"Arial",
	"sans-serif",
	"Times",
	"Times New Roman",
	"serif"
].join();

export const colors = {
	purple: "#7E57C2",
	black: "#121212",
	white: "#fff",
	errorRed: "#FF0000",
	lightgrey: "#696969",
	lightergrey: "rgba(255, 255, 255, 0.6)",
	grey: "#494949",
	darkgrey: "#393939",
	orange: "#CD5000",
	holidayRed: "#2A1A1A",
	saturdayGrey: "#1C1C1C",
	vacationYellow: "#fbbd2d",
	winterVacationYellowPale: "#fddc76",
	parentalLeaveCyan: "#5aa4a2",
	trainingOrange: "#fc863a",
	privateIron: "#d1d5d8",
	skiGrey: "#E1E8ED",
	movingRed: "#FF6347",
	weddingPink: "#E1E8ED",
	uncategorizedDarkgrey: "#989898",
	gridColor: "#292929",
	homeOfficeGreen: "#5C913B"
};

const miscOptions = {
	grid: `1px solid ${colors.gridColor}`
};

const theme = createTheme({
	palette: {
		mode: "dark", // Set to light if you want to disable dark mode
		primary: {
			// Grey base color
			main: colors.darkgrey,
			dark: colors.black,
			light: colors.lightgrey
		},
		secondary: {
			main: colors.orange,
			dark: colors.orange,
			light: colors.orange
		},
		// Used by getContrast() to maximize the contrast between the background and
		// the text.
		contrastThreshold: 3,
		// Used to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
		common: {
			white: colors.white
		},
		background: {
			default: colors.black,
			paper: colors.darkgrey,
			level1: colors.white,
			level2: colors.purple
		},
		text: {
			primary: colors.white,
			secondary: colors.lightergrey,
			disabled: colors.grey,
			hint: colors.purple
		},
		specialDays: {
			holiday: colors.holidayRed,
			saturday: colors.saturdayGrey
		}
	},
	button: {
		backgroundColor: colors.grey,
		color: colors.white,
		"&:hover": {
			backgroundColor: colors.orange
		}
	},
	typography: {
		// Headings
		h1: { fontFamily, fontWeight: "bold", fontSize: "64px" },
		h2: {
			fontFamily,
			fontWeight: "lighter",
			fontSize: "60px",
			letterSpacing: "0.18px",
			color: colors.orange
		},
		h3: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "34px",
			letterSpacing: "0.18px",
			color: colors.lightgrey
		},
		h4: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "24px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.lightgrey
		},
		// used for ubar title font
		h5: {
			fontFamily,
			fontWeight: "lighter",
			fontSize: "14px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.lightergrey
		},
		h6: {
			fontFamily,
			fontWeight: "bold",
			fontSize: "20px",
			letterSpacing: "1.25px",
			color: colors.white
		},
		subtitle1: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "16px",
			color: colors.white
		},
		subtitle2: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "10px",
			lineHeight: "16px",
			letterSpacing: "1.5px",
			color: colors.lightergrey
		},
		body1: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "16px",
			letterSpacing: "0.5px",
			lineHeight: "24px",
			color: colors.white
		},
		body2: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "12px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.white
		},
		button: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "14px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.white
		},
		caption: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "12px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.white
		},
		overline: {
			fontFamily,
			fontWeight: "normal",
			fontSize: "10px",
			letterSpacing: "1.25px",
			lineHeight: "16px",
			color: colors.white
		},
		// All other fonts
		fontFamily: ["Source Sans Pro", "Helvetica Neue", "Helvetica", "sans-serif"].join(",")
	},
	timeline: {
		box: {
			minWidth: "50px",
			minHeight: "50px"
		},
		timelineDataItem: {
			marginTop: "18px",
			marginBottom: "18px",
			position: "absolute",
			overflow: "hidden",
			borderRadius: "7px"
		},
		timelineUserText: {
			fontSize: "16px",
			letterSpacing: "0.5px",
			lineHeight: "23px",
			overflow: "hidden"
		},
		compactTimelineUserText: {
			fontSize: "12px",
			letterSpacing: "0.5px",
			lineHeight: "16px"
		},
		timelineUserItem: {
			width: "100%",
			position: "relative"
		},
		absenceTypeColor: {
			parentalLeave: colors.parentalLeaveCyan,
			training: colors.trainingOrange,
			vacation: colors.vacationYellow,
			winterVacation: colors.winterVacationYellowPale,
			private: colors.privateIron,
			homeOffice: colors.homeOfficeGreen,
			moving: colors.movingRed,
			wedding: colors.weddingPink,
			uncategorized: colors.uncategorizedDarkgrey
		},
		gridBorders: {
			border: miscOptions.grid
		}
	}
});

// Override default themes
Object.assign(theme, {
	components: {
		MuiFab: {
			styleOverrides: {
				root: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.primary.main,
					"&:hover": {
						backgroundColor: theme.palette.secondary.main
					},
					minWidth: "120px"
				},
				sizeSmall: {}
			}
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.text.primary
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: theme.palette.secondary.main
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				elevation4: {
					boxShadow: "none"
				}
			}
		}
	}
});

export default theme;
