import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { MenuItemDiv, TypographyText } from "../UserMenu/UserMenuStyles";
import { MenuItem } from "@mui/material";
import { viewSetCompactView } from "actions/viewActions";

/**
 * Represents a menu option for toggling the compactView property in the view state
 */
const ToggleCompactViewMenuItem = ({ useCompactView, viewSetCompactView }) => {
	const { t } = useTranslation();
	const [enabled, setEnabled] = useState(useCompactView);

	const toggleCompactView = () => {
		setEnabled(!enabled);
		viewSetCompactView(!enabled);
	};

	return (
		<MenuItem onClick={toggleCompactView}>
			<MenuItemDiv>
				{enabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
				<TypographyText data-testid="usermenu-compactView" variant="body1">
					{t("compactView")}
				</TypographyText>
			</MenuItemDiv>
		</MenuItem>
	);
};

ToggleCompactViewMenuItem.propTypes = {
	/** Indicates if the compact view should be shown */
	useCompactView: PropTypes.bool,
	/** Function for setting the compact view */
	viewSetCompactView: PropTypes.func
};

export const mapStateToProps = state => {
	return {
		useCompactView: state.view.compactView
	};
};

export default connect(mapStateToProps, { viewSetCompactView })(ToggleCompactViewMenuItem);
