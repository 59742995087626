import { SimpleGroupDto } from "models/simpleGroupDto";
import {
	MODALS_SET_CUSTOM_GROUP_MODAL_GROUP,
	MODALS_SET_CUSTOM_GROUP_SELECTED_USERS,
	MODALS_SET_MODAL_TYPE,
	MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP,
	GROUP_TYPE_CUSTOM_GROUP
} from "../constants";
import initialState from "./initialState";

/** Reducer for the modals object in state */
export default function (state = initialState.modals, action) {
	switch (action.type) {
		case MODALS_SET_CUSTOM_GROUP_MODAL_GROUP:
			return {
				...state,
				customGroupModal: {
					...state.customGroupModal,
					modalGroup: action.payload
				}
			};
		case MODALS_SET_CUSTOM_GROUP_SELECTED_USERS:
			return {
				...state,
				customGroupModal: {
					...state.customGroupModal,
					selectedUsers: action.payload
				}
			};
		case MODALS_SET_MODAL_TYPE:
			return {
				...state,
				modalType: action.payload
			};
		case MODALS_RESET_CUSTOM_GROUP_MODAL_GROUP:
			return {
				...state,
				customGroupModal: {
					...state.customGroupModal,
					modalGroup: new SimpleGroupDto("", "", false, "", GROUP_TYPE_CUSTOM_GROUP),
					selectedUsers: []
				}
			};
		default:
			return {
				...state
			};
	}
}
