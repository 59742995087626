import {
	IconButton,
	Typography,
	FormControl,
	TextField,
	Button,
	FormControlLabel
} from "@mui/material";
import styled from "@emotion/styled";
import theme, { colors } from "styles/theme";

export const ModalTitle = styled(Typography)(() => ({
	color: colors.orange
}));

export const ModalSecondaryText = styled(Typography)(() => ({
	marginBottom: "18px"
}));

export const StyledCloseModalButton = styled(IconButton)(() => ({
	position: "absolute",
	top: "2px",
	right: "2px"
}));

export const StyledFormControl = styled(FormControl)(() => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	backgroundColor: colors.gridColor,
	border: "2px solid #000",
	boxShadow: 24,
	padding: 20
}));

export const StyledTextField = styled(TextField)(() => ({
	"& label.Mui-focused": {
		color: colors.orange
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: colors.lightgrey
		},
		"&.Mui-focused fieldset": {
			borderColor: colors.orange
		}
	},
	marginBottom: "15px"
}));

export const CreateGroupButtonContainer = styled("div")(() => ({
	marginBottom: "15px",
	display: "flex",
	justifyContent: "center"
}));

export const StyledButton = styled(Button)(() => ({
	...theme.button,
	maxHeight: "40px"
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	marginBottom: "10px"
}));
