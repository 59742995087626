import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import CircleIcon from "@mui/icons-material/FiberManualRecordOutlined";
import UsageGuide1 from "../../assets/images/UsageGuide1.png";
import UsageGuide2 from "../../assets/images/UsageGuide2.png";
import UsageGuide3 from "../../assets/images/UsageGuide3.png";
import theme from "styles/theme";
import {
	DialogContentTextText,
	BackIconBack,
	DivSpacer,
	ForwardIconNext
} from "./UsageGuideStyles";

const progress = [
	<>
		<DotIcon fontSize={"small"} />
		<CircleIcon fontSize={"small"} />
		<CircleIcon fontSize={"small"} />
	</>,
	<>
		<CircleIcon fontSize={"small"} />
		<DotIcon fontSize={"small"} />
		<CircleIcon fontSize={"small"} />
	</>,
	<>
		<CircleIcon fontSize={"small"} />
		<CircleIcon fontSize={"small"} />
		<DotIcon fontSize={"small"} />
	</>
];

const image = [UsageGuide1, UsageGuide2, UsageGuide3];

/**
 * Represents progress dots indicating which page is currently viewed
 */
const Progress = props => progress[props.pageIndex];

/**
 * Represents the usage guide, explanation of the application
 */
const UsageGuide = props => {
	const { onClose, open } = props;
	const { t } = useTranslation();

	const title = [
		t("usageGuideTitlePageOne"),
		t("usageGuideTitlePageTwo"),
		t("usageGuideTitlePageThree")
	];
	const text = [
		t("usageGuideTextPageOne"),
		t("usageGuideTextPageTwo"),
		t("usageGuideTextPageThree")
	];

	const content = [progress, image, title, text];
	const startPage = 0;
	const endPage = content.reduce((p, c, i, a) => (a[p].length < c.length ? p : i), 0) - 1; // Min index of the content arrays

	const [currentPage, setCurrentPage] = useState(startPage);

	const hasNextPage = currentPage < endPage;
	const hasPreviousPage = currentPage > startPage;

	const handleNextPage = () => {
		if (hasNextPage) setCurrentPage(currentPage + 1);
		else handleClose();
	};
	const handlePreviousPage = () => {
		if (hasPreviousPage) setCurrentPage(currentPage - 1);
	};

	const handleClose = () => onClose();
	const resetDialog = () => setCurrentPage(startPage);

	useEffect(() => {
		// Re-render on page flip
	}, [currentPage]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"md"}
			onClose={handleClose}
			TransitionProps={{ onExited: resetDialog }}
			aria-labelledby="usage-guide-title"
			open={open}>
			<img
				src={image[currentPage]}
				alt="usage-guide-illustration"
				color={String(theme.palette.primary)}
			/>
			<Divider />
			<DialogTitle id="usage-guide-title">{title[currentPage]}</DialogTitle>
			<DialogContent>
				<DialogContentTextText paragraph id="usage-guide-text">
					{text[currentPage]}
				</DialogContentTextText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handlePreviousPage}
					style={!hasPreviousPage ? { visibility: "hidden" } : {}}
					size={"medium"}>
					<>
						<BackIconBack />
						<Typography>{t("previous")}</Typography>
					</>
				</Button>
				<DivSpacer />
				<Progress pageIndex={currentPage} />
				<DivSpacer />
				<Button onClick={handleNextPage} size={"medium"}>
					<>
						<Typography>{hasNextPage ? t("next") : t("finish")}</Typography>
						{hasNextPage ? <ForwardIconNext /> : ""}
					</>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

UsageGuide.propTypes = {
	/** Function for handling the closing */
	onClose: PropTypes.func,
	/** Boolean setting the open prop on the usage guide */
	open: PropTypes.bool.isRequired
};

Progress.propTypes = {
	/** Current page index */
	pageIndex: PropTypes.number.isRequired
};

export default UsageGuide;
