import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
export class AuthService {
	constructor() {
		this.msalConfig = {
			auth: {
				clientId: process.env.REACT_APP_CLIENT_ID,
				authority: process.env.REACT_APP_AUTH,
				redirectUri: process.env.REACT_APP_REDIRECT
			},

			cache: {
				cacheLocation: process.env.REACT_APP_CACHE_LOC,
				storeAuthStateInCookie: false
			},

			system: {
				loggerOptions: {
					loggerCallback: (level, message, containsPii) => {
						if (containsPii) {
							return;
						}
						switch (level) {
							case LogLevel.Error:
								console.error(message);
								return;
							case LogLevel.Info:
								console.info(message);
								return;
							case LogLevel.Verbose:
								console.debug(message);
								return;
							case LogLevel.Warning:
								console.warn(message);
								return;
							default:
								console.log(message);
						}
					}
				}
			}
		};

		this.msalClient = new PublicClientApplication(this.msalConfig);
		// console.log("AuthService:: initialized", this.msalConfig);
	}

	get serviceName() {
		return "Microsoft";
	}

	getToken = async () => {
		const activeAccount = this.msalClient.getActiveAccount();
		const accounts = this.msalClient.getAllAccounts();
		if (!activeAccount && accounts.length === 0) {
			return null;
		}
		return await this.msalClient.acquireTokenSilent({
			scopes: [`api://${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`],
			account: activeAccount || accounts[0]
		});
	};
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
