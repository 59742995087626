import React from "react";
import PropTypes from "prop-types";
import UserPhoto from "../../UserPhoto/UserPhoto";
import { useTranslation } from "react-i18next";
import { TimeLineUserText, TimeLineUserItemContainer } from "./TimelineUserItemStyles";
import { getNoOfAbsenceWorkDaysInSelectedPeriod } from "./TimelineUserItemHelper";

/**
 * Represent an user item in the timeline,
 * i.e. a profile picture and name
 */
const TimelineUserItem = ({ name, start, end, userWithEvents, useCompactView }) => {
	const { t } = useTranslation();
	let noOfWorkDaysAbsence = getNoOfAbsenceWorkDaysInSelectedPeriod(
		start,
		end,
		userWithEvents.events
	);
	let entryDateText = t("absenceWorkdays") + ": " + noOfWorkDaysAbsence;

	return (
		<TimeLineUserItemContainer>
			<div style={{ flexBasis: "10%" }} />
			<UserPhoto userId={userWithEvents.user.id} size={useCompactView ? "20px" : "40px"} />
			<div style={{ flexBasis: "7%" }} />

			<TimeLineUserText useCompactView={useCompactView}>
				{name}
				{!useCompactView && (
					<>
						<br />
						<div style={{ fontSize: "14px", color: "#b5b5b5" }}>{entryDateText}</div>
					</>
				)}
			</TimeLineUserText>
		</TimeLineUserItemContainer>
	);
};

TimelineUserItem.propTypes = {
	/** User name */
	name: PropTypes.string,
	/** Start date from current view */
	start: PropTypes.object,
	/** End date from current view */
	end: PropTypes.object,
	/** User with attached events */
	userWithEvents: PropTypes.object,
	/** Indicates if the compact view should be shown */
	useCompactView: PropTypes.bool
};

export default TimelineUserItem;
