import styled from "@emotion/styled";
import { Box, Button, TableCell, TableContainer } from "@mui/material";
import theme, { colors } from "styles/theme";

export const TableAndFilterContainer = styled(Box)(() => ({
	flexGrow: 1
}));

export const TableBox = styled(Box)(() => ({
	borderRadius: "5px",
	backgroundColor: colors.gridColor
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
	height: "60vh",
	overflowX: "hidden",
	overflowY: "auto",
	"::-webkit-scrollbar": {
		width: "8px"
	},
	"::-webkit-scrollbar-track": {
		backgroundColor: colors.darkgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb": {
		backgroundColor: colors.lightgrey,
		borderRadius: "10px"
	},
	"::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#a9a9a9"
	}
}));

export const HeaderTableCell = styled(TableCell)(() => ({
	backgroundColor: colors.darkgrey
}));

export const TableCellNoGroups = styled(TableCell)(() => ({
	textAlign: "center",
	border: "none",
	height: "50vh"
}));

export const RemoveButton = styled(Button)(() => ({
	...theme.button
}));
