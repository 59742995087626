import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { DivRoot, TypographyText } from "./NavTextDisplayStyles";
import { DAY, WEEK, MONTH, YEAR, norwegianCode } from "../../constants";
import { format, addBusinessDays, getMonth, getWeek } from "date-fns";
import { enGB, nb } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import HolidayIcons from "../HolidayIcons/HolidayIcons";

/**
 * Represents the text displaying the scope of current view
 */
const NavTextDisplay = ({ view }) => {
	const isTextAlignmentNarrow = useMediaQuery("(min-width: 480px)");
	const [text, setText] = useState("");
	const { i18n, t } = useTranslation();
	const languageCode = i18n.language;

	useEffect(() => {
		// Check what language is set for the application and set locale for date-fns
		const locale = languageCode === norwegianCode ? nb : enGB;

		// Function for setting the display text based on current view
		const displayText = view => {
			if (view.start === undefined) {
				return "";
			}

			const start = new Date(view.start);
			const end = new Date(view.end);
			switch (view.scope) {
				case YEAR: {
					return `${t("firstAndLastMonths")} ${format(
						addBusinessDays(start, 4),
						"yyyy"
					)}`;
				}
				case WEEK: {
					const weekStr = `${t("week")} ${getWeek(start, {
						weekStartsOn: 1, // monday
						firstWeekContainsDate: 4 // thursday
					})}, `;
					if (getMonth(start) === getMonth(end)) {
						return weekStr + format(start, "MMMM, yyyy", { locale });
					}
					return (
						weekStr +
						format(start, "MMMM", { locale }) +
						"-" +
						format(end, "MMMM, yyyy", { locale })
					);
				}
				case MONTH: {
					const strMonth = format(addBusinessDays(start, 4), "MMMM, yyyy", { locale });
					// Make sure first letter is capitalized
					return strMonth[0].toUpperCase() + strMonth.slice(1);
				}
				case DAY: {
					const strDay = format(start, "eeee d. MMMM y", { locale });
					// Make sure first letter is capitalized
					return strDay[0].toUpperCase() + strDay.slice(1);
				}
				default:
					return "";
			}
		};

		setText(displayText(view));
	}, [view, languageCode, t]);

	const date = new Date(view.start);

	return (
		<DivRoot>
			<TypographyText variant={isTextAlignmentNarrow ? "h4" : "h5"}>{text}</TypographyText>
			{view.scope === "day" && <HolidayIcons date={date} />}
		</DivRoot>
	);
};

NavTextDisplay.propTypes = {
	/** The view object from state */
	view: PropTypes.object
};

const mapStateToProps = state => {
	return {
		view: state.view
	};
};

export default connect(mapStateToProps)(NavTextDisplay);
