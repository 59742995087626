import {
	addSeconds,
	addDays,
	getHours,
	setHours,
	setMinutes,
	getMinutes,
	setSeconds,
	differenceInDays
} from "date-fns";
import { getHolidays, isSaturday, isHolidayOrSunday } from "utils/holidayHelper";

export const getNoOfAbsenceWorkDaysInSelectedPeriod = (start, end, events) => {
	let noOfAbsenceWorkDays = 0;
	events.forEach(event => {
		let eventStartDate = new Date(event.start);
		let eventEndDate = new Date(event.end);

		// Limit the start and end dates to the current view
		eventStartDate.getTime() > start.getTime() ? eventStartDate : (eventStartDate = start);
		eventEndDate.getTime() < end.getTime() ? eventEndDate : (eventEndDate = end);

		// If the last day ends at midnight, then the last day is not counted as an absence day
		if (
			eventEndDate.getHours() === 0 &&
			eventEndDate.getMinutes() === 0 &&
			eventEndDate.getSeconds() === 0
		) {
			eventEndDate = addDays(eventEndDate, -1);
			eventEndDate.setHours(23, 59, 59, 0);
		}

		// Trim first day if not absent the whole day. I.e., if the first day of the event starts after 08:00
		if (eventStartDate.getHours() >= 8) {
			eventStartDate = addDays(eventStartDate, 1);
			eventStartDate.setHours(0, 0, 0, 0);
		}

		// Extend last day to 23:59:59 if absent the whole day. I.e., if the event ends after 16:00. This ensures the differenceInDays method counts the last day as an abscense day
		if (eventEndDate.getHours() >= 16) {
			eventEndDate.setHours(23, 59, 59, 0);
		}

		// addSeconds hack to add one second because the differenceInDays method only counts whole days. I.e, if the event ends at 23:59:59, then the last day is not counted
		let diffInDays = differenceInDays(addSeconds(eventEndDate, 1), eventStartDate);

		if (diffInDays >= 1) {
			let noOfNotWorkingDays = getNoOfNotWorkingDaysInInterval(eventStartDate, eventEndDate);
			noOfAbsenceWorkDays += parseInt(diffInDays) - noOfNotWorkingDays;
		}
	});
	return noOfAbsenceWorkDays;
};

const getNoOfNotWorkingDaysInInterval = (start, end) => {
	let noOfNotWorkingDays = 0;
	let startDate = start;
	const holidays = getHolidays([startDate, end]);

	// Set startDate to beginning of next day, as we only calculate whole days for absence
	if (getHours(startDate) !== 0 || getMinutes(startDate) !== 0) {
		startDate = setHours(startDate, 0);
		startDate = setMinutes(startDate, 0);
		startDate = setSeconds(startDate, 0);
		startDate = addDays(startDate, 1);
	}
	// Set endDate to beginning of current day, as we only calculate whole days for absence
	let endDate = end;
	if (getHours(endDate) !== 0 || getMinutes(endDate) !== 0) {
		endDate = setHours(endDate, 0);
		endDate = setMinutes(endDate, 0);
		endDate = setSeconds(endDate, 0);
	}
	while (startDate <= endDate) {
		if (isHolidayOrSunday(startDate, holidays) || isSaturday(startDate)) {
			noOfNotWorkingDays++;
		}
		startDate = addDays(startDate, 1);
	}
	return noOfNotWorkingDays;
};
