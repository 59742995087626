import styled from "@emotion/styled";
import { Paper, Typography, Divider } from "@mui/material";

export const PaperRoot = styled(Paper)(() => ({
	padding: "12px",
	width: "200px",
	height: "auto"
}));

export const TypographyTitle = styled(Typography)(() => ({
	textTransform: "uppercase"
}));

export const TypographyDescription = styled(Typography)(() => ({
	textTransform: "uppercase",
	marginTop: "5px"
}));

export const StyledDivider = styled(Divider)(() => ({
	margin: "8px 0"
}));

export const DivTimeitem = styled("div")(() => ({
	display: "flex"
}));

export const TypographyItem = styled(Typography)(({ theme }) => ({
	width: "50px",
	color: theme.palette.text.secondary
}));

export const TypographyTime = styled(Typography)(({ theme }) => ({
	width: "150px",
	color: theme.palette.text.secondary
}));
