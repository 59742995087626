import React, { useState } from "react";
import { Fab, Menu, Typography, MenuItem } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { viewSetScope } from "../../actions/viewActions";
import { TIMELINE_OPTIONS } from "../../constants";
import { useTranslation } from "react-i18next";

const options = TIMELINE_OPTIONS;

/**
 * Represents a button which when clicked drops a selection menu for setting the view scope
 */
const NavViewScopeButton = ({ view, viewSetScope }) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(options.indexOf(view.scope));

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		viewSetScope(options[index]);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Fab
				data-testid="nav-scope-btn"
				onClick={handleClick}
				variant="extended"
				size="small"
				aria-label="nav-button">
				<Typography variant="button">{t(view.scope)}</Typography>
			</Fab>
			<Menu
				data-testid="nav-scope-menu"
				id="viewScope menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{ vertical: "top", horizontal: "center" }}>
				{options.map((option, index) => {
					return (
						<MenuItem
							data-testid={`nav-scope-menuitem/${index}`}
							key={option}
							selected={index === selectedIndex}
							onClick={event => handleMenuItemClick(event, index)}>
							<Typography variant="body1" sx={{ textTransform: "capitalize" }}>
								{t(option)}
							</Typography>
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

NavViewScopeButton.propTypes = {
	/** The view object from state */
	view: PropTypes.object,
	/** Action for setting the view scope */
	viewSetScope: PropTypes.func
};

const mapStateToProps = state => {
	return {
		view: state.view
	};
};

export default connect(mapStateToProps, { viewSetScope })(NavViewScopeButton);
