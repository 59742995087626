import styled from "@emotion/styled";
import { alpha, Menu, Typography } from "@mui/material";

export const MenuItemDiv = new styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
}));

export const TypographyText = styled(Typography)(() => ({
	margin: "0 20px 0 20px"
}));

export const StyledDiv = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
}));

export const TypographyNestedText = styled(Typography)(() => ({
	margin: "0 20px 0 44px"
}));

export const DivTest = styled("div")(() => ({
	width: "100%",
	display: "flex",
	justifyContent: "space-between"
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
	"& .MuiPaper-root": { backgroundColor: theme.palette.primary.main },
	"& .MuiMenuItem-root": {
		"&:active": {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
		}
	}
}));
