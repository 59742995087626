import React, { useState, useEffect } from "react";
import { submitFeedback } from "services/apiFeedbackService";
import { useTranslation } from "react-i18next";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import {
	FeedbackOrangeText,
	FeedbackRedText,
	FeedbackWhiteText,
	VisuallyHiddenInput
} from "./FeedbackMenuStyles.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import imageCompression from "browser-image-compression";
import { FEEDBACK_TYPES, FEEDBACK_INTERVAL } from "constants/index.js";

/** Component used to collect feedback from a user */
function FeedbackMenu() {
	const { t } = useTranslation();
	const [categoryOptions, setCategoryOptions] = useState("feedbackCategoryNoCategory");
	const [submissionResult, setSubmissionResult] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submittingDots, setSubmittingDots] = useState("");
	const [image, setImage] = useState(null);
	const [isCompressing, setIsCompressing] = useState(false);
	const [imageError, setImageError] = useState("");

	const handleSubmit = event => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		formData.set("feedbackImage", image);
		setIsSubmitting(true);

		submitFeedback(formData)
			.then(response => {
				console.log(response);
				setSubmissionResult(t("feedbackSuccess"));
				event.target.reset();
				setImage(null);
				setCategoryOptions("feedbackCategoryNoCategory");
			})
			.catch(error => {
				if (error === "Too Many Requests") {
					setSubmissionResult(
						t("feedbackTooManyRequestsMain") +
							t("feedbackNumberOfFeedbacks") +
							(FEEDBACK_INTERVAL === 1
								? t("feedbackNumberOfFeedbacksSingular")
								: FEEDBACK_INTERVAL + t("feedbackNumberOfFeedbacksPlural"))
					);
				} else {
					setSubmissionResult(t("feedbackError"));
				}
			})
			.then(() => {
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setSubmittingDots(prev => (prev.length >= 3 ? "" : prev + "."));
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const handleChange = event => {
		setCategoryOptions(event.target.value);
	};

	function ignoreEnter(keyEvent) {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	}

	const handleImageChange = event => {
		// Clearing the previous image
		setImage(null);

		// Checking if the file is actually an image using the content type
		if (!event.target.files[0].type.startsWith("image/")) {
			setImageError(t("feedbackImageNotImage"));
			return;
		}

		// Checking if the file is too large
		if (event.target.files[0].size > 10000000) {
			setImageError(t("feedbackImageTooLarge"));
			return;
		}

		setImageError("");
		compressAndSaveImage(event);
	};

	const compressAndSaveImage = async event => {
		setIsCompressing(true);
		const imageFile = event.target.files[0];
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 3840,
			useWebWorker: true
		};
		const compressedFile = await imageCompression(imageFile, options);
		setImage(compressedFile);
		setIsCompressing(false);
	};

	return (
		<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100vw",
				maxWidth: "1000px",
				marginLeft: "auto",
				marginRight: "auto"
			}}>
			<FeedbackOrangeText>{t("feedbackDescription1")}</FeedbackOrangeText>
			<FeedbackOrangeText>{t("feedbackDescription2")}</FeedbackOrangeText>
			<FeedbackOrangeText>
				{t("feedbackNumberOfFeedbacks") +
					(FEEDBACK_INTERVAL === 1
						? t("feedbackNumberOfFeedbacksSingular")
						: FEEDBACK_INTERVAL + t("feedbackNumberOfFeedbacksPlural"))}
			</FeedbackOrangeText>
			<FeedbackOrangeText>{}</FeedbackOrangeText>
			<Box
				width={"80%"}
				component="form"
				onSubmit={handleSubmit}
				sx={{
					display: "flex",
					flexDirection: "column",
					marginTop: "20px"
				}}>
				<Select
					id="category"
					name="feedbackCategory"
					type="text"
					required
					fullWidth
					value={categoryOptions}
					label={t("feedbackCategory")}
					onChange={handleChange}>
					{FEEDBACK_TYPES.map(feedbackType => (
						<MenuItem key={feedbackType} value={feedbackType}>
							{t(feedbackType)}
						</MenuItem>
					))}
				</Select>
				<TextField
					id="title"
					name="feedbackTitle"
					type="text"
					margin="normal"
					required
					fullWidth
					onKeyDown={ignoreEnter}
					label={t("feedbackTitle")}
				/>
				<TextField
					id="content"
					name="feedbackContent"
					type="text"
					margin="normal"
					required
					fullWidth
					multiline
					label={t("feedbackContent")}
				/>
				<FeedbackWhiteText>{t("feedbackImage")}</FeedbackWhiteText>
				<Button
					component="label"
					variant="contained"
					sx={{
						width: "180px",
						marginTop: "2px"
					}}
					startIcon={<CloudUploadIcon />}>
					{t("feedbackImageUpload")}
					<VisuallyHiddenInput
						type="file"
						accept="image/*"
						onChange={handleImageChange}
					/>
				</Button>
				{image && (
					<FeedbackWhiteText
						sx={{
							marginTop: "2px"
						}}>
						{t("feedbackChosenImage")}: {image.name}
					</FeedbackWhiteText>
				)}
				{imageError && <FeedbackRedText>{imageError}</FeedbackRedText>}
				{!isCompressing ? (
					<Button
						type="submit"
						fullWidth
						variant="contained"
						width={"80%"}
						sx={{ marginTop: "20px", marginBottom: "10px" }}>
						{t("feedbackSubmit")}
					</Button>
				) : (
					<FeedbackOrangeText
						sx={{
							marginTop: "20px",
							marginBottom: "10px"
						}}>
						{t("feedbackCompressingImage")}
						{submittingDots}
					</FeedbackOrangeText>
				)}
			</Box>
			{isSubmitting && (
				<FeedbackOrangeText>
					{t("feedbackSubmittingText")}
					{submittingDots}
				</FeedbackOrangeText>
			)}
			{!isSubmitting && <FeedbackOrangeText>{submissionResult}</FeedbackOrangeText>}
		</Box>
	);
}

export default FeedbackMenu;
