import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationNO from "./assets/locales/no.json";
import translationEN from "./assets/locales/en.json";

const resources = {
	en: {
		translation: translationEN
	},
	nb: {
		translation: translationNO
	}
};

/** i18n configuration */
i18n.use(detector) // Detect user preferred language
	.use(initReactI18next)
	// More options: https://www.i18next.com/overview/configuration-options
	.init({
		interpolation: {
			escapeValue: false // not needed for React as it escapes by default
		},
		keySeparator: false,
		fallbackLng: "nb", // use 'nb' if detected language from browser is not available
		resources
	});

export default i18n;
