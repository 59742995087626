import React from "react";
import PropTypes from "prop-types";
import {
	PaperRoot,
	TypographyTitle,
	TypographyDescription,
	StyledDivider,
	DivTimeitem,
	TypographyItem,
	TypographyTime
} from "./CalendarItemInfoStyles";
import { useTranslation } from "react-i18next";

/**
 * Represents the pop-up info panel when hovering an event in the timeline.
 */
const CalendarItemInfo = ({ event }) => {
	const { t } = useTranslation();
	return event === undefined ? null : (
		<PaperRoot>
			<TypographyTitle variant="h5">{event.title}</TypographyTitle>
			<TypographyDescription variant="subtitle2">{event.description}</TypographyDescription>
			<StyledDivider />
			<DivTimeitem>
				<TypographyItem variant="body2">{t("from")}:</TypographyItem>
				<TypographyTime variant="body2">{event.start}</TypographyTime>
			</DivTimeitem>
			<DivTimeitem>
				<TypographyItem variant="body2">{t("to")}:</TypographyItem>
				<TypographyTime variant="body2">{event.end}</TypographyTime>
			</DivTimeitem>
		</PaperRoot>
	);
};

CalendarItemInfo.propTypes = {
	/** The event object from state */
	event: PropTypes.object
};

export default CalendarItemInfo;
