import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { viewSetShowFromToday, viewSetTodayCurrent } from "../../../../actions/viewActions";
import { Typography, Fab } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * Represents the button for navigating to present date within view scope
 */
const NavTodayButton = ({ viewSetTodayCurrent, viewSetShowFromToday }) => {
	const handleClick = () => {
		viewSetTodayCurrent();
		viewSetShowFromToday(false);
	};
	const { t } = useTranslation();
	return (
		<Fab
			data-testid="nav-today-btn"
			onClick={handleClick}
			variant="extended"
			size="small"
			aria-label="nav-today-btn">
			<Typography variant="button">{t("today")}</Typography>
		</Fab>
	);
};

NavTodayButton.propTypes = {
	/** Action for setting the view to today in current scope */
	viewSetTodayCurrent: PropTypes.func,
	/** Action for setting showFromToday to false */
	viewSetShowFromToday: PropTypes.func
};

export default connect(null, { viewSetTodayCurrent, viewSetShowFromToday })(NavTodayButton);
