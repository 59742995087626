import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { putCustomGroup } from "services/apiCustomGroupService";
import { connect, useDispatch, useStore } from "react-redux";
import { userSetSelectedGroupId } from "actions/userActions";
import CustomGroupModal from "../CustomGroupModal";
import { SHOW_EDIT_CUSTOM_GROUP_MODAL } from "constants";
import { modifyGroupInState, removeEntriesFromGroupInState } from "utils/groupHelper";
import { CustomGroupDto } from "models/customGroupDto";

/**
 * Represents the modal used to add new custom groups
 */
function EditCustomGroupModal({ modals, user, userSetSelectedGroupId }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = useStore();
	const modalGroup = modals.customGroupModal.modalGroup;

	const handleEditGroup = async () => {
		// Filter out the current user and get Ids of the selected users to be sent in put request
		const memberIdList = modals.customGroupModal.selectedUsers
			.filter(usernameAndId => usernameAndId.id !== user.id)
			.map(usernameAndId => usernameAndId.id);

		const result = await putCustomGroup(
			new CustomGroupDto(
				modalGroup.name,
				modalGroup.id,
				memberIdList,
				modalGroup.isSharedWithMembers
			)
		);

		if (result) {
			removeEntriesFromGroupInState(modalGroup.id, store, dispatch); // Delete data for old users
			modifyGroupInState(modalGroup, store, dispatch);
			userSetSelectedGroupId(modalGroup.id); // Display the edited group
		}
	};

	return (
		<CustomGroupModal
			displayModal={modals.modalType === SHOW_EDIT_CUSTOM_GROUP_MODAL}
			modalTitle={t("editGroup")}
			modalButtonText={t("change")}
			modalButtonClickHandler={handleEditGroup}
		/>
	);
}

EditCustomGroupModal.propTypes = {
	/** The modals object from state */
	modals: PropTypes.object,
	/** The user object from state */
	user: PropTypes.object,
	/** Action for setting selected group */
	userSetSelectedGroupId: PropTypes.func
};

const mapStateToProps = state => {
	return {
		modals: state.modals,
		user: state.user
	};
};

export default connect(mapStateToProps, {
	userSetSelectedGroupId
})(EditCustomGroupModal);
