import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TimelineDateItem from "../TimelineDateItem/TimelineDateItem";
import { addMonths, addWeeks, addDays, addHours, format } from "date-fns";
import { useMediaQuery } from "@mui/material";
import { enGB, nb } from "date-fns/locale";
import { norwegianCode } from "../../../constants";
import { SpacerDesktop, SpacerMobile, TimeLineDateRowDiv } from "./TimelineDateRowStyles";

/** Function for filtering date rows, i.e. appropriate format them  */
const filterDateRow = (dateType, ticks, startDate, locale) => {
	let dataRows = [];
	let timeFormat = null;
	let dateText = null;

	// Decides what kind of date type will be worked with and displayed
	let type =
		dateType === "day"
			? "hour"
			: dateType === "week"
				? "weekday"
				: dateType === "month"
					? "day"
					: "month";

	// Sets the date format of the text
	timeFormat =
		type === "hour" ? "HH:mm" : type === "weekday" ? "E dd" : type === "day" ? "E dd" : "MMM";

	let displayStart = dateType === "year" ? addWeeks(startDate, 1) : startDate;

	const monthViewShowDayShort = window.innerWidth < 1500;

	for (let i = 0; i < ticks; i++) {
		// Adds i days, weeks or months, depending on the current type
		let tickTime = null;
		switch (type) {
			case "hour":
				tickTime = addHours(displayStart, i);
				if (window.innerWidth < 900) {
					dateText = i % 4 === 0 ? format(tickTime, timeFormat, { locale }) : "";
				} else if (window.innerWidth < 1600) {
					dateText = i % 2 === 0 ? format(tickTime, timeFormat, { locale }) : "";
				} else {
					dateText = format(tickTime, timeFormat, { locale });
				}
				break;
			case "weekday":
				tickTime = addDays(displayStart, i);
				dateText = format(tickTime, timeFormat, { locale });
				break;
			case "day":
				tickTime = addDays(displayStart, i);

				if (window.innerWidth < 1100) {
					dateText = i % 2 === 0 ? format(tickTime, timeFormat, { locale }) : "";
				} else {
					dateText = format(tickTime, timeFormat, { locale });
				}

				/* Display the names of the days in the month as "M, T, W,..." instead of "Mon, Tue, Wed,..." for smaller screens. */
				if (monthViewShowDayShort && dateText !== "")
					dateText = dateText[0] + dateText.slice(3);

				break;
			case "week":
				tickTime = addWeeks(displayStart, i);
				dateText = format(tickTime, timeFormat, { locale });
				break;
			case "month":
				tickTime = addMonths(displayStart, i);
				dateText = format(tickTime, timeFormat, { locale });
				break;
			default:
				break;
		}

		dateText = dateText === "" ? "" : dateText;

		type === "day"
			? dataRows.push(
					<TimelineDateItem
						key={i}
						lineBreakAt={monthViewShowDayShort ? 1 : 3}
						text={dateText}
						date={tickTime}
						dateType={dateType}
					/>
				)
			: dataRows.push(
					<TimelineDateItem key={i} text={dateText} date={tickTime} dateType={dateType} />
				);
	}
	return dataRows;
};

/**
 * Represents the timeline date row
 */
const TimelineDateRow = ({ dateType, ticks, startTime }) => {
	const isDesktop = useMediaQuery("(min-width: 960px)");
	const { i18n } = useTranslation();
	const languageCode = i18n.language;
	// Check which language is set for the application and set locale for date-fns
	const locale = languageCode === norwegianCode ? nb : enGB;
	const dateRows = filterDateRow(dateType, ticks, startTime, locale);
	const Spacer = isDesktop ? SpacerDesktop : SpacerMobile;

	return (
		<div style={{ display: "flex" }}>
			<Spacer />
			<TimeLineDateRowDiv>{dateRows}</TimeLineDateRowDiv>
		</div>
	);
};

TimelineDateRow.propTypes = {
	/** The date type. i.e "week"/"month" */
	dateType: PropTypes.string,
	/** Number of sections the row is divided into */
	ticks: PropTypes.number,
	/** Start time from current view */
	startTime: PropTypes.object
};

export default TimelineDateRow;
