import styled from "@emotion/styled";
import theme, { colors } from "styles/theme";
import { Button, TextField } from "@mui/material";

export const StyledSelectedUsersContainer = styled("div")(() => ({
	marginTop: "10px"
}));

export const StyledSearchContainer = styled("div")(() => ({
	display: "flex",
	flexDirection: "row",
	gap: "15px",
	marginTop: "10px"
}));

export const UsernameTextField = styled(TextField)(() => ({
	"& label.Mui-focused": {
		color: colors.orange
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: colors.lightgrey
		},
		"&.Mui-focused fieldset": {
			borderColor: colors.orange
		}
	},
	width: "100%"
}));

export const StyledButton = styled(Button)(() => ({
	...theme.button,
	marginRight: "10px"
}));
