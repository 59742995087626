import {
	VIEW_SET_TODAY_CURRENT,
	VIEW_SET_SCOPE,
	VIEW_MOVE_CURRENT,
	WEEK,
	MONTH,
	YEAR,
	DAY,
	TIMELINE_PREFERENCE,
	TIMELINE_START,
	TIMELINE_END,
	TIMELINE_CURRENT,
	VIEW_SET_CURRENT,
	VIEW_SET_COMPACT_VIEW,
	VIEW_SET_SHOW_FROM_TODAY
} from "../constants";
import initialState from "./initialState";
import {
	endOfYear,
	startOfYear,
	endOfWeek,
	startOfWeek,
	endOfMonth,
	startOfMonth,
	endOfDay,
	startOfDay,
	addBusinessDays,
	addDays,
	subDays,
	addWeeks,
	addMonths,
	addYears
} from "date-fns";

/** Reducer for the view object in state */
export default function (view = initialState.view, action) {
	let start = null;
	let end = null;

	switch (action.type) {
		case VIEW_SET_TODAY_CURRENT:
			[start, end] = getStartEndTimesForScope(view.scope, view.today);

			return {
				...view,
				current: initialState.view.today,
				start: start,
				end: end
			};

		case VIEW_SET_SCOPE:
			// If the scope is changed to day, check if the selected day is in a weekend
			// If so, change the selected day to the next monday
			if (action.scope === DAY) {
				let selectedDay = new Date(view.current);
				if (selectedDay.getDay() === 0) {
					selectedDay = addDays(selectedDay, 1);
				}
				if (selectedDay.getDay() === 6) {
					selectedDay = addDays(selectedDay, 2);
				}
				[start, end] = getStartEndTimesForScope(action.scope, selectedDay);
				saveCurrentView(action.scope, start, end, selectedDay);
			} else {
				[start, end] = getStartEndTimesForScope(action.scope, view.current);
				saveCurrentView(action.scope, start, end, view.current);
			}

			return {
				...view,
				start: start,
				end: end,
				scope: action.scope,
				showFromToday: false // Changing scope disables the show from today option
			};

		case VIEW_MOVE_CURRENT:
			/**
			 * Moving the view should disable the showFromToday option.
			 * Get the first monday of the current week and then proceed with the move action.
			 * This prevents the move action from displaying the incorrect day.
			 */
			if (view.showFromToday) {
				[start, end] = getMovedStartEndTimes(
					{
						...view,
						start: start,
						end: end
					},
					action.oper
				);
			} else {
				[start, end] = getMovedStartEndTimes(view, action.oper);
			}
			saveCurrentView(view.scope, start, end, start);

			return {
				...view,
				current: start,
				start: start,
				end: end,
				showFromToday: false // Moving disables the show from today option
			};

		case VIEW_SET_CURRENT:
			return {
				...view,
				current: action.date
			};

		case VIEW_SET_SHOW_FROM_TODAY:
			if (action.showFromToday) {
				[start, end] = getStartEndTimesFromToday(view.scope);
			} else {
				[start, end] = getStartEndTimesForScope(view.scope, view.current);
			}

			return {
				...view,
				start: start,
				end: end,
				showFromToday: action.showFromToday
			};

		case VIEW_SET_COMPACT_VIEW:
			return {
				...view,
				compactView: action.compactView
			};

		default:
			return view;
	}
}

/**
 * Function for getting start and end times for the current scope
 */
export const getStartEndTimesForScope = (scope, current) => {
	let start = new Date(current);
	let end = new Date(current);

	if (scope === YEAR) {
		start = startOfYear(start);
		end = endOfYear(start);
	}

	if (scope === MONTH) {
		start = startOfMonth(start);
		end = endOfMonth(start);
	}

	if (scope === WEEK) {
		// get monday and friday for current week(s)
		start = startOfWeek(start, { weekStartsOn: 1 });
		end = endOfWeek(end, { weekStartsOn: 1 });
		end = subDays(end, 2);
	}

	start = startOfDay(start);
	end = endOfDay(end);

	return [start.toString(), end.toString()];
};

/** Function for getting start and end times for the current scope from today */
export const getStartEndTimesFromToday = scope => {
	const days = scope === DAY ? 0 : scope === WEEK ? 6 : scope === MONTH ? 30 : 364;
	let start = startOfDay(new Date());
	let end = endOfDay(addDays(start, days));
	return [start.toString(), end.toString()];
};

/**
 * Function for getting new start and end times on move current view
 */
export const getMovedStartEndTimes = (view, oper) => {
	let start = new Date(view.start);
	let end = new Date(view.end);

	// make sure start date is not last week of previous year
	if (view.scope === YEAR) {
		start = addYears(startOfYear(addBusinessDays(start, 4)), oper);
		end = endOfYear(start);
	}

	// make sure start date is not last week in previous month
	if (view.scope === MONTH) {
		// skip ahead 14 days to ensure current month
		start = addDays(start, 14);
		start = addMonths(start, oper);
		end = new Date(start);
		start = startOfMonth(start);
		end = endOfMonth(end);
	}

	if (view.scope === WEEK) {
		start = addWeeks(start, oper);
		end = addWeeks(end, oper);
	} else if (view.scope === DAY) {
		start = addBusinessDays(start, oper);
		end = addBusinessDays(end, oper);
	}

	start = startOfDay(start);
	end = endOfDay(end);

	return [start.toString(), end.toString()];
};

/**
 * Function for saving current view to Local Storage
 */
export const saveCurrentView = (scope, start, end, current) => {
	localStorage.setItem(TIMELINE_PREFERENCE, scope);
	localStorage.setItem(TIMELINE_START, start);
	localStorage.setItem(TIMELINE_END, end);
	localStorage.setItem(TIMELINE_CURRENT, current);
};
