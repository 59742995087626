import { getApiData, postApiData } from "./apiService";
import { ENDPOINT } from "constants";

/** API call for checking if the logged in user is an admin */
export const isUserAdmin = async () => {
	let loc = ENDPOINT + "isUserAdmin";

	return await getApiData(loc);
};

/** Admin only API call for getting all AD groups */
export const getADGroups = async () => {
	let loc = ENDPOINT + "getADGroups";

	return await getApiData(loc);
};

/** Admin only API call used to search for an AD group */
export const searchADGroups = async searchString => {
	let loc = ENDPOINT + "searchADGroups/" + searchString;

	return await getApiData(loc, searchString);
};

/** Admin only API call used to add AD groups */
export const addADGroups = async adGroupDtos => {
	let loc = ENDPOINT + "addADGroups";

	return await postApiData(loc, adGroupDtos);
};

/** Admin only API call used to disable AD groups */
export const removeADGroups = async adGroupDtos => {
	let loc = ENDPOINT + "removeADGroups";

	return await postApiData(loc, adGroupDtos);
};
