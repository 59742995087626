import { SET_SNACKBAR } from "constants";

export const setSnackbar = (message, severity) => {
	return {
		type: SET_SNACKBAR,
		payload: {
			message: message,
			severity: severity
		}
	};
};
