import { toDate } from "date-fns-tz";
import { UserWithEvents } from "../models/userWithEvents";
import initialState from "reducers/initialState";
import { store } from "reducers/store";
import {
	loadingAddToLoadingQueue,
	loadingRemoveFromLoadingQueue,
	loadingSetIsLoading
} from "actions/loadingActions";

/** Function for shaping the response data for retrieveing all calendar events */
export const shapeData = data => {
	let entryList = {};
	let userWithEventsList = [];

	for (let item in data) {
		let user = data[item];
		// Checks if there exists as key for a given entry user id
		// If not, creates that key and adds an empty list as its value
		let entries = [];
		if (entryList[user.id] === undefined) entryList[user.id] = [];

		user.events.forEach(event => {
			let newEntry = {
				subject: event.isPrivate === true ? "Undisclosed" : event.subject,
				start: toDate(event.start.dateTime, {
					timeZone: event.start.timeZone
				}),
				end: toDate(event.end.dateTime, {
					timeZone: event.end.timeZone
				}),
				name: user.name,
				memberId: user.id,
				isRecurring:
					event.additionalData && event.additionalData.isRecurring
						? event.additionalData.isRecurring
						: false
			};
			// Finds the key that corresponds to the entry's id in the entry list
			// and adds that entry to the correspoding list.
			entries.push(newEntry);
		});
		entries.sort((a, b) => +a.start - +b.start || +a.end - +b.end);

		entries = removeOverlappingEvents(entries);

		let userWithEvents = new UserWithEvents(user, entries);
		userWithEventsList.push(userWithEvents);
	}

	for (let user in entryList) {
		entryList[user].sort((a, b) => +a.start - +b.start);
	}

	return userWithEventsList;
};

/** Helper function for camelcasing word or sentence, e.g. Test sentence => testSentence */
export const shapeGroupEntriesMap = groupEntriesMap => {
	const shapedGroupEntriesMap = new Map();
	for (const [groupID, groupEntries] of groupEntriesMap) {
		shapedGroupEntriesMap.set(groupID, shapeData(groupEntries));
	}
	return shapedGroupEntriesMap;
};

/** Helper function for converting shapedGroupEntriesMap to an array containing unique users */
export const getUniqueUsersFromGroupEntriesMap = shapedGroupEntriesMap => {
	let allUserEvents = [];
	let userIds = new Set();

	for (const groupEntries of shapedGroupEntriesMap.values()) {
		for (const userWithEvents of groupEntries) {
			if (!userIds.has(userWithEvents.user.id)) {
				allUserEvents.push(userWithEvents);
				userIds.add(userWithEvents.user.id);
			}
		}
	}
	return allUserEvents;
};

/** Helper function for converting a user object with name, id, email
 *  into an object containing user and events */
export function shapeUserToUserWithEvents(listOfUsers) {
	let usersWithEvents = [];
	listOfUsers.forEach(user => {
		usersWithEvents.push({
			user: {
				...initialState.user,
				id: user.id,
				name: user.name
			},
			events: []
		});
	});
	return usersWithEvents;
}

/** Helper function for camelcasing word or sentence, e.g. Test sentence => testSentence */
export const toCamelCase = str =>
	str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
			idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
		)
		.replace(/\s+/g, "");

/** Helper function for upper camelcasing word or sentence, e.g. Test sentence => TestSentence */
export const toUpperCamelCase = str =>
	str.replace(/(?:^\w|[A-Z]|\b\w)/g, ltr => ltr.toUpperCase()).replace(/\s+/g, "");

export const removeOverlappingEvents = entries => {
	let end = entries.length;
	if (end !== 0) {
		let startDate = entries[0].start;
		let endDate = entries[0].end;
		for (let i = 1; i < end; i++) {
			let overlap =
				startDate.getTime() <= entries[i].start.getTime() &&
				endDate.getTime() >= entries[i].end.getTime();
			if (overlap) {
				entries.splice(i, 1);
				end--;
				i--;
			} else {
				startDate = entries[i].start;
				endDate = entries[i].end;
			}
		}
	}
	return entries;
};

// Adds a new uuid to the loading queue and sets isLoading to true
export const addToLoadingQueue = uuid => {
	store.dispatch(loadingAddToLoadingQueue(uuid));
	store.dispatch(loadingSetIsLoading(true));
};

// Removes a uuid from the loading queue and sets isLoading to false if the queue is empty
export const removeFromLoadingQueue = uuid => {
	store.dispatch(loadingRemoveFromLoadingQueue(uuid));
	if (store.getState().loading.loadingQueue.length === 0) {
		store.dispatch(loadingSetIsLoading(false));
	}
};
