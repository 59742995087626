import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { colors } from "styles/theme";

export const ViewFeedbackOrangeText = styled(Typography)(() => ({
	color: colors.orange,
	width: "80%"
}));

export const ViewFeedbackGreyText = styled(Typography)(() => ({
	color: colors.lightgrey,
	width: "80%"
}));

export const ViewFeedbackWhiteText = styled(Typography)(() => ({
	color: colors.white,
	width: "80%"
}));
