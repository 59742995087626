import { getApiData } from "./apiService";
import { ENDPOINT } from "constants";

/** API call for getting the logged in users info */
export const getUserInfoApi = async () => {
	let loc = ENDPOINT + "user";
	return await getApiData(loc);
};

/** API call for getting a user with id's profile photo */
export const getUserProfilePhoto = async userID => {
	let loc = ENDPOINT + "userProfilePhoto";
	!userID || 0 === userID.length ? loc : (loc += "/?userid=" + userID);

	return await getApiData(loc);
};

/** API call for getting the logged in user's profile photo */
export const getUsersProfilePhotos = async groupID => {
	let loc = ENDPOINT + "usersProfilePhotos/" + groupID;

	return await getApiData(loc);
};

/** API call used to get a list of users that have the search string in their name */
export const getUsersBySearch = async searchString => {
	let loc = ENDPOINT + "searchUsers/" + searchString;

	return await getApiData(loc);
};
