import styled from "@emotion/styled";

export const DivDate = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	paddingLeft: "50px"
}));

export const DivNav = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end"
}));

export const DivGroupSelectMobile = styled("div")(() => ({
	flexBasis: "100%",
	marginTop: "10px",
	display: "flex",
	flexDirection: "column",
	maxWidth: "200px",
	marginLeft: "15px"
}));

export const DivGroupSelectDesktop = styled("div")(() => ({
	display: "flex",
	flexDirection: "row",
	gap: "20px"
}));

export const DivDesktop = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	margin: "20px 0px 20px 0px",
	paddingLeft: "230px",
	flexWrap: "wrap"
}));

export const DivMobile = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	margin: "20px 0px 10px 0px",
	flexWrap: "wrap"
}));
