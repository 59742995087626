import { EVENT_SET_EVENTS, EVENT_ADD_EVENTS } from "../constants";

export const eventSetEvents = groupEntriesMap => {
	return {
		type: EVENT_SET_EVENTS,
		payload: groupEntriesMap
	};
};

export const eventAddEvents = groupEntriesMap => {
	return {
		type: EVENT_ADD_EVENTS,
		payload: groupEntriesMap
	};
};
