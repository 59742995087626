import { GROUP_TYPE_AD_GROUP, GROUP_TYPE_CUSTOM_GROUP, MONTH } from "constants";
import { userSetUserInfo } from "../actions/userActions";
import { getSchedulesByStartTimeAndEndTime as getGroupSchedulesByStartTimeAndEndTime } from "services/apiService";
import { getUserInfoApi } from "services/apiUserService";
import {
	getCustomGroupSchedulesByStartTimeAndEndTime,
	getSharedCustomGroupSchedulesByStartTimeAndEndTime
} from "services/apiCustomGroupService";
import { viewSetTodayCurrent, viewSetScope } from "../actions/viewActions";
import { loadingSetIsLoading } from "../actions/loadingActions";
import { eventAddEvents, eventSetEvents } from "../actions/eventActions";
import { addUsersPhotos, getUsersPhotos } from "../actions/userPhotoActions";
import {
	shapeGroupEntriesMap,
	getUniqueUsersFromGroupEntriesMap,
	addToLoadingQueue,
	removeFromLoadingQueue
} from "utils/helper-funcs";
import { store } from "reducers/store";
import { addDays, addYears } from "date-fns";
import { v4 as uuidv4 } from "uuid";

/**
 * Gets entries from groups async
 */
const getSchedulesAsync = async (groups, startDate, endDate) => {
	let groupEntriesMap = new Map();
	if (Object.keys(groups).length === 0) return groupEntriesMap;

	const uuid = uuidv4();
	addToLoadingQueue(uuid);

	for (const [groupId, group] of Object.entries(groups)) {
		let groupEntries;
		if (group.groupType === GROUP_TYPE_AD_GROUP) {
			groupEntries = await getGroupSchedulesByStartTimeAndEndTime(
				groupId,
				startDate,
				endDate
			);
		} else if (group.groupType === GROUP_TYPE_CUSTOM_GROUP) {
			const groupIsSharedWithUser = store.getState().user.id !== group.groupOwnerId;
			if (groupIsSharedWithUser) {
				groupEntries = await getSharedCustomGroupSchedulesByStartTimeAndEndTime(
					groupId,
					group.groupOwnerId,
					startDate,
					endDate
				);
			} else {
				groupEntries = await getCustomGroupSchedulesByStartTimeAndEndTime(
					groupId,
					startDate,
					endDate
				);
			}
		}

		groupEntriesMap.set(groupId, groupEntries);
	}

	removeFromLoadingQueue(uuid);
	return groupEntriesMap;
};

/**
 * Requests data from the API in increments and dispatches the data to the store.
 */
export function getSchedulesIncrementally(groups, startDate, endDate, incrementDaysBy) {
	if (incrementDaysBy <= 0) throw new Error("incrementBy must be a positive number");

	let currentStartDate = startDate;
	let currentEndDate = addDays(startDate, incrementDaysBy);

	let promises = [];
	while (currentStartDate < endDate) {
		promises.push(
			getSchedulesAsync(groups, currentStartDate, currentEndDate).then(groupEntriesMap => {
				const eventData = shapeGroupEntriesMap(groupEntriesMap);
				store.dispatch(eventAddEvents(eventData));
			})
		);

		currentStartDate = addDays(currentStartDate, incrementDaysBy);
		currentEndDate = addDays(currentEndDate, incrementDaysBy);
	}

	return Promise.all(promises);
}

/**
 * The initialization function
 * Handles authorization and sets the state objects after requesting
 * data from the API. Actions are dispatched directly on the available
 * store.
 */
const initUserConfig = async () => {
	let signedInUserId;

	// Set view to current date when page is loaded
	store.dispatch(viewSetScope(MONTH));
	store.dispatch(viewSetTodayCurrent());

	await getUserInfoApi()
		.then(async user => {
			signedInUserId = user.id;
			store.dispatch(userSetUserInfo(user));

			// Get schedule for the first two weeks
			let startDate = new Date();
			return await getSchedulesAsync(
				user.groups,
				addDays(startDate, -14),
				addDays(startDate, 14)
			);
		})
		.then(groupEntriesMap => {
			const shapedGroupEntriesMap = shapeGroupEntriesMap(groupEntriesMap);
			store.dispatch(eventSetEvents(shapedGroupEntriesMap));
			return getUniqueUsersFromGroupEntriesMap(shapedGroupEntriesMap);
		})
		.then(allUsersEvents => {
			return getUsersPhotos(signedInUserId, allUsersEvents);
		})
		.then(allUsersPhotos => {
			store.dispatch(addUsersPhotos(allUsersPhotos));
		})
		.then(() => {
			let user = store.getState().user;
			let startDate = new Date();

			getSchedulesIncrementally(
				user.groups,
				addDays(startDate, 14),
				addYears(startDate, 1),
				200
			);
			getSchedulesIncrementally(
				user.groups,
				addYears(startDate, -1),
				addDays(startDate, -14),
				200
			);
		});

	// Set isLoading to false
	if (store.getState().loading.loadingQueue.length === 0) {
		store.dispatch(loadingSetIsLoading(false));
	}
};

export default initUserConfig;
