import React from "react";
import { FilterFormControl, FilterTextField } from "./TableFiltersStyles";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function TableFilters({ setFilterTextFieldInput, filterCheckboxValue, setFilterCheckboxValue }) {
	const { t } = useTranslation();
	return (
		<FilterFormControl>
			<FilterTextField
				variant="outlined"
				label={t("filterGroupName")}
				placeholder={t("groupName")}
				size="small"
				onChange={event => setFilterTextFieldInput(event.target.value)}
				InputLabelProps={{
					shrink: true
				}}
			/>

			<FormControlLabel
				control={<Checkbox color="secondary" size="small" checked={filterCheckboxValue} />}
				label={t("adminTableFilterMember")}
				onChange={event => setFilterCheckboxValue(event.target.checked)}
			/>
		</FilterFormControl>
	);
}
TableFilters.propTypes = {
	/** Setter for filterTextFieldInput state variable */
	setFilterTextFieldInput: PropTypes.func,
	/** A string representing the selected filter from the filter radio group */
	filterRadioValue: PropTypes.string,
	/** Setter for filterRadioValue state variable */
	setFilterRadioValue: PropTypes.func,
	/** A boolean representing whether the filter non-member groups checkbox is checked */
	filterCheckboxValue: PropTypes.bool,
	/** Setter for filterCheckboxValue state variable */
	setFilterCheckboxValue: PropTypes.func
};

export default TableFilters;
