import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { viewSetScope, viewSetCurrent } from "actions/viewActions";
import { connect } from "react-redux";
import { ClickableBox } from "./ClickableGridItemsContainerStyles";
import { getWeek, format } from "date-fns";
import { dateFnsOptions } from "constants";

/**
 * Component for displaying clickable TimeLineGridItems.
 */
const ClickableGridItemsContainer = ({
	dateType,
	gridItems,
	startDateOfFirstGridItem,
	width,
	viewSetScope,
	viewSetCurrent
}) => {
	const { t } = useTranslation();
	let scopeBelowCurrent = undefined;
	let text = "";

	switch (dateType) {
		case "week":
			scopeBelowCurrent = "day";
			text = format(startDateOfFirstGridItem, "EEEE");
			break;
		case "month":
			scopeBelowCurrent = "week";
			text = `${t("week")} ${getWeek(startDateOfFirstGridItem, dateFnsOptions)}`;
			break;
		case "year":
			scopeBelowCurrent = "month";
			text = format(startDateOfFirstGridItem, "MMMM");
			break;
		default:
			return gridItems;
	}

	// Display the clicked date in the timeline
	const handleClick = () => {
		viewSetCurrent(startDateOfFirstGridItem);
		viewSetScope(scopeBelowCurrent);
	};

	return (
		<Tooltip title={text}>
			<ClickableBox width={width} onClick={() => handleClick()}>
				{gridItems}
			</ClickableBox>
		</Tooltip>
	);
};

ClickableGridItemsContainer.propTypes = {
	/** The date type of the grid items */
	dateType: PropTypes.string,
	/** A list grid items to display */
	gridItems: PropTypes.array,
	/** The date representing the start of the week */
	startDateOfFirstGridItem: PropTypes.object,
	/** The width the week component should use.
	 * Scales depending on the number of visible week days in the month scope */
	width: PropTypes.string,
	/** Function for setting the scope */
	viewSetScope: PropTypes.func,
	/** Function for setting the current date */
	viewSetCurrent: PropTypes.func
};

export default connect(null, { viewSetScope, viewSetCurrent })(ClickableGridItemsContainer);
